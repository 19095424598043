import React, { useState, useEffect } from "react";
import senderRequest from "../../service/Http";
import toast from "react-hot-toast";
import { handleInputValidation } from "../../helper/validator";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    setquery_type("general");
  }, []);
  const [query_type, setquery_type] = useState("general");
  const [query, setQuery] = useState("");
  const [project_type, setproject_type] = useState("");
  const [referral, setreferral] = useState("");
  const [highlight, setHighlight] = useState(false);
  const [mobile, setMobile] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (query_type === "general") {
        if (!name || !email || !query || !company || !phoneNumber) {
          toast.error("Please fill all the fields");
          return;
        }
      }
      if (query_type === "business") {
        if (
          !name ||
          !email ||
          !query ||
          !company ||
          !phoneNumber ||
          !project_type ||
          !referral
        ) {
          toast.error("Please fill all the fields");
          return;
        }
      }
      const data = {
        name,
        email,
        mobile: countryCode +"-"+ phoneNumber,
        query,
        company,
        query_type,
        project_type,
        referral,
      };

      const response = await senderRequest(
        "post",
        "api/admin/add-query",
        "",
        "",
        data
      );
      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
        setName("");
        setEmail("");
        setPhoneNumber("");
        setCountryCode("+91");
        setMessage("");
        setCompany("");
        // setquery_type("");
        setQuery("");
        setproject_type("");
        setreferral("");
      }
    } catch (error) {}
  };

  const countryCodes = [
    { code: "+1", name: "USA", flag: "https://flagcdn.com/us.svg" },
    { code: "+44", name: "UK", flag: "https://flagcdn.com/gb.svg" },
    { code: "+91", name: "India", flag: "https://flagcdn.com/in.svg" },
    { code: "+61", name: "Australia", flag: "https://flagcdn.com/au.svg" },
    { code: "+81", name: "Japan", flag: "https://flagcdn.com/jp.svg" },
    { code: "+49", name: "Germany", flag: "https://flagcdn.com/de.svg" },
    { code: "+33", name: "France", flag: "https://flagcdn.com/fr.svg" },
    { code: "+39", name: "Italy", flag: "https://flagcdn.com/it.svg" },
    { code: "+86", name: "China", flag: "https://flagcdn.com/cn.svg" },
    { code: "+34", name: "Spain", flag: "https://flagcdn.com/es.svg" },
    { code: "+7", name: "Russia", flag: "https://flagcdn.com/ru.svg" },
    { code: "+20", name: "Egypt", flag: "https://flagcdn.com/eg.svg" },
    { code: "+27", name: "South Africa", flag: "https://flagcdn.com/za.svg" },
    { code: "+82", name: "South Korea", flag: "https://flagcdn.com/kr.svg" },
    { code: "+55", name: "Brazil", flag: "https://flagcdn.com/br.svg" },
    { code: "+54", name: "Argentina", flag: "https://flagcdn.com/ar.svg" },
    { code: "+52", name: "Mexico", flag: "https://flagcdn.com/mx.svg" },
    { code: "+64", name: "New Zealand", flag: "https://flagcdn.com/nz.svg" },
    { code: "+358", name: "Finland", flag: "https://flagcdn.com/fi.svg" },
    { code: "+47", name: "Norway", flag: "https://flagcdn.com/no.svg" },
    { code: "+46", name: "Sweden", flag: "https://flagcdn.com/se.svg" },
    { code: "+41", name: "Switzerland", flag: "https://flagcdn.com/ch.svg" },
    { code: "+31", name: "Netherlands", flag: "https://flagcdn.com/nl.svg" },
    { code: "+32", name: "Belgium", flag: "https://flagcdn.com/be.svg" },
    { code: "+30", name: "Greece", flag: "https://flagcdn.com/gr.svg" },
    { code: "+48", name: "Poland", flag: "https://flagcdn.com/pl.svg" },
    { code: "+351", name: "Portugal", flag: "https://flagcdn.com/pt.svg" },
    { code: "+90", name: "Turkey", flag: "https://flagcdn.com/tr.svg" },
    { code: "+66", name: "Thailand", flag: "https://flagcdn.com/th.svg" },
    { code: "+65", name: "Singapore", flag: "https://flagcdn.com/sg.svg" },
    { code: "+62", name: "Indonesia", flag: "https://flagcdn.com/id.svg" },
    { code: "+63", name: "Philippines", flag: "https://flagcdn.com/ph.svg" },
    { code: "+60", name: "Malaysia", flag: "https://flagcdn.com/my.svg" },
    { code: "+95", name: "Myanmar", flag: "https://flagcdn.com/mm.svg" },
    { code: "+84", name: "Vietnam", flag: "https://flagcdn.com/vn.svg" },
    { code: "+92", name: "Pakistan", flag: "https://flagcdn.com/pk.svg" },
    { code: "+880", name: "Bangladesh", flag: "https://flagcdn.com/bd.svg" },
    { code: "+964", name: "Iraq", flag: "https://flagcdn.com/iq.svg" },
    { code: "+98", name: "Iran", flag: "https://flagcdn.com/ir.svg" },
    { code: "+972", name: "Israel", flag: "https://flagcdn.com/il.svg" },
    { code: "+966", name: "Saudi Arabia", flag: "https://flagcdn.com/sa.svg" },
    {
      code: "+971",
      name: "United Arab Emirates",
      flag: "https://flagcdn.com/ae.svg",
    },
    { code: "+973", name: "Bahrain", flag: "https://flagcdn.com/bh.svg" },
    { code: "+974", name: "Qatar", flag: "https://flagcdn.com/qa.svg" },
    { code: "+965", name: "Kuwait", flag: "https://flagcdn.com/kw.svg" },
    { code: "+968", name: "Oman", flag: "https://flagcdn.com/om.svg" },
    { code: "+961", name: "Lebanon", flag: "https://flagcdn.com/lb.svg" },
    { code: "+962", name: "Jordan", flag: "https://flagcdn.com/jo.svg" },
    { code: "+216", name: "Tunisia", flag: "https://flagcdn.com/tn.svg" },
    { code: "+213", name: "Algeria", flag: "https://flagcdn.com/dz.svg" },
    { code: "+212", name: "Morocco", flag: "https://flagcdn.com/ma.svg" },
    { code: "+221", name: "Senegal", flag: "https://flagcdn.com/sn.svg" },
    { code: "+225", name: "Ivory Coast", flag: "https://flagcdn.com/ci.svg" },
    { code: "+233", name: "Ghana", flag: "https://flagcdn.com/gh.svg" },
    { code: "+234", name: "Nigeria", flag: "https://flagcdn.com/ng.svg" },
    { code: "+254", name: "Kenya", flag: "https://flagcdn.com/ke.svg" },
    { code: "+256", name: "Uganda", flag: "https://flagcdn.com/ug.svg" },
    { code: "+255", name: "Tanzania", flag: "https://flagcdn.com/tz.svg" },
    { code: "+250", name: "Rwanda", flag: "https://flagcdn.com/rw.svg" },
    { code: "+258", name: "Mozambique", flag: "https://flagcdn.com/mz.svg" },
    { code: "+263", name: "Zimbabwe", flag: "https://flagcdn.com/zw.svg" },
    { code: "+852", name: "Hong Kong", flag: "https://flagcdn.com/hk.svg" },
    { code: "+853", name: "Macau", flag: "https://flagcdn.com/mo.svg" },
    { code: "+855", name: "Cambodia", flag: "https://flagcdn.com/kh.svg" },
    { code: "+856", name: "Laos", flag: "https://flagcdn.com/la.svg" },
    { code: "+93", name: "Afghanistan", flag: "https://flagcdn.com/af.svg" },
    { code: "+94", name: "Sri Lanka", flag: "https://flagcdn.com/lk.svg" },
    { code: "+960", name: "Maldives", flag: "https://flagcdn.com/mv.svg" },
    { code: "+963", name: "Syria", flag: "https://flagcdn.com/sy.svg" },
    { code: "+967", name: "Yemen", flag: "https://flagcdn.com/ye.svg" },
    { code: "+970", name: "Palestine", flag: "https://flagcdn.com/ps.svg" },
    { code: "+975", name: "Bhutan", flag: "https://flagcdn.com/bt.svg" },
    { code: "+976", name: "Mongolia", flag: "https://flagcdn.com/mn.svg" },
    { code: "+977", name: "Nepal", flag: "https://flagcdn.com/np.svg" },
    { code: "+992", name: "Tajikistan", flag: "https://flagcdn.com/tj.svg" },
    { code: "+993", name: "Turkmenistan", flag: "https://flagcdn.com/tm.svg" },
    { code: "+994", name: "Azerbaijan", flag: "https://flagcdn.com/az.svg" },
    { code: "+995", name: "Georgia", flag: "https://flagcdn.com/ge.svg" },
    { code: "+996", name: "Kyrgyzstan", flag: "https://flagcdn.com/kg.svg" },
    { code: "+998", name: "Uzbekistan", flag: "https://flagcdn.com/uz.svg" },
    { code: "+1242", name: "Bahamas", flag: "https://flagcdn.com/bs.svg" },
    { code: "+1246", name: "Barbados", flag: "https://flagcdn.com/bb.svg" },
    { code: "+1264", name: "Anguilla", flag: "https://flagcdn.com/ai.svg" },
    {
      code: "+1268",
      name: "Antigua and Barbuda",
      flag: "https://flagcdn.com/ag.svg",
    },
    {
      code: "+1284",
      name: "British Virgin Islands",
      flag: "https://flagcdn.com/vg.svg",
    },
    {
      code: "+1340",
      name: "US Virgin Islands",
      flag: "https://flagcdn.com/vi.svg",
    },
    {
      code: "+1345",
      name: "Cayman Islands",
      flag: "https://flagcdn.com/ky.svg",
    },
    { code: "+1441", name: "Bermuda", flag: "https://flagcdn.com/bm.svg" },
    { code: "+1473", name: "Grenada", flag: "https://flagcdn.com/gd.svg" },
    {
      code: "+1649",
      name: "Turks and Caicos Islands",
      flag: "https://flagcdn.com/tc.svg",
    },
    { code: "+1664", name: "Montserrat", flag: "https://flagcdn.com/ms.svg" },
    {
      code: "+1670",
      name: "Northern Mariana Islands",
      flag: "https://flagcdn.com/mp.svg",
    },
    { code: "+1671", name: "Guam", flag: "https://flagcdn.com/gu.svg" },
    {
      code: "+1684",
      name: "American Samoa",
      flag: "https://flagcdn.com/as.svg",
    },
    { code: "+1721", name: "Sint Maarten", flag: "https://flagcdn.com/sx.svg" },
    { code: "+1758", name: "Saint Lucia", flag: "https://flagcdn.com/lc.svg" },
    { code: "+1767", name: "Dominica", flag: "https://flagcdn.com/dm.svg" },
    {
      code: "+1784",
      name: "Saint Vincent and the Grenadines",
      flag: "https://flagcdn.com/vc.svg",
    },
    {
      code: "+1809",
      name: "Dominican Republic",
      flag: "https://flagcdn.com/do.svg",
    },
    {
      code: "+1829",
      name: "Dominican Republic",
      flag: "https://flagcdn.com/do.svg",
    },
    {
      code: "+1849",
      name: "Dominican Republic",
      flag: "https://flagcdn.com/do.svg",
    },
    {
      code: "+1868",
      name: "Trinidad and Tobago",
      flag: "https://flagcdn.com/tt.svg",
    },
    {
      code: "+1869",
      name: "Saint Kitts and Nevis",
      flag: "https://flagcdn.com/kn.svg",
    },
    { code: "+1876", name: "Jamaica", flag: "https://flagcdn.com/jm.svg" },
  ];

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");

  const [number, setNumber] = useState("");

  const handleInputValidationNumber = (value, setNumber) => {
    setNumber(value);
  };

  return (
    <>
      <section className="commonPage_bnr contactUs_bnr">
        <div className="container">
          <div className="row alignItem_center">
            <div
              data-aos="fade-down-right"
              data-aos-duration="1500"
              className="col-lg-7  mobSpace_mb24"
            >
              <p className="fontSize24  fontWeight400 cerebralGreyClr_text syne_Font">
                From exclusive private projects to dynamic public urban
                squares," we at Metaskapes bring your vision to life.
              </p>
            </div>
            <div
              data-aos="fade-down-left"
              data-aos-duration="1500"
              className="col-lg-5"
            >
              <div className="sectionTitle">
                <h2 className="atypDisplay_Font textalign_right">
                  LET'S CONNECT
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-aos="fade-up"
        data-aos-duration="1800"
        className="contactUs_sec"
      >
        <div className="container">
          {/* 
                    <label className='switch'>
                        <input className='switch-input' value='Email' type='checkbox' />
                        <span
                            className='switch-label'
                            data-on='SAY hello!'
                            data-off='Get a Quote'
                        />
                        <span className='switch-handle' />
                    </label> */}

          {/* <label className="custom_switch"> */}
          <div className="mb_24">
            {/* <span
              className={`toggle-label ${!highlight ? "highlight_yellow" : ""}`}
            > */}
            <h2 className="fontWeight600 atypDisplay_Font textalign_center fontSize34 limeMistClr_text">Get in Touch</h2>
           
            {/* </span> */}
          </div>
            {/* <div>
            <input
              className="toggle_checkbox"
              onClick={() => {
                setName("");
                setEmail("");
                setMessage("");
                setCompany("");
                setQuery("");
                setproject_type("");
                setreferral("");
                setquery_type((prevQueryType) =>
                  prevQueryType === "general" ? "business" : "general"
                );
                setHighlight(!highlight);
              }}
              type="checkbox"
            />

            <div className="toggle_switch"></div>
            </div> */}
            {/* <span className={`toggle-label ${highlight ? "highlight_yellow" : ""}`}>
              Get a Quote
            </span> */}
          {/* </label> */}

          <div
            className="mb_40"
            data-aos="fade-up"
            data-aos-duration="2500"
          >
              <p className="fontSize24  fontWeight400 cerebralGreyClr_text syne_Font textalign_center">
                You’re one step away from transforming your dream space into a
                reality. <br className="MobBr_hide" /> Fill out the form, and let us help you bridge that gap.
                {/* (This one should come above the form fields to inform the users
                why we collect the form) */}
              </p>
          </div>

          <div data-aos="fade-up" data-aos-duration="2500" className="row">
            <div className="col-lg-6 mb_24">
              <div>
                <input
                  type="text"
                  value={name}
                  onChange={(e) =>
                    handleInputValidation(e.target.value, setName, 1)
                  }
                  placeholder="Your name *"
                  className="borderInput"
                />
              </div>
            </div>
            <div className="col-lg-6 mb_24">
              <div>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Your mail (contacting) *"
                  className="borderInput"
                />
              </div>
            </div>
            <div className="col-lg-6 mb_24">
              <div className="GroupBorderinput">
                <div className="input-group-prepend">
                  <select
                    className="GroupBorderinput_text focus:right-0"
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                  >
                    {countryCodes.map((country) => (
                      <option key={country.code} value={country.code}>
                        {/* <Flag
                            code={country.code.substring(1)}
                            className="flag-icon"
                          /> */}
                        <p className="mr-10">{country.code}</p>
                      </option>
                    ))}
                  </select>
                </div>
                <input
                  className="atypDisplay_Font"
                  value={phoneNumber}
                  pattern="[0-9]"
                  onChange={(e) =>
                    handleInputValidation(e.target.value, setPhoneNumber, 8)
                  }
                  type="tel"
                  placeholder="Enter Phone number *"
                />
              </div>
            </div>
            <div className="col-lg-6 mb_24">
              {/* <div>
                <input
                  type="text"
                  placeholder="Company/ Organization"
                  className="borderInput"
                />
              </div> */}
              <select
                className="borderSelectInput"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              >
                <option className="" disabled value="">
                  Company/ Organization*
                </option>
                <option value="individual">Individual</option>
                <option value="organization">Organization</option>
                <option value="contractor">Contractor</option>
              </select>
            </div>

           
                <div className="col-lg-6 mb_24">
                  {/* <div>
                <input
                  type="text"
                  placeholder="Company/ Organization"
                  className="borderInput"
                />
              </div> */}
                  <select
                    className="borderSelectInput"
                    value={project_type}
                    onChange={(e) => setproject_type(e.target.value)}
                  >
                    <option value="" disabled>
                      Project Type*
                    </option>
                    <option value="Residential">
                      Residential{" "}
                      <span>
                        (Villa, Apartments, Penthouse, Bungalow, Farmhouse,
                        Rowhouses, Dormitory etc)
                      </span>
                    </option>
                    <option value="Commercial">
                      Commercial{" "}
                      <span>
                        (Showroom, Resorts, Retail, Hotel, Restaurant, Terminus,
                        etc.)
                      </span>
                    </option>
                    <option value="Mixed">
                      Mixed <span>(Residential & Commercial)</span>{" "}
                    </option>
                    <option value="Corporate">
                      Corporate <span>(IT parks, Office, etc…)</span>
                    </option>
                    <option value="Institutional">
                      Institutional{" "}
                      <span>
                        (Medical, Hospitals, School, College, University,
                        Orphanage, etc.. )
                      </span>
                    </option>
                    <option value="Landscape">
                      Landscape{" "}
                      <span>
                        (Urban Square, Parks, Public & Private Recreation,
                        Transport, Gardens, Swimming Pool etc..)
                      </span>
                    </option>
                    <option value="Industrial">
                      Industrial{" "}
                      <span>(Factory, Warehouse, Flex, R&D Labs, etc..)</span>
                    </option>
                    <option value="Interior and Exterior">
                      Interior and Exterior
                    </option>
                    <option value="Government">
                      Government{" "}
                      <span>
                        ( Bus Terminal, School, Library, Museum, Courthouse,
                        Police Station, Fire Station Offices etc..)
                      </span>
                    </option>
                    <option value="Luxury">
                      Luxury{" "}
                      <span>
                        (Bus Station, Terminals, Shipyard, Boathouse etc..)
                      </span>
                    </option>
                  </select>
                </div>

                <div className="col-lg-6 mb_24">
                  <div className="GroupBorderinput">
                    <select
                      className="borderSelectInput"
                      value={referral}
                      onChange={(e) => setreferral(e.target.value)}
                    >
                      <option disabled value="">
                        How did you hear about us
                      </option>
                      <option value="Search">Search</option>
                      <option value="Social media">Social media</option>
                      <option value="Website">Website</option>
                      <option value="Blogs/News">Blogs/News</option>
                      <option value="Friends">Friends</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
            
          </div>

          <div className="mb_34">
            <textarea
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              type="text"
              rows={6}
              className="borderTextarea"
              placeholder="Ask any queries here..*"
            />
          </div>

          <div className="">
            <button
              className="limeMistFullClrbtn ml_auto display_block"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </section>
      {/* <section className="contactus_marquee">
        <div className="container-fluid">
          <div className="main-marq">
            <div className="slide-har st1">
              <div className="box">
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default ContactUs;
