import React from "react";
import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import senderRequest from "../../../service/Http";
import toast from "react-hot-toast";
import { handleInputValidation } from "../../../helper/validator";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import ImageUploading from "react-images-uploading";
import Upload from "../../../components/Upload";
import AddIcon from "../../../assets/images/add_icon.svg";
import axios from "axios";
const ThoughtsEditModel = ({
  openModal,
  setOpenModal,
  id,
  title,
  setTitle,
  inputFields,
  setInputFields,
  image,
  image_id,
  setImageId,
  setImage,
  hashtags,
  setHashtags,
  handleThoughts,
}) => {
  console.log(image, "image");

  const [newImg, setNewImage] = useState(null);

  const [edit, setEdit] = useState(false);

  const [errors, setErrors] = useState({
    title: "",
    hashtags: "",
    inputFields: ""
  });

  // const [imageId, setImageId] = useState(null);

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // if (!file) {
    //   toast.error("Please select an image");
    //   return;
    // }
    const inputFieldErrors = inputFields.some(field => field.description.trim() === "")
      ? "Please fill out all descriptions"
      : "";

    const titleError = !title.trim() ? "Title is required" : "";
    const hashtagsError = !hashtags.trim() ? "Hashtags are required" : "";

    if (titleError || hashtagsError || inputFieldErrors) {
      setErrors({
        title: titleError,
        hashtags: hashtagsError,
        inputFields: inputFieldErrors
      });
      return;
    }


    formData.append("files", newImg[0].file);

    try {
      const response = await senderRequest(
        "post-photo",
        `api/image/update/${image_id}/noCover`,
        "",
        "",
        formData
      );

      if (response.status === 200) {
        setImageId(response.data.data.id);
        toast.success("Image uploaded successfully");
        handleEditThoughts(e, response.data.data.id);
        console.log("Image uploaded successfully");
      } else {
        console.error("Image upload failed");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const handleEditThoughts = async (e, image_idnew) => {
    e.preventDefault();
    try {
      const inputFieldErrors = inputFields.some(field => field.description.trim() === "")
        ? "Please fill out all descriptions"
        : "";

      const titleError = !title.trim() ? "Title is required" : "";
      const hashtagsError = !hashtags.trim() ? "Hashtags are required" : "";

      if (titleError || hashtagsError || inputFieldErrors) {
        setErrors({
          title: titleError,
          hashtags: hashtagsError,
          inputFields: inputFieldErrors
        });
        return;
      }
      let body = {
        blog_id: id,
        title: title,
        paragraph: JSON.stringify(inputFields),
        image_id: image_idnew ? image_idnew : image_id,
        hashtags: hashtags,
      };
      const response = await senderRequest(
        "put",
        `api/blogs/update`,
        "",
        "",
        body
      );
      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
        setTitle("");
        setInputFields("");
        setImage("");
        setHashtags("");
        setOpenModal(false);
        handleThoughts();
        setNewImage("");
        setEdit(false);
        setErrors({
          title: "",
          hashtags: "",
          inputFields: ""
        })
      }
    } catch (error) {
      toast.error(error.message);
      setOpenModal(false);
      setTitle("");
      setInputFields("");
      setImage("");
      setHashtags("");
      setNewImage("");
      setOpenModal(false);
      setErrors({
        title: "",
        hashtags: "",
        inputFields: ""
      })
    }
  };

  const handleInputValidationAdd = (value, index) => {
    const newInputFields = [...inputFields];
    newInputFields[index].description = value;
    setInputFields(newInputFields);
  };

  const handleNewInputAdd = () => {
    setInputFields([...inputFields, { id: Date.now(), description: "" }]);
  };

  const handleInputRemove = (id) => {
    const newInputFields = inputFields.filter((field) => field.id !== id);
    setInputFields(newInputFields);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (edit) {
        await handleUpload(e);
      } else {
        await handleEditThoughts(e);
      }
    } catch (error) {
      console.error("Operation failed:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={openModal}
      size={"2xl"}
      popup
      className="z-[99999999999999999]"
      onClose={() => {
        setOpenModal(false);
        setEdit(false);
      }}
    >
      <Modal.Header>Edit Thoughts</Modal.Header>
      <Modal.Body>
        <div className="whiteFrame">
          {/* <h5 className="fontWeight600 fontSize20 mb_24 blackClr_text">
            Edit Thoughts
          </h5> */}

          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="InputFrame mb_24">
                  <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                    Thoughts Title
                    <span className="blooddonorClr_text fontSize16 ml_4">
                      *{" "}
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Thoughts Title"
                    name="blog Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                {errors.title && (
                  <p className="text-red-500 text-sm">{errors.title}</p>
                )}
              </div>

              <div className="col-md-6">
                <div className="InputFrame mb_24">
                  <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                    Hashtags
                    <span className="blooddonorClr_text fontSize12 ml_4">
                    (Add it with #) *{" "}
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Thoughts Description"
                    name="hashtags"
                    value={hashtags}
                    onChange={(e) => setHashtags(e.target.value)}
                  />
                </div>
                {errors.hashtags && (
                  <p className="text-red-500 text-sm">{errors.hashtags}</p>
                )}
              </div>
              {/* 
              <div className="">
                <textarea
                  id="message"
                  rows="4"
                  className="block p-2.5 w-full text-sm font-semibold text-gray-800 bg-formbg rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-0 focus:border-blue-500 focus:placeholder-[#5570f1]  focus:text-black focus:font-medium dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Hashtags *"
                  pattern="[A-Za-z0-9\p{L}]+"
                  value={hashtags}
                  onChange={(e) => setHashtags(e.target.value)}
                ></textarea>
              </div> */}
            </div>
            <div className="col-md-12 mt-3 mb-2">
              {edit ? (
                <Upload count={1} images={newImg} setImages={setNewImage} />
              ) : (
                <div className="flex justify-between items-center">
                  <img src={image} alt="image" className="w-80" />
                  <button
                    onClick={() => setEdit(true)}
                    className="MainButton greenGoneWild_btnclr"
                  >
                    Edit
                  </button>
                </div>
              )}

              <div>
                {inputFields?.length &&
                  inputFields?.map((inputField, index) => (
                    <>
                      <div className="flex gap-4 mb-3 justify-start items-center">
                        {/* <div className=''> */}
                        <div className="col-11">
                          <textarea
                            id="message"
                            rows="4"
                            className="block p-2.5 w-full text-sm font-semibold text-gray-800 bg-formbg rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-0 focus:border-blue-500 focus:placeholder-[#5570f1]  focus:text-black focus:font-medium dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder={`Description ${index + 1}`}
                            pattern="[A-Za-z0-9\p{L}]+"
                            value={inputFields[index].description}
                            onChange={(e) =>
                              handleInputValidationAdd(e.target.value, index)
                            }
                          ></textarea>
                        </div>
                        <div>
                          {inputField.id === 1 ? (
                            <img
                              className="greenGoneWildFilter cursor-pointer"
                              src={AddIcon}
                              alt="EditIcon"
                              onClick={() => handleNewInputAdd()}
                            />
                          ) : (
                            <img
                              className="greenGoneWildFilter cursor-pointer"
                              src={DeleteIcon}
                              alt="EditIcon"
                              onClick={() => handleInputRemove(inputField.id)}
                            />
                          )}
                        </div>
                      </div>
                      {errors.inputFields && (
                        <p className="text-red-500 text-sm">{errors.inputFields}</p>
                      )}
                    </>
                  ))}
              </div>
            </div>

            <button
              className={`MainButton greenGoneWild_btnclr mt-3 flex items-center justify-center ${isLoading ? "cursor-not-allowed" : ""
                }`}
              onClick={handleClick}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <svg
                    className="animate-spin h-5 w-5 mr-2 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                    ></path>
                  </svg>
                  Uploading...</>
              ) : (
                "Update"
              )}
            </button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ThoughtsEditModel;
