import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import RightArrowIcon from "../../../assets/images/rightArrow.svg";
import BlogOneImg from "../../../assets/images/blogOne_img.png";
import LeftGreenIcon from "../../../assets/images/leftGreen_icon.svg";
import Modal from "react-bootstrap/Modal";
import PopUpCloseIcon from "../../../assets/images/popUpClose_icon.svg";

import CopyLinkIcon from "../../../assets/images/copyLink_icon.svg";
import XLogo from "../../../assets/images/x_logo.svg";
import linkedinIcon from "../../../assets/images/linkedin_icon.svg";
import WhatsappLogo from "../../../assets/images/whatsapp_icon.svg";
import FacebookLogo from "../../../assets/images/facebook_icon.svg";
import InstagramLogo from "../../../assets/images/instagram_logo.svg";
import senderRequest from "../../../service/Http";

const ThoughtDetail = () => {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const textToShare =
    "Check out this awesome link: http://localhost:3000/thoughts/3232";

    const [showPopover, setShowPopover] = useState(false);

    const handleCopyLink = () => {
      navigator.clipboard.writeText(window.location.href)
        .then(() => {
          setShowPopover(true);
          setTimeout(() => setShowPopover(false), 2000);
        })
        .catch(err => console.error('Failed to copy: ', err));
    };

  useEffect(() => {
    handleBlogs();
    window.scrollTo(0, 0);
  }, [id]);

  const [latestBlogs, setLatestBlogs] = useState([]);

  const [blogs, setBlogs] = useState([]);
  const handleBlogs = async () => {
    try {
      const response = await senderRequest("get", "api/blogs/get-all", {});
      if (response.data.statusCode === 200) {
        const blogs = response.data.data.filter((blog) => blog?.blog_id !== id);
        const Matchblogs = response.data.data.filter(
          (blog) => blog?.blog_id === id
        );
        setBlogs(Matchblogs);
        setLatestBlogs(blogs);
      } else {
        setBlogs([]);
      }
      console.log(response, "response");
    } catch (error) {
      setBlogs([]);
    }
  };
  const navigate = useNavigate();
  console.log(blogs, "blogs", id, latestBlogs);

  return (
    <>
      <section className="blogDetailPage">
        <div className="container">
          <Link to="/thoughts">
            <div data-aos="fade-up" data-aos-duration="1200">
              <button className="limeMistBorderbtn mb_32">
                <img src={LeftGreenIcon} alt="LeftGreenIcon" />
              </button>
            </div>
          </Link>
          <div
            data-aos="fade-up"
            data-aos-duration="900"
            className="blogMain_img"
          >
            <img src={blogs[0]?.image_url} alt="BlogOneImg" />

            <button className="secondaryBtn position_absolute right_20 top_20 zIndex1">
              {blogs[0]?.created_date}
            </button>
          </div>

          <div className="displayFlex alignItem_center justifyContent_spacebetween mb_40  Mobgap24 MobFlexWrapgap16">
            <div className="mobSpace_mb16">
              <h2
                data-aos="fade-up"
                data-aos-duration="1000"
                className="mobSpace_mb16 drWhiteClr_text fontWeight400 atypDisplay_Font fontSize40 "
              >
                {blogs[0]?.title}
              </h2>

              <ul
                data-aos="fade-up"
                data-aos-duration="1500"
                className="displayFlex alignItem_center gap30 emptyList "
              >
                {blogs[0]?.hashtags?.split("#").filter(item=>item)?.map((item, index) => (
                  <li className="fontSize16 fontWeight400 blankaGreenClr_text syne_Font textdecoration_underline">
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            <div data-aos="fade-up" data-aos-duration="1500">
              <button className="limeMistBorderbtn" onClick={handleShow}>
                Share
              </button>
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="shareViaPopup cursor-pointer"
          >
            <Modal.Header>
              <div>
                <h2 className="syne_Font fontSize22 fontWeight500 whiteClr_text">
                  Share via
                </h2>
              </div>
              <div>
                <button className="emptyBtn" onClick={handleClose}>
                  <img
                    className="width24px"
                    src={PopUpCloseIcon}
                    alt="PopUpCloseIcon"
                  />
                </button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="shareViaLink cursor-pointer">
              {showPopover && (
                      <div className="absolute m-auto bottom-full mb-2 w-32 p-2 bg-gray-800 text-white text-center rounded">
                        Link copied!
                      </div>
                    )}
                <ul>
                  <li>
                    <Link
                      to="https://www.instagram.com/?url=http://localhost:3000/thoughts/3232"
                      target="_blank"
                      className="InstaLink cursor-pointer"
                    >
                      <img
                        src={InstagramLogo}
                        alt="InstagramLogo"
                        className="cursor-pointer"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`https://wa.me/?text=${encodeURIComponent(
                        textToShare
                      )}`}
                      target="_blank"
                      className="whatsappLink"
                    >
                      <img
                        src={WhatsappLogo}
                        alt="WhatsappLogo"
                        className="cursor-pointer"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="fbLink   emptyBtn"
                      target="_blank"
                      to="https://www.facebook.com/sharer/sharer.php?u=http://localhost:3000/thoughts/3232"
                    >
                      <img
                        src={FacebookLogo}
                        alt="FacebookLogo"
                        className="cursor-pointer"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      to="https://twitter.com/intent/tweet?url=http://localhost:3000/thoughts/3232&text=Check%20this%20out!"
                    >
                      <img src={XLogo} alt="XLogo" className="cursor-pointer" />
                    </Link>
                  </li>

                  <li>
                    <Link
                      target="_blank"
                      to="https://www.linkedin.com/sharing/share-offsite/?url=http://localhost:3000/thoughts/3232"
                      className="LinkedInLink"
                    >
                      <img
                        src={linkedinIcon}
                        alt="linkedinIcon"
                        className="cursor-pointer"
                      />
                    </Link>
                  </li>

                  <li className="relative">
                    <button className="emptyBtn" onClick={handleCopyLink}>
                      <img
                        src={CopyLinkIcon}
                        alt="Copy Link"
                        className="cursor-pointer"
                      />
                    </button>
                   
                  </li>
                </ul>
              </div>
            </Modal.Body>
          </Modal>

          {JSON.stringify(blogs[0]?.paragraph) &&
            JSON.parse(blogs[0]?.paragraph).map((item, index) => (
              <p
                data-aos="fade-up"
                data-aos-duration="2100"
                className="fontSize18 fontWeight400 syne_Font whitesmokeClr_text mb_16"
              >
                {item?.description}
              </p>
            ))}
          {/* <p
            data-aos="fade-up"
            data-aos-duration="2100"
            className="fontSize18 fontWeight400 syne_Font whitesmokeClr_text mb_16"
          >
            I browsed dozens of magazines with modern kitchen, minimalist
            kitchens, kitchens small, medium or large, the sofa, the table, no
            table, shelving, lockers with wood, metal and choices were becoming
            increasingly difficult to do. In addition the cost of fitting a
            small kitchen (unfortunately the budget is the one who decides in
            the end) does not exempt me a sigh and were inversely proportional
            to the number of square meters that he occupies. Chennai interior
            designers have tried to give the audience lots of examples to manage
            and get the perfect kitchen, the dream one that every woman would
            love to have.
          </p>

          <p
            data-aos="fade-up"
            data-aos-duration="2200"
            className="fontSize18 fontWeight400 syne_Font whitesmokeClr_text mb_16"
          >
            But big dreams fit in small kitchens and especially in modern
            kitchens and intelligently designed. Until I can show you my new
            kitchen, but I can give you a few ideas that I’ve inspired me and
            that could be helpful to anyone who wants to renovate the space for
            cooking.
          </p>

          <p
            data-aos="fade-up"
            data-aos-duration="2300"
            className="fontSize18 fontWeight400 syne_Font whitesmokeClr_text mb_16"
          >
            For example, you can integrate a beautiful wooden top that can serve
            as foot cooking area as well as dining. You give up completely and
            unified kitchen walls with living with the condition to keep the
            color or general line shapes of the house. Choose bright colors or
            combinations that catch the eye. We all know that in the morning you
            need any stimulus to awaken fresh as life. And we know better we
            might have if you favorite color?
          </p>

          <p
            data-aos="fade-up"
            data-aos-duration="2400"
            className="fontSize18 fontWeight400 syne_Font whitesmokeClr_text mb_16"
          >
            Bring forms futuristic kitchens! If your kid and show these pictures
            to any of them exclaimed “Star Wars !!” a better look to understand
            what we mean when we talk about modern kitchens, and even I could
            tell the future. Use unusual combinations of materials. Do not skimp
            on metal – it’s easy to maintain, looks elegant and impressive and
            is durable over time, or better combine metal with colored glass.
          </p>

          <p
            data-aos="fade-up"
            data-aos-duration="2500"
            className="fontSize18 fontWeight400 syne_Font whitesmokeClr_text mb_16"
          >
            Last but not least, make sure that your kitchen will reflect your
            personality. Carefully select decorative objects and subtle finish
            to leave your personal stamp on your space.
          </p>

          <p
            data-aos="fade-up"
            data-aos-duration="2600"
            className="fontSize18 fontWeight400 syne_Font whitesmokeClr_text mb_16"
          >
            For planning or renovating a kitchen requires a variety of
            materials, appliances and furniture. No wonder, because the kitchen
            is the room most “functional” around the house. Therefore, when it
            comes to decorative style, sometimes regardless of the overall style
            of the house, the kitchen can suffer a range of influences and
            decorative style.
          </p>

          <p
            data-aos="fade-up"
            data-aos-duration="2700"
            className="fontSize18 fontWeight400 syne_Font whitesmokeClr_text mb_16"
          >
            A vintage kitchen does not sound bad, that offers more style and
            elegance both the kitchen and the house.
          </p>

          <p
            data-aos="fade-up"
            data-aos-duration="2800"
            className="fontSize18 fontWeight400 syne_Font whitesmokeClr_text mb_16"
          >
            Either way, I strongly believe that a kitchen you need to represent
            yourself, you spend time in it cooking for family and friends,
            children or your soul. You need to feel good in it, to be in harmony
            with what surrounds you from cabinets to tile and tableware.
          </p>

          <p
            data-aos="fade-up"
            data-aos-duration="2900"
            className="fontSize18 fontWeight400 syne_Font whitesmokeClr_text mb_40"
          >
            If You like to dream with open eyes like me or not, you’ve got a job
            and looking for ideas for decorating the kitchen, I will jump in
            real quick help with a series of images that covers all the great
            tastes in terms of materials, design and colors.
          </p> */}

          <div className="verifiedBlackBottomborder mb_40"></div>

          <div
            data-aos="fade-up"
            data-aos-duration="3200"
            className="displayFlex alignItem_center justifyContent_spacebetween mb_40"
          >
            <div>
              <h3 className="fontSize32  fontWeight500 drWhiteClr_text syne_Font">
                Latest Thoughts
              </h3>
            </div>
            <Link to="/thoughts">
              <button className="limeMistBorderbtn">View all</button>
            </Link>
          </div>

          <div className="row">
            {latestBlogs?.map((blog, index) => (
              <div
                data-aos="fade-up"
                data-aos-duration="2000"
                className="col-lg-4 mb_34 leftToRight cursor-pointer"
                onClick={() => navigate(`/thoughts/${blog.blog_id}`)}
              >
                <div className="blogItem">
                  <div className="blogItemImage">
                    <img src={blog?.image_url} alt="BlogOneImg" />
                    <button className="secondaryBtn position_absolute right_20 top_20 zIndex1">
                      {blog?.created_date}
                    </button>
                  </div>
                  <div className="displayFlex alignItem_center justifyContent_spacebetween">
                    <div>
                      <h5 className="fontSize16 fontWeight400 drWhiteClr_text atypDisplay_Font">
                        {blog?.title}
                      </h5>
                    </div>
                    <div>
                      <p className="fontSize14 fontWeight400 blankaGreenClr_text syne_Font textdecoration_underline">
                        {blog?.hashtags?.split("#")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <section className="contactus_marquee">
        <div className="container-fluid">
          <div className="main-marq">
            <div className="slide-har st1">
              <div className="box">
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default ThoughtDetail;
