import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Flag from "react-world-flags";

import ProjectManagerIcon from "../../assets/images/project_manager_icon.svg";
import VideoEditorIcon from "../../assets/images/videoEditor_icon.svg";
import SrArchitectIcon from "../../assets/images/srArchitect_icon.svg";
import PopUpCloseIcon from "../../assets/images/popUpClose_icon.svg";
import CheckCircleIcon from "../../assets/images/checkCircle_icon.svg";
import SmallCloseIcon from "../../assets/images/smallClose_icon.svg";
import senderRequest from "../../service/Http";
import toast from "react-hot-toast";
import { handleInputValidation } from "./../../helper/validator";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const Careers = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    handleCareer();
    window.scrollTo(0, 0);
  }, []);

  
  

  const [caroselContent, setCaroselContent] = useState([]);

  const handleShow = (item) => {
    setShow(true);
    setCaroselContent(item);
  };

  const countryCodes = [
    { code: "+1", name: "USA", flag: "https://flagcdn.com/us.svg" },
    { code: "+44", name: "UK", flag: "https://flagcdn.com/gb.svg" },
    { code: "+91", name: "India", flag: "https://flagcdn.com/in.svg" },
    { code: "+61", name: "Australia", flag: "https://flagcdn.com/au.svg" },
    { code: "+81", name: "Japan", flag: "https://flagcdn.com/jp.svg" },
    { code: "+49", name: "Germany", flag: "https://flagcdn.com/de.svg" },
    { code: "+33", name: "France", flag: "https://flagcdn.com/fr.svg" },
    { code: "+39", name: "Italy", flag: "https://flagcdn.com/it.svg" },
    { code: "+86", name: "China", flag: "https://flagcdn.com/cn.svg" },
    { code: "+34", name: "Spain", flag: "https://flagcdn.com/es.svg" },
    { code: "+7", name: "Russia", flag: "https://flagcdn.com/ru.svg" },
    { code: "+20", name: "Egypt", flag: "https://flagcdn.com/eg.svg" },
    { code: "+27", name: "South Africa", flag: "https://flagcdn.com/za.svg" },
    { code: "+82", name: "South Korea", flag: "https://flagcdn.com/kr.svg" },
    { code: "+55", name: "Brazil", flag: "https://flagcdn.com/br.svg" },
    { code: "+54", name: "Argentina", flag: "https://flagcdn.com/ar.svg" },
    { code: "+52", name: "Mexico", flag: "https://flagcdn.com/mx.svg" },
    { code: "+64", name: "New Zealand", flag: "https://flagcdn.com/nz.svg" },
    { code: "+358", name: "Finland", flag: "https://flagcdn.com/fi.svg" },
    { code: "+47", name: "Norway", flag: "https://flagcdn.com/no.svg" },
    { code: "+46", name: "Sweden", flag: "https://flagcdn.com/se.svg" },
    { code: "+41", name: "Switzerland", flag: "https://flagcdn.com/ch.svg" },
    { code: "+31", name: "Netherlands", flag: "https://flagcdn.com/nl.svg" },
    { code: "+32", name: "Belgium", flag: "https://flagcdn.com/be.svg" },
    { code: "+30", name: "Greece", flag: "https://flagcdn.com/gr.svg" },
    { code: "+48", name: "Poland", flag: "https://flagcdn.com/pl.svg" },
    { code: "+351", name: "Portugal", flag: "https://flagcdn.com/pt.svg" },
    { code: "+90", name: "Turkey", flag: "https://flagcdn.com/tr.svg" },
    { code: "+66", name: "Thailand", flag: "https://flagcdn.com/th.svg" },
    { code: "+65", name: "Singapore", flag: "https://flagcdn.com/sg.svg" },
    { code: "+62", name: "Indonesia", flag: "https://flagcdn.com/id.svg" },
    { code: "+63", name: "Philippines", flag: "https://flagcdn.com/ph.svg" },
    { code: "+60", name: "Malaysia", flag: "https://flagcdn.com/my.svg" },
    { code: "+95", name: "Myanmar", flag: "https://flagcdn.com/mm.svg" },
    { code: "+84", name: "Vietnam", flag: "https://flagcdn.com/vn.svg" },
    { code: "+92", name: "Pakistan", flag: "https://flagcdn.com/pk.svg" },
    { code: "+880", name: "Bangladesh", flag: "https://flagcdn.com/bd.svg" },
    { code: "+964", name: "Iraq", flag: "https://flagcdn.com/iq.svg" },
    { code: "+98", name: "Iran", flag: "https://flagcdn.com/ir.svg" },
    { code: "+972", name: "Israel", flag: "https://flagcdn.com/il.svg" },
    { code: "+966", name: "Saudi Arabia", flag: "https://flagcdn.com/sa.svg" },
    {
      code: "+971",
      name: "United Arab Emirates",
      flag: "https://flagcdn.com/ae.svg",
    },
    { code: "+973", name: "Bahrain", flag: "https://flagcdn.com/bh.svg" },
    { code: "+974", name: "Qatar", flag: "https://flagcdn.com/qa.svg" },
    { code: "+965", name: "Kuwait", flag: "https://flagcdn.com/kw.svg" },
    { code: "+968", name: "Oman", flag: "https://flagcdn.com/om.svg" },
    { code: "+961", name: "Lebanon", flag: "https://flagcdn.com/lb.svg" },
    { code: "+962", name: "Jordan", flag: "https://flagcdn.com/jo.svg" },
    { code: "+216", name: "Tunisia", flag: "https://flagcdn.com/tn.svg" },
    { code: "+213", name: "Algeria", flag: "https://flagcdn.com/dz.svg" },
    { code: "+212", name: "Morocco", flag: "https://flagcdn.com/ma.svg" },
    { code: "+221", name: "Senegal", flag: "https://flagcdn.com/sn.svg" },
    { code: "+225", name: "Ivory Coast", flag: "https://flagcdn.com/ci.svg" },
    { code: "+233", name: "Ghana", flag: "https://flagcdn.com/gh.svg" },
    { code: "+234", name: "Nigeria", flag: "https://flagcdn.com/ng.svg" },
    { code: "+254", name: "Kenya", flag: "https://flagcdn.com/ke.svg" },
    { code: "+256", name: "Uganda", flag: "https://flagcdn.com/ug.svg" },
    { code: "+255", name: "Tanzania", flag: "https://flagcdn.com/tz.svg" },
    { code: "+250", name: "Rwanda", flag: "https://flagcdn.com/rw.svg" },
    { code: "+258", name: "Mozambique", flag: "https://flagcdn.com/mz.svg" },
    { code: "+263", name: "Zimbabwe", flag: "https://flagcdn.com/zw.svg" },
    { code: "+852", name: "Hong Kong", flag: "https://flagcdn.com/hk.svg" },
    { code: "+853", name: "Macau", flag: "https://flagcdn.com/mo.svg" },
    { code: "+855", name: "Cambodia", flag: "https://flagcdn.com/kh.svg" },
    { code: "+856", name: "Laos", flag: "https://flagcdn.com/la.svg" },
    { code: "+93", name: "Afghanistan", flag: "https://flagcdn.com/af.svg" },
    { code: "+94", name: "Sri Lanka", flag: "https://flagcdn.com/lk.svg" },
    { code: "+960", name: "Maldives", flag: "https://flagcdn.com/mv.svg" },
    { code: "+963", name: "Syria", flag: "https://flagcdn.com/sy.svg" },
    { code: "+967", name: "Yemen", flag: "https://flagcdn.com/ye.svg" },
    { code: "+970", name: "Palestine", flag: "https://flagcdn.com/ps.svg" },
    { code: "+975", name: "Bhutan", flag: "https://flagcdn.com/bt.svg" },
    { code: "+976", name: "Mongolia", flag: "https://flagcdn.com/mn.svg" },
    { code: "+977", name: "Nepal", flag: "https://flagcdn.com/np.svg" },
    { code: "+992", name: "Tajikistan", flag: "https://flagcdn.com/tj.svg" },
    { code: "+993", name: "Turkmenistan", flag: "https://flagcdn.com/tm.svg" },
    { code: "+994", name: "Azerbaijan", flag: "https://flagcdn.com/az.svg" },
    { code: "+995", name: "Georgia", flag: "https://flagcdn.com/ge.svg" },
    { code: "+996", name: "Kyrgyzstan", flag: "https://flagcdn.com/kg.svg" },
    { code: "+998", name: "Uzbekistan", flag: "https://flagcdn.com/uz.svg" },
    { code: "+1242", name: "Bahamas", flag: "https://flagcdn.com/bs.svg" },
    { code: "+1246", name: "Barbados", flag: "https://flagcdn.com/bb.svg" },
    { code: "+1264", name: "Anguilla", flag: "https://flagcdn.com/ai.svg" },
    {
      code: "+1268",
      name: "Antigua and Barbuda",
      flag: "https://flagcdn.com/ag.svg",
    },
    {
      code: "+1284",
      name: "British Virgin Islands",
      flag: "https://flagcdn.com/vg.svg",
    },
    {
      code: "+1340",
      name: "US Virgin Islands",
      flag: "https://flagcdn.com/vi.svg",
    },
    {
      code: "+1345",
      name: "Cayman Islands",
      flag: "https://flagcdn.com/ky.svg",
    },
    { code: "+1441", name: "Bermuda", flag: "https://flagcdn.com/bm.svg" },
    { code: "+1473", name: "Grenada", flag: "https://flagcdn.com/gd.svg" },
    {
      code: "+1649",
      name: "Turks and Caicos Islands",
      flag: "https://flagcdn.com/tc.svg",
    },
    { code: "+1664", name: "Montserrat", flag: "https://flagcdn.com/ms.svg" },
    {
      code: "+1670",
      name: "Northern Mariana Islands",
      flag: "https://flagcdn.com/mp.svg",
    },
    { code: "+1671", name: "Guam", flag: "https://flagcdn.com/gu.svg" },
    {
      code: "+1684",
      name: "American Samoa",
      flag: "https://flagcdn.com/as.svg",
    },
    { code: "+1721", name: "Sint Maarten", flag: "https://flagcdn.com/sx.svg" },
    { code: "+1758", name: "Saint Lucia", flag: "https://flagcdn.com/lc.svg" },
    { code: "+1767", name: "Dominica", flag: "https://flagcdn.com/dm.svg" },
    {
      code: "+1784",
      name: "Saint Vincent and the Grenadines",
      flag: "https://flagcdn.com/vc.svg",
    },
    {
      code: "+1809",
      name: "Dominican Republic",
      flag: "https://flagcdn.com/do.svg",
    },
    {
      code: "+1829",
      name: "Dominican Republic",
      flag: "https://flagcdn.com/do.svg",
    },
    {
      code: "+1849",
      name: "Dominican Republic",
      flag: "https://flagcdn.com/do.svg",
    },
    {
      code: "+1868",
      name: "Trinidad and Tobago",
      flag: "https://flagcdn.com/tt.svg",
    },
    {
      code: "+1869",
      name: "Saint Kitts and Nevis",
      flag: "https://flagcdn.com/kn.svg",
    },
    { code: "+1876", name: "Jamaica", flag: "https://flagcdn.com/jm.svg" },
  ];

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");

  const [number, setNumber] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [links, setLinks] = useState("");
  const [pdf, setPdf] = useState("");

  const handleClose = () => {
    setShow(false);
    setName("");
    setEmail("");
    setPhoneNumber("");
    setCountryCode("+91");
    setNumber("");
    setPdf(null);
  };

  const [role, setRole] = useState("");

  const [pdfId, setPdfId] = useState("");

  const [career, setCareer] = useState([]);

  const handleCareer = async () => {
    try {
      const response = await senderRequest(
        "get",
        "api/career/fetch-all-jobs",
        "",
        "",
        ""
      );
      if (response.data.statusCode === 200) {
        console.log(response, "response");
        setCareer(response.data.data);
      } else {
        setCareer([]);
      }
    } catch (error) {
      setCareer([]);
    }
  };

  const handleResumeSubmit = async (e, roles) => {
    e.preventDefault();
    try {
      if (!pdf) {
        toast.error("Please upload your resume");
        return;
      }
      console.log(pdf, "pdf");
      const data = new FormData();
      data.append("files", pdf);
      //   let body = {
      //     files: pdf,
      //   };

      const response = await senderRequest(
        "post-photo",
        "api/image/upload",
        "",
        "",
        data
      );
      if (response.data.statusCode === 200) {
        setPdfId(response.data.data.image_url);
        console.log(
          response.data.data.image_url,
          "response.data.data.image_url"
        );
        toast.success(response.data.message);
        handleFormSubmit(e, response.data.data.image_url, roles);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleFormSubmit = async (e, resumeLink, roles) => {
    e.preventDefault();
    if (!name || !email || !phoneNumber) {
      toast.error("Please fill all the fields");
      return;
    }
    try {
      const body = {
        name: name,
        mail: email,
        phone_number: countryCode + "-" + phoneNumber,
        social_media_link: links,
        resume: resumeLink,
        role: roles,
      };
      const response = await senderRequest(
        "post",
        "api/career/apply",
        "",
        "",
        body
      );
      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
        setShow(false);
        setName("");
        setEmail("");
        setPhoneNumber("");
        setCountryCode("+91");
        setNumber("");
        setLinks("");
        setPdf(null);
      } else {
        toast.success(response.data.message);
        setShow(false);
        setName("");
        setEmail("");
        setPhoneNumber("");
        setCountryCode("+91");
        setNumber("");
        setLinks("");
        setPdf(null);
      }
    } catch (error) {
      setShow(false);
      setName("");
      setEmail("");
      setPhoneNumber("");
      setCountryCode("+91");
      setNumber("");
      setLinks("");
      setPdf(null);
      setRole("");
      toast.error(error.message);
    }
  };
  const handleFileChange = (e) => {
    setPdf(e.target.files[0]);
  };
  return (
    <>
      <section className="commonPage_bnr careers_bnr">
        <div className="container">
          <div className="row alignItem_center">
            <div
              data-aos="fade-down-right"
              data-aos-duration="1500"
              className="col-lg-6 mobSpace_mb24"
            >
              <p className="fontSize24  fontWeight400 cerebralGreyClr_text syne_Font">
                Join MetaSkapes, the visionary force reshaping the future of
                Architecture and Design while transforming spaces in all
                verticals from conscientious corporate to dynamic smart cities.
              </p>
            </div>
            <div
              data-aos="fade-down-left"
              data-aos-duration="1500"
              className="col-lg-6"
            >
              <div className="sectionTitle">
                <h2 className="atypDisplay_Font textalign_right">
                  JOIN OUR TEAM
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="welcomrToOurTeam_sec">
        <div className="container">
          <h2
            data-aos="fade-up"
            data-aos-duration="2000"
            className="atypDisplay_Font mb_40 fontSize40 fontWeight400 drWhiteClr_text textalign_center"
          >
            JOIN OUR TEAM
          </h2>

          <div
            className="row justifyContent_center pb_80"
            data-aos="fade-up"
            data-aos-duration="2500"
          >
            <div className="col-lg-7">
              <p className="syne_Font  SteelWoolClr_text fontSize24 textalign_center ">
                Explore our current job openings and find the perfect role that
                aligns with your skills and aspirations. Whether you are an
                Architect, Engineer, Interior Designer, Project Manager, or
                Marketing Strategist, we offer a variety of opportunities that
                match your expertise.
              </p>
            </div>
          </div>

          <div className="row">
            {career?.map((item, index) => (
              <div
                data-aos="fade-up"
                data-aos-duration="2000"
                className="col-lg-4 mb_24"
              >
                <div className="careerItem" onClick={() => handleShow(item)}>
                  <div className="careerItem_icon mb_24">
                    <img src={item.icon_url} alt="ProjectManagerIcon" />
                  </div>

                  <h4 className="atypDisplay_Font fontSize24 whiteClr_text fontWeight400 mb_16">
                    {item?.roles}
                  </h4>
                  <p className="syne_Font SteelWoolClr_text fontSize16 fontWeight400 mb_34">
                    {item?.description.length > 150
                      ? item?.description?.slice(0, 150)
                      : item?.description}
                  </p>

                  <button
                    onClick={() => handleShow(item)}
                    className="marginLeft_Auto display_block"
                  >
                    View
                  </button>
                </div>
              </div>
            ))}
            {/* <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="col-lg-4 mb_24"
            >
              <div className="careerItem">
                <div className="careerItem_icon mb_24">
                  <img src={VideoEditorIcon} alt="VideoEditorIcon" />
                </div>

                <h4 className="atypDisplay_Font fontSize24 whiteClr_text fontWeight400 mb_16">
                  Video editor
                </h4>
                <p className="syne_Font SteelWoolClr_text fontSize16 fontWeight400 mb_34">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>

                <button className="marginLeft_Auto display_block">View</button>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="col-lg-4 mb_24"
            >
              <div className="careerItem">
                <div className="careerItem_icon mb_24">
                  <img src={SrArchitectIcon} alt="SrArchitectIcon" />
                </div>

                <h4 className="atypDisplay_Font fontSize24 whiteClr_text fontWeight400 mb_16">
                  Sr Architect
                </h4>
                <p className="syne_Font SteelWoolClr_text fontSize16 fontWeight400 mb_34">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>

                <button className="marginLeft_Auto display_block">View</button>
              </div>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="col-lg-4 mb_24"
            >
              <div className="careerItem">
                <div className="careerItem_icon mb_24">
                  <img src={VideoEditorIcon} alt="VideoEditorIcon" />
                </div>

                <h4 className="atypDisplay_Font fontSize24 whiteClr_text fontWeight400 mb_16">
                  3d Designer
                </h4>
                <p className="syne_Font SteelWoolClr_text fontSize16 fontWeight400 mb_34">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>

                <button className="marginLeft_Auto display_block">View</button>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="col-lg-4 mb_24"
            >
              <div className="careerItem">
                <div className="careerItem_icon mb_24">
                  <img src={SrArchitectIcon} alt="SrArchitectIcon" />
                </div>

                <h4 className="atypDisplay_Font fontSize24 whiteClr_text fontWeight400 mb_16">
                  Jr Architect
                </h4>
                <p className="syne_Font SteelWoolClr_text fontSize16 fontWeight400 mb_34">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>

                <button className="marginLeft_Auto display_block">View</button>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="col-lg-4 mb_24"
            >
              <div className="careerItem">
                <div className="careerItem_icon mb_24">
                  <img src={VideoEditorIcon} alt="VideoEditorIcon" />
                </div>

                <h4 className="atypDisplay_Font fontSize24 whiteClr_text fontWeight400 mb_16">
                  Jr Designer
                </h4>
                <p className="syne_Font SteelWoolClr_text fontSize16 fontWeight400 mb_34">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>

                <button className="marginLeft_Auto display_block">View</button>
              </div>
            </div> */}
          </div>
        </div>

        {/* backdrop="static" */}
        <Modal
          backdrop="static"
          show={show}
          onHide={handleClose}
          centered
          keyboard={false}  
          className="careerPopup cursor-pointer"
        >
        
          <Modal.Header>
            <div>
              <h2>{caroselContent.roles}</h2>
            </div>
            <div>
              <p
                className="emptyBtn cursor-pointer"
                style={{ cursor: "pointer !important" }}
                onClick={handleClose}
              >
                <img
                  className="width24px"
                  src={PopUpCloseIcon}
                  alt="PopUpCloseIcon"
                />
              </p>
            </div>
          </Modal.Header>
          <Modal.Body>
            <h4 className="cerebralGreyClr_text fontSize24 fontWeight400 syne_Font mb_16">
              Job Responsibilities:
            </h4>

            <div className="row">
              <div className="col-lg-8">
                <p className="SteelWoolClr_text fontSize16 fontWeight400 syne_Font mb_24">
                  {caroselContent.description}
                </p>
              </div>
            </div>

            <div className="rolesTab ">
              <ul>
                {caroselContent?.keywords
                  ?.split("#")
                  ?.filter((item) => item.trim() !== "")
                  .map((item, index) => (
                    <li key={index}>
                      <button  className="atypDisplay_Font">
                        {item.trim().endsWith(",")
                          ? item.trim().slice(0, -1)
                          : item.trim()}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>

            <h4 className="cerebralGreyClr_text fontSize24 fontWeight400 syne_Font mb_16">
              Requirements:
            </h4>

            <ul className="RequirementList">
              {caroselContent?.responsibility
                ? JSON.parse(caroselContent.responsibility).map((item) => (
                    <li className="SteelWoolClr_text fontSize16 fontWeight400 syne_Font mb_12">
                      {item.Requirements}
                    </li>
                  ))
                : null}
            </ul>

            <div className="row ">
              <div className="col-lg-6 mb_24 atypDisplay_Font">
                <div>
                  <input
                    type="text"
                    placeholder="Your name *"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="borderInput"
                  />
                </div>
              </div>
              <div className="col-lg-6 mb_24">
                <div>
                  <input
                    type="text"
                    placeholder="Your mail (contacting) *"
                    className="borderInput"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6 mb_24">
                <div className="GroupBorderinput">
                  <div className="input-group-prepend">
                    <select
                      className="GroupBorderinput_text focus:right-0"
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                    >
                      {countryCodes.map((country) => (
                        <option key={country.code} value={country.code}>
                          <Flag
                            code={country.code.substring(1)}
                            className="flag-icon"
                          />
                          <p className="mr-10">{country.code}</p>
                        </option>
                      ))}
                    </select>
                  </div>
                  <input
                    type="tel"
                    placeholder="Enter Phone number *"
                    pattern="[0-9]"
                    value={phoneNumber}
                    onChange={(e) =>
                      handleInputValidation(e.target.value, setPhoneNumber, 8)
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6 mb_24">
                <div>
                  <input
                    type="text"
                    placeholder="Portfolio link"
                    className="borderInput"
                    value={links}
                    onChange={(e) => setLinks(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="GroupBorderinput ">
                <input
                  type="file"
                  id="file-upload"
                  placeholder="Submit your resume here * (preferred format: pdf)"
                  accept=".pdf"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="file-upload"
                  className="custom-file-upload GroupBorderinput_text cursor-pointer flex w-[97%]"
                >
                  {pdf
                    ? "Update Resume"
                    : "Attach your latest  Resume / CV * (preferred format: pdf)"}
                </label>
                {pdf && <p className="GroupBorderinput_text">Selected file: {pdf.name}</p>}

                <div className="input-group-prepend">
                  <span className="GroupBorderinput_text">
                    <button
                      className="smallbutton"
                      onClick={(e) =>
                        handleResumeSubmit(e, caroselContent?.roles)
                      }
                    >
                      Add
                    </button>
                  </span>
                </div>
              </div>
            </div>
            
          </Modal.Body>
        </Modal>

        {/* successfull popup */}

        {/* <Modal show={show} onHide={handleClose} centered>
                    
                    <Modal.Body>
                        <div className='displayFlex justifyContent_center mb_40'>
                            <div>
                            <img src={CheckCircleIcon} alt='CheckCircleIcon' />
                            </div>
                            </div>
                        <h4 className='whiteClr_text fontSize34 fontWeight400 atypDisplay_Font mb_16 textalign_center'>Form submitted successfully!</h4>

                        <p className='SteelWoolClr_text fontSize16 fontWeight400 syne_Font mb_34 textalign_center'>Our team will contact you soon!</p>

                        <div className='textalign_center'>
                            <button className='limeMistFullClrbtn'>Back to home</button>

                        </div>
                       
                    </Modal.Body>

                </Modal> */}
      </section>
      {/* <section className="contactus_marquee">
        <div className="container-fluid">
          <div className="main-marq">
            <div className="slide-har st1">
              <div className="box">
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Careers;
