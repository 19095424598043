import React, { useState } from "react";
import toast from "react-hot-toast";
import ImageUploading from "react-images-uploading"; // Correct the import
import senderRequest from "../service/Http";

const Upload = ({ count, images, setImages, Edit, curoselImgId, icons }) => {
  // const [images, setImages] = useState([]);
  console.log(images, "images");
  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, "imageList");
    setImages(imageList);
  };
  const handleImageRemove = (id) => {
    try {
      console.log(id, "id");
      const response = senderRequest("delete", "api/image/delete/" + id);
      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
      }
    } catch (error) { }
  };

  return (
    <div>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={count}
        dataURLKey="image_url"
        acceptType={["jpg", "jpeg", "png", "svg"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // Building UI
          <div className="upload__image-wrapper">
            <div className="flex gap-2">
              <p
                className="MainButton greenGoneWild_btnclr cursor-pointer"
                onClick={onImageUpload}
                {...dragProps}
              >
                Click or Drop here
              </p>
              &nbsp;
              {/* <p
                className="MainButton bg-red-600 text-white cursor-pointer"
                onClick={onImageRemoveAll}
              >
                Remove all images
              </p> */}
            </div>
            <div className="grid grid-cols-4 gap-3 items-center justify-start">
              {imageList
                ?.filter(image => image?.image_url)
                .map((image, index) => (
                  <div key={index} className="image-item mt-3">
                    <img src={`${image?.image_url}`} className="w-80" alt="" />
                    <div className="image-item__btn-wrapper flex gap-2 mt-2 mb-2">
                      <p
                        className="MainButton greenGoneWild_btnclr cursor-pointer"
                        onClick={() => onImageUpdate(index)}
                      >
                        Update Image
                      </p>

                      <p
                        className="MainButton bg-red-600 text-white cursor-pointer"
                        onClick={() => {
                          if (curoselImgId) {
                            handleImageRemove(image?.image_id); 
                          }
                          onImageRemove(index);
                        }}
                      >
                        Remove
                      </p>
                    </div>
                  </div>
                ))}

            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default Upload;
