import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BlogOneimg from "../../assets/images/blogOne_img.png";
import EditIcon from "../../assets/images/edit_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import AddIcon from "../../assets/images/add_icon.svg";
import ViewIcon from "../../assets/images/view_icon.svg";
import senderRequest from "../../service/Http";
import toast from "react-hot-toast";
import DeleteModal from "./DeleteModal";
import QueryCallSchedule from "./QueryCallSchedule";
const Queries = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const route = pathname?.split("/")[1];
  const [queries, setQueries] = useState("");
  const [businessQuery, setBusinessQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isSchedule, setisSchedule] = useState("");
  const [ScheduleId, setScheduleId] = useState("");
  const [OpenModalSchedule, SetOpenModalSchedule] = useState("");
  const [ScheuledEmail, SetScheuledEmail] = useState("");
  const [schedule , setSchedule] = useState("");
  useEffect(() => {
    handleGetQueries();
  }, []);

  const [type, setType] = useState("generalQuery");

  const handleGetQueries = async () => {
    try {
      const response = await senderRequest("get", "api/admin/get-all", {});
      if (response.data.statusCode === 200) {
        console.log(response, "response");
        setQueries(response?.data?.data?.generalQuery);
        setBusinessQuery(response.data.data.BusinessQuery);
      }
    } catch (error) {
      console.log(error);
      setQueries([]);
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      const response = await senderRequest(
        "delete",
        `api/admin/delete/${id}`,
        {}
      );
      if (response.data.statusCode === 200) {
        console.log(response, "response");
        toast.success(response.data.message);
        setShowModal(false);
        handleGetQueries();
      }
    } catch (error) {
      console.log(error);
      setShowModal(false);
    }
  };

  const handleStatusChange = async (id, status) => {
    try {
      const response = await senderRequest(
        "put",
        `api/admin/change-query-status/${id}/${status}`,
        {},
        {}
      );
      if (response.data.statusCode === 200) {
        console.log(response, "response");
        toast.success(response.data.message);
        handleGetQueries();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="displayFlex alignItem_center justifyContent_spacebetween mb_24">
        <div>
          <h2 className="fontSize24 fontWeight600 blackClr_text">Queries</h2>
        </div>
        <div></div>
      </div>

      {/* <div>
        <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
          <li className="me-2" onClick={() => setType("general")}>
            <a
              href="#"
              aria-current="page"
              className={
                type === "general"
                  ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
                  : "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
              }
            >
              General
            </a>
          </li>
          <li className="me-2" onClick={() => setType("business")}>
            <a
              href="#"
              className={
                type === "business"
                  ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
                  : "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
              }
            >
              Business
            </a>
          </li>
        </ul>
      </div> */}
      {type === "business" ? (
        <div className="Table_Wrapper tableResponsive">
          <table className="mb_24">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Company / Organization</th>
                <th>Project Type</th>
                <th>Schedule Call</th>
                <th>How Did you Know About Us</th>
                <th>Message</th>
                <th>status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {businessQuery?.length > 0 &&
                businessQuery?.map((item, index) => (
                  <tr>
                    <td> {index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.mobile}</td>
                    <td>{item.company}</td>
                    <td>{item.project_type}</td>
                    <td>
                      {item.status === true ? (
                        ""
                      ) : (
                        <button
                          className={`p-2 rounded-lg ${
                            item?.date === null
                              ? "bg-[#92d43f] border border-[#92d43f] text-white"
                              : "border border-[#92d43f] text-[#92d43f]"
                          }`}
                          onClick={() => {
                            SetScheuledEmail(item.email);
                            SetOpenModalSchedule(true);
                            setScheduleId(item?.id);
                            setSchedule(item)
                          }}
                        >
                          { item?.date === null ? "Schedule Call" : " ReSchedule Call"}
                        </button>
                      )}
                    </td>
                    <td>{item.about_project}</td>
                    <td>{item.referral}</td>
                    <td>
                      <label className="inline-flex items-center cursor-pointer">
                        <select
                          value={item.status ? "Completed" : "InProgress"}
                          onChange={(e) =>
                            handleStatusChange(
                              item.id,
                              e.target.value === "Completed"
                            )
                          }
                          className="form-select block w-32 bg-gray-200 text-gray-900 dark:bg-gray-700 dark:text-gray-300 rounded-full focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800"
                        >
                          <option value="InProgress">InProgress</option>
                          <option value="Completed">Completed</option>
                        </select>
                        {/* <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                          {item.status ? "Completed" : "InProgress"}
                        </span> */}
                      </label>
                    </td>
                    <td>
                      {/* <button
                      className="actionBtn"
                      onClick={() => handleDeleteQueries(item.id)}
                    >
                     
                    </button> */}
                      <button
                        className="actionBtn"
                        onClick={() => {
                          setDeleteId(item.id);
                          setShowModal(true);
                        }}
                      >
                        <img
                          className="bloodDonorFilter"
                          src={DeleteIcon}
                          alt="DeleteIcon"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="Table_Wrapper tableResponsive">
          <table className="mb_24">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Company</th>
                <th>Message</th>
                <th>Schedule Call</th>
                <th>status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {queries?.length > 0 &&
                queries?.map((item, index) => (
                  <tr>
                    <td> {index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.mobile}</td>
                    <td>{item.company}</td>
                    <td>{item.query}</td>
                    <td>
                      {item.status === true ? (
                        ""
                      ) : (
                        <button
                          className={`p-2 rounded-lg ${
                            item?.date === null
                              ? "bg-[#92d43f] border border-[#92d43f] text-white"
                              : "border border-[#92d43f] text-[#92d43f]"
                          }`}
                          onClick={() => {
                            SetScheuledEmail(item.email);
                            SetOpenModalSchedule(true);
                            setScheduleId(item?.id);
                            setSchedule(item)
                          }}
                        >
                          { item?.date === null ? "Schedule Call" : " ReSchedule Call"}
                        </button>
                      )}
                    </td>
                    <td>
                      <label className="inline-flex items-center cursor-pointer">
                        <select
                          value={item.status ? "Completed" : "InProgress"}
                          onChange={(e) =>
                            handleStatusChange(
                              item.id,
                              e.target.value === "Completed"
                            )
                          }
                          className="form-select block w-32 bg-gray-200 text-gray-900 dark:bg-gray-700 dark:text-gray-300 rounded-full focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800"
                        >
                          <option value="InProgress">InProgress</option>
                          <option value="Completed">Completed</option>
                        </select>
                      </label>
                    </td>
                    <td>
                      <button
                        className="actionBtn"
                        onClick={() => {
                          setDeleteId(item.id);
                          setShowModal(true);
                        }}
                      >
                        <img
                          className="bloodDonorFilter"
                          src={DeleteIcon}
                          alt="DeleteIcon"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      <DeleteModal
        openModal={showModal}
        setOpenModal={setShowModal}
        handleDelete={handleDelete}
        id={deleteId}
        name="Query"
      />

      <QueryCallSchedule
        openModal={OpenModalSchedule}
        setOpenModal={SetOpenModalSchedule}
        email={ScheuledEmail}
        ScheduleId={ScheduleId}
        getAllQuery={handleGetQueries}
        schedule={schedule}
      />
    </>
  );
};

export default Queries;
