import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "../../../assets/images/edit_icon.svg";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import AddIcon from "../../../assets/images/add_icon.svg";
import ViewIcon from "../../../assets/images/view_icon.svg";
import senderRequest from "../../../service/Http";
import DeleteModal from "../DeleteModal";
const BusinessQuery = () => {
  // const [openModal, setOpenModal] = useState(false);
  const [career, setCareer] = useState([]);
  // const route = pathname?.split("/")[1];

  useEffect(() => {
    handleCareer();
  }, []);

  const [id, setId] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleCareer = async () => {
    try {
      const response = await senderRequest(
        "get",
        "api/career/fetch-employee",
        "",
        "",
        {}
      );
      if (response.data.statusCode === 200) {
        console.log(response, "response");
        setCareer(response?.data?.data);
      } else {
        setCareer("");
      }
    } catch (error) {
      setCareer("");
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      const response = await senderRequest(
        "delete",
        `api/career/delete-employee/${id}`,
        "",
        "",
        {}
      );
      if (response.data.statusCode === 200) {
        console.log(response, "response");
        // setOpenModal(false);
        setOpenDeleteModal(false);
        handleCareer();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div>
      <div className="displayFlex overflow-x-auto alignItem_center justifyContent_spacebetween mb_24"></div>
      <div className="Table_Wrapper tableResponsive">
        <table className="mb_24 min-w[1400px]">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Email Id</th>
              <th>Contact Number </th>
              <th>Portfolio Links</th>
              <th>Resume</th>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {career &&
              career?.map((item, index) => (
                <tr>
                  <td> {index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.mail}</td>
                  <td className="greenGoneWildClr_text">{item.phone_number}</td>
                  <td>{item.social_media_link}</td>
                  <td>
                    <Link to={item?.resume} target="_blank">
                      Resume
                    </Link>
                  </td>
                  <td>{item?.role}</td>
                  <td>
                    <div className="displayFlex alignItem_center gap12">
                      {/* <div>
                        <button className="actionBtn">
                          <img
                            className="verifiedBlackFilter"
                            src={ViewIcon}
                            alt="ViewIcon"
                          />
                        </button>
                      </div> */}
                      <div>
                        <button
                          className="actionBtn"
                          onClick={() => {
                            setId(item?.id);
                            setOpenDeleteModal(true);
                          }}
                        >
                          <img
                            className="bloodDonorFilter"
                            src={DeleteIcon}
                            alt="DeleteIcon"
                          />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <DeleteModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        handleDelete={handleDelete}
        id={id}
        name={"Business Query"}
      />
    </div>
  );
};

export default BusinessQuery;
