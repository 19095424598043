import React, { useEffect } from "react";
import { Modal } from "flowbite-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import senderRequest from "../../service/Http";
import toast from "react-hot-toast";

const QueryCallSchedule = ({ openModal, setOpenModal, email, ScheduleId, getAllQuery, schedule }) => {
  const navigate = useNavigate();


  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [link, setLink] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (schedule) {
      setDate(schedule.date)
      setTime(schedule.time)
      setLink(schedule.link)
    }
  }, [schedule])

  console.log(date, "date");

  const handleCallSchedule = async (e) => {
    e.preventDefault();
    try {
      if (!date || !time || !link) {
        return toast.error("Please Fill all the fields");
      }

      setLoading(true);
      setDisabled(true);
      const body = {
        query_id: ScheduleId,
        email: email,
        date: date,
        time: time,
        link: link,
        password: password.length > 0 ? password : null,
        content: "",
      };
      const response = await senderRequest(
        "post",
        "api/admin/schedule",
        "",
        "",
        body
      );
      if (response.data.statusCode === 200) {
        setOpenModal(false);
        setDate("");
        setLink("");
        setTime("");
        getAllQuery()
        toast.success("Call scheduled successfully")
        setLoading(false);
        setDisabled(false);
      } else {
        setDate("");
        setLink("");
        setTime("");
        toast.error(response.data.message)
        setOpenModal(false);
        setLoading(false);
        setDisabled(false);
      }
    } catch (error) {
      setDate("");
      setLink("");
      setTime("");
      setOpenModal(false);
      toast.error(error.message);
      setLoading(false);
      setDisabled(false);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  return (
    <Modal
      show={openModal}
      size={"xl"}
      onClose={() => {
        setOpenModal(false);
      }}
    >
      <Modal.Header className="p-4">Call Schedule</Modal.Header>
      <Modal.Body>
        <form>
          <div className="row">
            <>
              <div className="InputFrame mb_24">
                <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                  Email
                  <span className="blooddonorClr_text fontSize16 ml_4">* </span>
                </label>
                <input
                  type="text"
                  placeholder="feedback"
                  value={email}
                  name="Experience"
                />
              </div>
            </>
            <>
              <div className="InputFrame mb_24">
                <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                  Password <span className="" style={{fontSize: "12px" }}>
                    (*if not means give none)
                  </span>
                  <span className="blooddonorClr_text fontSize16 ml_4">* </span>
                </label>
                <input
                  type="text"
                  placeholder="Meet link password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name="Experience"
                />
              </div>
            
            </>
            <>
              <div className="InputFrame mb_24">
                <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                  Date
                  <span className="blooddonorClr_text fontSize16 ml_4">* </span>
                </label>
                <input
                  aria-label="Date"
                  type="date"
                  value={date}
                  min={getCurrentDate()}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
            </>
            <>
              <div className="InputFrame mb_24">
                <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                  Time
                  <span className="blooddonorClr_text fontSize16 ml_4">* </span>
                </label>
                <input
                  aria-label="Time"
                  type="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                />
              </div>
            </>
            <>
              <div className="InputFrame mb_24">
                <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                  Meet Link
                  <span className="blooddonorClr_text fontSize16 ml_4">* </span>
                </label>
                <input
                  type="text"
                  placeholder="feedback"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  name="Experience"
                />
              </div>
            </>
          </div>

          <button
            className="MainButton greenGoneWild_btnclr mt-5"
            onClick={handleCallSchedule}
            disabled={disabled}
          >
            {loading ? (
              <>
                <svg
                  className="animate-spin h-5 w-5 mr-2 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                  ></path>
                </svg>
                Scheduling...
              </>
            ) : (
              'Call Schedule'
            )}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default QueryCallSchedule;
