import React from 'react'

const ServicesAdd = () => {
  return (
    <div className='whiteFrame'>

      <h5 className='fontWeight600 fontSize20 mb_24 blackClr_text'>Add Career</h5>

      <form>
        <div className='row'>
          <div className='col-md-6'>
            <div className='InputFrame mb_24'>
              <label className='roycroftPewterClr_text display_block fontWeight600 fontSize14'>
                Roles
                <span className='blooddonorClr_text fontSize16 ml_4'>
                  *{' '}
                </span>
              </label>
              <input
                type='text'
                placeholder='Enter Roles'
                name='Roles'
              />
            </div>
          </div>
          <div className='col-md-6'>

            <div className='InputFrame mb_24'>
              <label className='roycroftPewterClr_text display_block fontWeight600 fontSize14'>
                Total Year of  Experience
                <span className='blooddonorClr_text fontSize16 ml_4'>
                  *{' '}
                </span>
              </label>
              <input
                type='text'
                placeholder='Enter Total Year of  Experience'
                name='Experience'
              />
            </div>
          </div>

          <div className='col-md-6'>

            <div className='InputFrame mb_24'>
              <label className='roycroftPewterClr_text display_block fontWeight600 fontSize14'>
                Role Description
                <span className='blooddonorClr_text fontSize16 ml_4'>
                  *{' '}
                </span>
              </label>
              <input
                type='text'
                placeholder='Enter Role Description'
                name='desc'
              />
            </div>
          </div>

          <div className='col-md-6'>

            <div className='InputFrame mb_24'>
              <label className='roycroftPewterClr_text display_block fontWeight600 fontSize14'>
                Status
                <span className='blooddonorClr_text fontSize16 ml_4'>
                  *{' '}
                </span>
              </label>
              <select>
                <option>Opened</option>
                <option>Closed</option>
              </select>
            </div>
          </div>

          <div className='col-md-6'>

            <div className='InputFrame mb_24'>
              <label className='roycroftPewterClr_text display_block fontWeight600 fontSize14'>
                Location
                <span className='blooddonorClr_text fontSize16 ml_4'>
                  *{' '}
                </span>
              </label>
              <input
                type='text'
                placeholder='Enter the Location'
                name='Location'
              />
            </div>
          </div>
        </div>

        <button className='MainButton greenGoneWild_btnclr'>Save</button>
      </form>
    </div>
  )
}

export default ServicesAdd
