import React, { useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import senderRequest from "../../../service/Http";
import toast from "react-hot-toast";
import { handleInputValidation } from "../../../helper/validator";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import ImageUploading from "react-images-uploading";
import Upload from "../../../components/Upload";
import axios from "axios";

const EditTestimonialsModal = ({
  openModal,
  setOpenModal,
  handleTestimonials,
  id,
}) => {
  const [name, setName] = useState("");
  const [feedback, setFeedback] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [designation, setDesignation] = useState("");
  const [Tags, setTags] = useState("");
  const [error, setError] = useState({});
  //   const [image, setImage] = useState("");
  const [modalPlacement, setModalPlacement] = useState("center");

  useEffect(() => {
    if (id) {
      handleGetTestimonial();
    }
  }, [id]);
  // const [images, setImages] = useState([]);

  const navigate = useNavigate();

  const handleGetTestimonial = async (e, image_id) => {
    // if (!name || !feedback || !company_name || !designation) {
    //   toast.error("All fields are required");
    //   return;
    // }

    try {
      //   let body = {
      //     name: name,
      //     feedback: feedback,
      //     company_name: company_name,
      //     designation: designation,
      //   };
      const response = await senderRequest(
        "get",
        `api/testimonial/get/${id}`,
        "",
        ""
      );
      if (response.data.statusCode === 200) {
        // navigate("/admin/testimonials");
        setFeedback(response.data.data[0].feedback);
        // setName(response.data.data[0].name);
        setCompany_name(response.data.data[0].company_name);
        // setDesignation(response.data.data[0].designation);
      }
    } catch (error) {
      setFeedback("");
      setName("");
      setCompany_name("");
      setDesignation("");
      setOpenModal(false);
    }
  };

  const handleEditTestimonial = async (e, image_id) => {
    e.preventDefault();
    if ( !feedback || !company_name ) {
      toast.error("All fields are required");
      return;
    }

    try {
      let body = {
        testimonial_id: id,
        name: name,
        feedback: feedback,
        company_name: company_name,
        designation: designation,
      };

      const response = await senderRequest(
        "put",
        `api/testimonial/update`,
        "",
        "",
        body
      );
      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
        // navigate("/admin/testimonials");
        handleTestimonials();
        setFeedback("");
        setName("");
        setCompany_name("");
        setDesignation("");
        setOpenModal(false);
      }
    } catch (error) {
      toast.error(error.message);
      setFeedback("");
      setName("");
      setCompany_name("");
      setDesignation("");
      setOpenModal(false);
    }
  };
  return (
    <Modal
      show={openModal}
      size={"xl"}
      onClose={() => {
        setOpenModal(false);
        setFeedback("");
        setName("");
        setCompany_name("");
        setDesignation("");
      }}
    >
      <Modal.Header className="p-4">Edit Testimonials</Modal.Header>
      <Modal.Body>
        <form>
          <div className="row">
            <>
              {/* <div className="InputFrame mb_24">
                <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                  Name
                  <span className="blooddonorClr_text fontSize16 ml_4">* </span>
                </label>
                <input
                  type="text"
                  placeholder=" Name"
                  value={name}
                  onChange={(e) =>
                    handleInputValidation(e.target.value, setName, 1)
                  }
                />
              </div> */}
            </>
            <>
              <div className="InputFrame mb_24">
                <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                  Feedback
                  <span className="blooddonorClr_text fontSize16 ml_4">* </span>
                </label>
                <input
                  type="text"
                  placeholder="feedback"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  name="Experience"
                />
              </div>
            </>
            <>
              <div className="InputFrame mb_24">
                <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                  Company Name
                  <span className="blooddonorClr_text fontSize16 ml_4">* </span>
                </label>
                <input
                  type="text"
                  placeholder="company Name"
                  value={company_name}
                  onChange={(e) => setCompany_name(e.target.value)}
                  name="Experience"
                />
              </div>
            </>
            <>
              {/* <div className="InputFrame mb_24">
                <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                  Designation
                  <span className="blooddonorClr_text fontSize16 ml_4">* </span>
                </label>
                <input
                  type="text"
                  placeholder="Designation"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  name="Experience"
                />
              </div> */}
            </>
          </div>

          <button
            className="MainButton greenGoneWild_btnclr mt-5"
            onClick={(e) => handleEditTestimonial(e)}
          >
            Save
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditTestimonialsModal;
