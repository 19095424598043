import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BlogOneimg from "../../../assets/images/blogOne_img.png";
import EditIcon from "../../../assets/images/edit_icon.svg";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import AddIcon from "../../../assets/images/add_icon.svg";
import ViewIcon from "../../../assets/images/view_icon.svg";

const ServicesList = () => {

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const route = pathname?.split('/')[1]




  return (
    <>
      <div className='service_smoother  displayFlex alignItem_center justifyContent_spacebetween mb_24'>
        <div>
          <h2 className='fontSize24 fontWeight600 blackClr_text'>Services</h2>
        </div>
        <div>
          <button className='MainButton greenGoneWild_btnclr' onClick={() => navigate('./add-service')}>
            <img src={AddIcon} alt='AddIcon' />
            <span>Add Services</span>
          </button>
        </div>
      </div>
      <div className="Table_Wrapper tableResponsive">
        <table className="mb_24">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Image(s)</th>
              <th>Title</th>
              <th>Location</th>
              <th>Description</th>
              <th>Action</th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td> 1</td>
              <td className='adminblog_frame'>
                <img src={BlogOneimg} alt='BlogOneimg' />

              </td>
              <td>CHENNAI HOME ARCHITECTURE</td>
              <td>Sample Location</td>
              <td>Lorem Ipsum is simply dummy text ....</td>
              <td>

                <div className='displayFlex alignItem_center gap12'>
                  <div>
                    <button className='actionBtn'>
                      <img className='verifiedBlackFilter' src={ViewIcon} alt='ViewIcon' />
                    </button>
                  </div>
                  <div>
                    <button className='actionBtn'>
                      <img className='greenGoneWildFilter' src={EditIcon} alt='EditIcon' />
                    </button>
                  </div>
                  <div>
                    <button className='actionBtn'>
                      <img className='bloodDonorFilter' src={DeleteIcon} alt='DeleteIcon' />
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td> 1</td>
              <td className='adminblog_frame'>
                <img src={BlogOneimg} alt='BlogOneimg' />
              </td>
              <td>CHENNAI HOME ARCHITECTURE</td>
              <td>Sample Location</td>
              <td>Lorem Ipsum is simply dummy text ....</td>
              <td>
                <div className='displayFlex alignItem_center gap12'>
                  <div>
                    <button className='actionBtn'>
                      <img className='verifiedBlackFilter' src={ViewIcon} alt='ViewIcon' />
                    </button>
                  </div>
                  <div>
                    <button className='actionBtn'>
                      <img className='greenGoneWildFilter' src={EditIcon} alt='EditIcon' />
                    </button>
                  </div>
                  <div>
                    <button className='actionBtn'>
                      <img className='bloodDonorFilter' src={DeleteIcon} alt='DeleteIcon' />
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ServicesList
