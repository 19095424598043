import React, { useRef, useEffect, useState, forwardRef } from "react";
import PerfecientbigIcon from "../../../assets/images/Perfecient_bigIcon.png";
import RightArrowIcon from "../../../assets/images/rightArrow.svg";

import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

import senderRequest from "../../../service/Http";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
import { Link, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import components from "../../../components/Skeleton";
const { ResponsiveSkeleton, ResponsiveSkeleton_Tag } = components;

const ProjectDetail = () => {
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
  const params = useParams();
  useEffect(() => {
    handleProjects();
    handleLatestProjects();
    window.scrollTo(0, 0);
  }, [params]);

  const [latestProject, setLatestProject] = useState([]);
  const onEdge = (direction) => {
    console.log(`Edge reached, direction: ${direction}`);
    // Perform any action you want when the edge is reached
  };


  // function SampleNextArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div className="sliderNextPosition">
  //       <button className="emptyBtn" onClick={onClick}>
  //         <img src={RightArrowIcon} alt="RightArrowIcon" />
  //       </button>
  //     </div>
  //   );
  // }

  // function SamplePrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div className="sliderPrevPosition">
  //       <button className="emptyBtn" onClick={onClick}>
  //         <img className="transformRotateMin180deg" src={RightArrowIcon} alt="RightArrowIcon" />
  //       </button>
  //     </div>
  //   );
  // }



  const [isPlaying, setIsPlaying] = useState(false);
  const [dummyImage, setDummyImage] = useState([{ 'item': 1 }, { 'item': 2 }, { 'item': 3 }, { item: 4 }, { item: 5 }]);
  const [autoplayed, setAutoplayed] = useState(false);
  const videoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      // Check if video should autoplay
      if (video.autoplay) {
        setIsPlaying(true); // If autoplay, set isPlaying to true
        setAutoplayed(true); // Set autoplayed to true
        video.addEventListener("pause", () => setIsPlaying(false)); // Listen for pause event
      }
      // Listen for play event to update isPlaying state
      video.addEventListener("play", () => setIsPlaying(true));
      // Listen for pause event to update isPlaying state
      video.addEventListener("pause", () => setIsPlaying(false));
    }
    // Cleanup event listeners
    return () => {
      if (video) {
        video.removeEventListener("play", () => setIsPlaying(true));
        video.removeEventListener("pause", () => setIsPlaying(false));
      }
    };
  }, []);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (videoRef.current.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const [projects, setProjects] = useState([]);
  const handleProjects = async () => {
    setProjects([]);
    try {
      const response = await senderRequest(
        "get",
        `api/project/get/${params?.id}`,
        "",
        "",
        {}
      );
      if (response.data.statusCode === 200) {
        console.log(response?.data?.data, "response");
        setProjects(response?.data?.data);
        window.scrollTo(0, 0);
      } else {
        setProjects("");
      }
    } catch (error) {
      setProjects("");
    }
  };

  console.log(projects[0]?.image_url?.length, "projects");
  const ProjectSlider = {
    centerMode: projects[0]?.image_url?.length > 3 ? true : false,
    dots: false,
    arrows:true,
    // arrows:  projects[0]?.image_url?.length > 3 ? true : false,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    infinite: projects[0]?.image_url?.length > 3 ? true : false,
    autoplay: projects[0]?.image_url?.length > 3 ? true : false,
    autoplaySpeed: 3000,
    speed: 500,
    initialSlide: 1,
    slidesToShow: 3,
    slidesToScroll: 3,
    cssEase: "ease-in-out",
    draggable: true,
    swipeToSlide: true,
    // swipe: true,
    pauseOnHover: true,
    touchThreshold: 5,
    touchMove: projects[0]?.image_url?.length > 3 ? true : false,
    focusOnSelect: true,
    accessibility: true,
    onEdge: onEdge, // Attach the onEdge event
    lazyLoad: "progressive", // or 'ondemand'
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          centerMode: false,
          dots: false,
          touchMove: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          initialSlide: 1,
          slidesToScroll: 1,
          centerMode: false,
          touchMove: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
          slidesToScroll: 1,
          centerMode: false,
          touchMove: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
          slidesToScroll: 1,
          centerMode: false,
          infinite: true,
          touchMove:  true,
        },
      },
    ],
  };

  const handleLatestProjects = async () => {
    try {
      const response = await senderRequest(
        "get",
        `api/project/get-all`,

        "",
        "",
        {}
      );
      if (response.data.statusCode === 200) {
        console.log(response, "response");

        setLatestProject(
          response?.data?.data
            .filter((item) => item.project_id !== params?.id)
            .slice(0, 3)
        );
      } else {
        setProjects("");
      }
    } catch (error) {
      setProjects("");
    }
  };

  const triggerPoint = document.getElementById('image')
  const triggerPoint1 = document.getElementsByClassName('blogMain_img')

  // if(triggerPoint && triggerPoint1){

  // gsap.from(".blogMain_img", {
  //   scale: 0.1,
  //   transformOrigin: "top top",
  //   rotation: 0.1,
  //   scrollTrigger: {
  //     trigger: "#image",
  //     start: "top top",
  //     end: "120% end",
  //     pin: "#image",
  //     scrub: 1,
  //     markers: true
  //   }
  // });
  // const tl = gsap.timeline({
  //   ease: "power2.inOut",
  // });

  // tl.from("#image", {
  //   scale: 0.1,
  //   duration: 1,
  //   transformOrigin: "top top",
  // })

  // ScrollTrigger.create({
  //   trigger: "#image",    // Adjust trigger selector based on your HTML structure
  //   start: "top top",
  //   end: "+=190%",
  //   pin: true,
  //   pinSpacing: false,    // Optional: Adjust based on your layout needs
  //   scrub: 1,
  //       markers: true,
  //   animation: tl,        // Link ScrollTrigger to your timeline
  //   once: true,
  //   onToggle: self => {
  //     if (self.isActive) {
  //       console.log('ScrollTrigger activated');
  //     } else {
  //       console.log('ScrollTrigger deactivated');
  //     }
  //   }
  // });
  // }


  // ScrollTrigger.create({
  //   trigger: "#videoSection",    
  //   start: "top top",
  //   end: "+=120%",
  //   pin: true,
  //   pinSpacing: false,    
  //   scrub: 1,
  //   animation: tl,        
  //   once: true,
  // });


  return (
    <>
      <section className="projectDetailSec">
        <div className="container">
          <div
            data-aos="fade-right"
            data-aos-duration="2000"
            className="projectDetailHeader"
          >
            <div className="projectDetail_icon">
              {projects[0]?.icon_url ? <img src={projects[0]?.icon_url} alt="PerfecientbigIcon" />
                : <Skeleton className="skeleton_projectDetailClientLogo" />}
            </div>
            <div>
              <h1>{projects[0]?.client_name || <Skeleton width="200px" height="30px" className="skeleton_projectDetailClientName" />}</h1>
            </div>
          </div>

          <div className="verifiedBlackBottomborder mb_34"></div>

          <div className="pb_150">
            <div className="row">
              <div
                data-aos="fade-up"
                data-aos-duration="700"
                className="col-lg-3 mobSpace_mb24"
              >
                <p className="syne_Font fontSize26 fontWeight400 limeMistClr_text">
                  {projects[0]?.title || (<><Skeleton count={2} width="100%" /> <Skeleton count={1} width="50%" /></>)

                  }
                </p>
              </div>
              {/* {projects[0]?.location ? ( */}
              <div
                data-aos="fade-up"
                data-aos-duration="900"
                className="col-lg-3  offset-lg-1 mobSpace_mb24"
              >
                <h5 className="syne_Font fontSize24 fontWeight400 SteelWoolClr_text">
                  LOCATION
                </h5>
                <p className="syne_Font fontSize24 fontWeight400 drWhiteClr_text">
                  {projects[0]?.location || (<><Skeleton count={1} width="100%" /> <Skeleton count={1} width="50%" /></>)}
                </p>
              </div>
              {/* ) : (
                ""
              )} */}
              {/* {projects[0]?.client_name ? ( */}
              <div
                data-aos="fade-up"
                data-aos-duration="1100"
                className="col-lg-3 mobSpace_mb24"
              >
                <h5 className="syne_Font fontSize24 fontWeight400 SteelWoolClr_text">
                  CLIENT
                </h5>
                <p className="syne_Font fontSize24 fontWeight400 drWhiteClr_text">
                  {projects[0]?.client_name || (<><Skeleton count={1} width="100%" /> <Skeleton count={1} width="50%" /></>)}
                </p>
              </div>
              {/* ) : (
                ""
              )} */}
              {/* {projects[0]?.year_of_completion ? ( */}
              <div
                data-aos="fade-up"
                data-aos-duration="1200"
                className="col-lg-2"
              >
                <h5 className="syne_Font fontSize24 fontWeight400 SteelWoolClr_text">
                  COMPLETION
                </h5>
                <p className="syne_Font fontSize24 fontWeight400 drWhiteClr_text">
                  {projects[0]?.year_of_completion || (<><Skeleton count={1} width="100%" /> </>)}
                </p>
              </div>
              {/* ) : (
                ""
              )} */}
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            className="row justifyContent_end mobSpace_mb34"
          >
            <div className="col-lg-6 ">
              <p className="fontSize36  fontWeight400 atypDisplay_Font drWhiteClr_text textalign_right pr40">
                {projects[0]?.sub_title}
                {/* <span className="cerebralGreyClr_text">
                  text of the printing and typesetting industry.
                </span> */}
              </p>
            </div>
          </div>

          <div
            data-aos="fade-up"
            data-aos-duration="1700"
            className={` ${projects[0]?.image_url?.length > 3 ? "projectsList" : "projectsList projectLengthOpacity"} `}
          >
            <Slider {...ProjectSlider}>
              {
                projects[0]?.image_url ? projects[0]?.image_url?.map((item, index) => (
                  <div className="projectsListItem" key={index}>
                    <img src={item?.image_url} />
                  </div>
                )) :

                  dummyImage.length && dummyImage?.map((item, index) => (
                    <div className="projectsListItem" key={index}>
                      <><Skeleton className="projectsListItem_skeletonImage" /></></div>
                  ))}
            </Slider>
          </div>

          <div>
            {/* <p
              data-aos="fade-up"
              data-aos-duration="1800"
              className="SteelWoolClr_text fontSize24 fontWeight400 syne_Font mb_12"
            >
              NAME OF BUILDING:{" "}
              <span className="cerebralGreyClr_text">{}</span>
            </p> */}
            {projects.length ? (
              <p
                data-aos="fade-up"
                data-aos-duration="1800"
                className="SteelWoolClr_text fontSize24 fontWeight400 syne_Font mb_12"
              >
                AREA:{" "}
                <span className="cerebralGreyClr_text">
                  {projects[0]?.area}
                  {projects[0]?.area && ' sq.ft '}
                </span>
              </p>
            ) : (<><Skeleton width="50%" /><Skeleton count={2} width="100%" /></>)}
            {/* <p
              data-aos="fade-up"
              data-aos-duration="1900"
              className="SteelWoolClr_text fontSize24 fontWeight400 syne_Font mb_12"
            >
              DETAILS:{" "}
              <span className="cerebralGreyClr_text">Details description</span>
            </p>
            <p
              data-aos="fade-up"
              data-aos-duration="2000"
              className="SteelWoolClr_text fontSize24 fontWeight400 syne_Font mb_24"
            >
              DETAILS 2:{" "}
              <span className="cerebralGreyClr_text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </span>
            </p> */}
            {projects[0]?.description ?
              JSON?.parse(projects[0]['description'])?.map((item, index) => (
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  key={index}
                  className="cerebralGreyClr_text fontSize24 fontWeight400 syne_Font mb_16"
                >
                  {item['description']}
                </p>
              )) : ""}
          </div>

          <div className="videoSection">
            {projects.length ? projects[0]["youtube_link"] ? <VideoComp source={projects[0]["youtube_link"]} ref={videoRef} /> : "" : <Skeleton width="100%" className="skeleton_projectDetailVideo" />}
            {/* <div className="VideoIcon">
              {!isPlaying && !autoplayed && (
                <button className="emptyBtn" onClick={togglePlayPause}>
                  <img src={VideoIcon} alt="VideoIcon" />
                </button>
              )}
            </div> */}
          </div>
          {/* )} */}

          {/* <div className="teamcredits_sec">
            <h3 data-aos="fade-up" data-aos-duration="2500" className="mb_20">
              Team credits
            </h3>
            <div
              data-aos="fade-up"
              data-aos-duration="2600"
              className="teamcredits_scrollani"
            >
              <ul>
                <li>
                  <img
                    src={CerebralGreycircleIcon}
                    alt="CerebralGreycircleIcon"
                  />
                  <span className="drWhiteClr_text fontSize24 fontWeight400 syne_Font">
                    Jason smith{" "}
                    <span className="SteelWoolClr_text">(Chief Architect)</span>
                  </span>
                </li>
                <li>
                  <img
                    src={CerebralGreycircleIcon}
                    alt="CerebralGreycircleIcon"
                  />
                  <span className="drWhiteClr_text fontSize24 fontWeight400 syne_Font">
                    John smith{" "}
                    <span className="SteelWoolClr_text">(Project manager)</span>
                  </span>
                </li>
                <li>
                  <img
                    src={CerebralGreycircleIcon}
                    alt="CerebralGreycircleIcon"
                  />
                  <span className="drWhiteClr_text fontSize24 fontWeight400 syne_Font">
                    {" "}
                    George <span className="SteelWoolClr_text">(Designer)</span>
                  </span>
                </li>
                <li>
                  <img
                    src={CerebralGreycircleIcon}
                    alt="CerebralGreycircleIcon"
                  />
                  <span className="drWhiteClr_text fontSize24 fontWeight400 syne_Font">
                    Andreanne
                  </span>
                </li>
                <li>
                  <img
                    src={CerebralGreycircleIcon}
                    alt="CerebralGreycircleIcon"
                  />
                  <span className="drWhiteClr_text fontSize24 fontWeight400 syne_Font">
                    Jason smith{" "}
                    <span className="SteelWoolClr_text">(Chief Architect)</span>
                  </span>
                </li>
                <li>
                  <img
                    src={CerebralGreycircleIcon}
                    alt="CerebralGreycircleIcon"
                  />
                  <span className="drWhiteClr_text fontSize24 fontWeight400 syne_Font">
                    John smith{" "}
                    <span className="SteelWoolClr_text">(Project manager)</span>
                  </span>
                </li>
                <li>
                  <img
                    src={CerebralGreycircleIcon}
                    alt="CerebralGreycircleIcon"
                  />
                  <span className="drWhiteClr_text fontSize24 fontWeight400 syne_Font">
                    {" "}
                    George <span className="SteelWoolClr_text">(Designer)</span>
                  </span>
                </li>
                <li>
                  <img
                    src={CerebralGreycircleIcon}
                    alt="CerebralGreycircleIcon"
                  />
                  <span className="drWhiteClr_text fontSize24 fontWeight400 syne_Font">
                    Andreanne
                  </span>
                </li>
                <li>
                  <img
                    src={CerebralGreycircleIcon}
                    alt="CerebralGreycircleIcon"
                  />
                  <span className="drWhiteClr_text fontSize24 fontWeight400 syne_Font">
                    Jason smith{" "}
                    <span className="SteelWoolClr_text">(Chief Architect)</span>
                  </span>
                </li>
                <li>
                  <img
                    src={CerebralGreycircleIcon}
                    alt="CerebralGreycircleIcon"
                  />
                  <span className="drWhiteClr_text fontSize24 fontWeight400 syne_Font">
                    John smith{" "}
                    <span className="SteelWoolClr_text">(Project manager)</span>
                  </span>
                </li>
                <li>
                  <img
                    src={CerebralGreycircleIcon}
                    alt="CerebralGreycircleIcon"
                  />
                  <span className="drWhiteClr_text fontSize24 fontWeight400 syne_Font">
                    {" "}
                    George <span className="SteelWoolClr_text">(Designer)</span>
                  </span>
                </li>
                <li>
                  <img
                    src={CerebralGreycircleIcon}
                    alt="CerebralGreycircleIcon"
                  />
                  <span className="drWhiteClr_text fontSize24 fontWeight400 syne_Font">
                    Andreanne
                  </span>
                </li>
              </ul>
            </div>
          </div> */}

          <div className="otherproject_Sec">
            <div className="displayFlex alignItem_center justifyContent_spacebetween mb_40">
              <div>
                <h3
                  data-aos="fade-up"
                  data-aos-duration="2800"
                  className="fontSize32  fontWeight500 drWhiteClr_text syne_Font"
                >
                  Other projects
                </h3>
              </div>
              <div>
                <Link to={`/projects?category=all`}>
                  <button className="limeMistBorderbtn">View all</button>
                </Link>
              </div>
            </div>

            <div className="row">
              {latestProject.length ? latestProject?.map((item) => (
                <div
                  data-aos="fade-up"
                  data-aos-duration="2800"
                  className="col-lg-4 mobSpace_mb24"
                >
                  <Link to={`/projects/${item.project_id}`}>
                    <div className="OtherItem ">
                      <div className="OtherItemImage">
                        {item?.cover_image_url ? <img src={item?.cover_image_url} alt="BlogOneImg" /> :
                          <Skeleton className="otherImage_skeleton" />}
                      </div>
                      <div className="displayFlex alignItem_center justifyContent_spacebetween">
                        <div>
                          <h5 className="fontSize16 fontWeight400 drWhiteClr_text atypDisplay_Font">
                            {item?.client_name || <Skeleton width="50%" />}, {item?.location || <Skeleton width="50%" />}.
                          </h5>
                        </div>
                        <div>
                          <p className="fontSize14 fontWeight400 blankaGreenClr_text syne_Font textdecoration_underline">
                            {item?.category}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )) : (
                <div
                  data-aos="fade-up"
                  data-aos-duration="2800"
                  className="col-lg-4 mobSpace_mb24"
                >
                  <div className="OtherItem ">
                    <div className="OtherItemImage"> <Skeleton className="otherImage_skeleton" />
                    </div>
                    <div className="displayFlex alignItem_center justifyContent_spacebetween">
                      <div>
                        <h5 className="fontSize16 fontWeight400 drWhiteClr_text atypDisplay_Font">
                          <Skeleton width="100px" />
                          <Skeleton width="50px" />
                        </h5>
                      </div>
                      <div>
                        <p className="fontSize14 fontWeight400 blankaGreenClr_text syne_Font textdecoration_underline">
                          <Skeleton width="100px" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section
        data-aos="fade-up"
        data-aos-duration="3000"
        className="WantToWork_Sec"
      >
        <div className="circleAnimationLinerOne"></div>
        <div className="circleAnimationLinerTwo"></div>

        <div className="container-fluid position_relative">
          <div className="circleAnimationLinerThree"></div>
          <div className="circleAnimationLinerfour"></div>
          <h1>
            Let's Transform
            <br />
            your Space
          </h1>
          <div className="displayFlex justifyContent_center">
            <div className="zIndex999 position_relative">
              <button
                className="lightGreenEffectBtn"
                onClick={() => navigate(`/contact-us`)}
              >Connect Today</button>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="contactus_marquee">
        <div className="container-fluid">
          <div className="main-marq">
            <div className="slide-har st1">
              <div className="box">
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default ProjectDetail;

export const VideoComp = forwardRef(({ source }, ref) => {
  const embedUrl = source.replace('watch?v=', 'embed/');

  return (
    <iframe
      ref={ref}
      src={embedUrl}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="blogMain_img rounded-md"
      style={{ height: "800px", width: "100%" }}
      title="Video player"
    />
  );
});

