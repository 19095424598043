import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import Adminlogo from "../assets/images/admin_logo.png";
import UserIcon from "../assets/images/user_icon.svg";

const Header = () => {
  const navigate = useNavigate();
  // const pageTitle = useSelector((state) => state.global.headerTitle);

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <div className="navbarHeader">

        <div>

        {/* <h5 className="fontSize20 fontWeight600">{pageTitle}</h5> */}
        </div>
        <div>
          <Dropdown className="userProfile">
            <Dropdown.Toggle
              id="dropdown-basic"
              className="displayFlex alignItem_center gap12"
            >
              <div>
              <img src={UserIcon} alt="Adminlogo" />
              </div>
              <div className="userprofileText">Admin</div>
            </Dropdown.Toggle>
            <Dropdown.Menu>

              <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>

              {/* <Dropdown.Item href='#'>Another action</Dropdown.Item>
            <Dropdown.Item href='#'>Something else</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
    </div>
  );
};

export default Header;
