import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Slider from "react-slick";
import { Link, useLocation } from "react-router-dom";
// import TempBNR from "../../assets/images/main_bnr1.jpg";
// import TempBNR_two from "../../assets/images/main_bnr2.jpg";
// import TempBNR_three from "../../assets/images/main_bnr3.jpg";

import TempBNR from "../../assets/images/newBnr_one.png";
import TempBNR_two from "../../assets/images/newBnr_two.png";
import TempBNR_three from "../../assets/images/newBnr_three.png";

import singleBlog from "../../assets/images/client_logo/blog-carousel_img.png";

import BorderWhatsappicon from "../../assets/images/border_whatsapp_icon.svg";
import ArchitecturalDesignImg from "../../assets/images/architectural_Design_img.jpg";
import ArchitecturalDesignImg_2 from "../../assets/images/architecture_2.png";
import ArchitecturalDesignImg_3 from "../../assets/images/architecture_3.png";
import ArchitecturalDesignImg_4 from "../../assets/images/architecture_4.png";
import ArchitecturalDesignImg_5 from "../../assets/images/architecture_5.png";
import ArchitecturalDesignImg_6 from "../../assets/images/architecture_6.png";
import ArchitecturalDesignImg_7 from "../../assets/images/architecture_7.jpg";


import InteriorDesignImg from "../../assets/images/interior_design_img.jpg";
import interior_2 from "../../assets/images/interior_2.png";
import interior_3 from "../../assets/images/interior_3.png";
import interior_4 from "../../assets/images/interior_4.png";
import interior_5 from "../../assets/images/interior_5.jpg";


import landscapes_1 from "../../assets/images/landspaces_img.jpg";
import landscapes_2 from "../../assets/images/landscapes_4.png";
import landscapes_3 from "../../assets/images/landscapes_3.png";
import landscapes_4 from "../../assets/images/landscapes_2.png";

import project_manageimg1 from "../../assets/images/project_manage1.jpg";
import project_manageimg2 from "../../assets/images/project_manage2.png";

import RightArrowIcon from "../../assets/images/rightArrow.svg";
import verizonimg from "../../assets/images/verizon_final.jpg";
import Villaimg from "../../assets/images/eagle_burgman.jpg";
import TirupurNewbusImg from "../../assets/images/bustand_Final.jpg";
import regusimg from "../../assets/images/regusimg.jpg";
import Tempdb from "../../assets/images/temp_db.png";
import CommaIcon from "../../assets/images/mdi_comma.svg";
import MetaskapeswhiteLogo from "../../assets/images/metaskapelogo.svg";

import GlUpdateone from "../../assets/images/glUpdate_one.jpg";
import GlUpdatetwo from "../../assets/images/glUpdate_2.jpg";
import GlUpdatethree from "../../assets/images/glUpdate_3.jpg";
import GlUpdateFour from "../../assets/images/glUpdate_4.jpg";
import GlUpdateFive from "../../assets/images/glUpdate_5.jpg";
import GlUpdatesix from "../../assets/images/glUpdate_6.jpg";
import GlUpdateseven from "../../assets/images/glUpdate_7.jpg";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
import { useGSAP } from "@gsap/react";
import Lenis from "@studio-freight/lenis";
import SplitType from "split-type";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { useNavigate } from "react-router-dom";

import MotionPathPlugin from "gsap/MotionPathPlugin";
import MotionPathHelper from "gsap/MotionPathHelper";
import senderRequest from "../../service/Http";
import Testimonials from "../../components/Testimonials";
import Marquee from "react-fast-marquee";
import SplitText from "gsap/SplitText";
gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);
gsap.registerPlugin(MotionPathPlugin, MotionPathHelper);

const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [TempBNR, TempBNR_two, TempBNR_three];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );

      // gsap.set(".full_page_animation", { opacity: 1 })
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  const navigate = useNavigate();
  // const sliderRef = useRef(null);
  const currentPage = useLocation();

  const handleMenuClick = (path) => {
    navigate(path); // Navigate to the specified path
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
  }, []);




  useGSAP(() => {
    gsap.set(".loader-wrap-heading", { scale: 0.7 })
    // const smoother = ScrollSmoother.create({
    //   content: ".customerLayout",
    //   smooth: 2,
    //   normalizeScroll: true,
    //   ignoreMobileResize: true,
    // });
    const mm = gsap.matchMedia();



    const svg = document.getElementById("svg");
    if (svg) {
      const tl2 = gsap.timeline();
      const curve = "M0 502S175 272 500 272s500 230 500 230V0H0Z";
      const flat = "M0 2S175 1 500 1s500 1 500 1V0H0Z";

      // Set the body height to 100vh at the start of the animation
      const style = document.createElement('style');
      style.innerHTML = `
       body {
          height: 100vh !important;
        }
      `;
      document.head.appendChild(style);


      tl2.set(".brandLogo_preloader", { autoAlpha: 1 });
      tl2.set(".brandLogo_preloader img", { scale: 2.5 });
      tl2
        .from(
          ".brandLogo_preloader",
          {
            duration: 2.5,
            xPercent: -150,
            ease: "Power2.out",
            onComplete: () => {
              console.log("ANIMATION COMPLETED");
              blurAnimation();
              gsap.to(".loader_text", { opacity: 0, duration: 1.1 }, 2.5);
            },
          },
          0
        )
        .to(
          ".brandLogo_preloader",
          {
            duration: 2,
            y: -1500,
            skewY: 0.5,
            ease: "power2.easeIn",
          },
          3
        )
        .to(
          svg,
          {
            duration: 0.8,
            attr: { d: curve },
            ease: "power2.easeIn",
          },
          3
        )
        .to(
          svg,
          {
            duration: 0.8,
            attr: { d: flat },
            ease: "power2.easeOut",
            onComplete: () => {
              document.head.removeChild(style);

            },
          },
          3.5
        )
        .to(".loader-wrap", {
          y: -1500,

        })
        .to(".loader-wrap", {
          zIndex: -1,
          display: "none",

        });
    }


    const bnrMaincontent = document.querySelectorAll(".bnrMain-content");

    const blurAnimation = () => {
      const tl3 = gsap.timeline();
      tl3.from(".bnrMain-content", {
        // filter: "blur(10px)",
        duration: 3,
      }).from({}, {
        onComplete: () => {
          document.body.style.overflow = "visible";
        },
      }, 1.5)
    };

    const fullPageAnimation = document.querySelector(".full_page_animation");

    let marqueTimeLine1 = gsap.timeline({ paused: false });

    // let marque = document.querySelectorAll(".marquee");
    // marque.forEach((marquee_tags, index) => {
    //   marqueTimeLine1.to(
    //     marquee_tags,
    //     {
    //       x: -3000,
    //       duration: 25,
    //       repeat: -1,
    //       ease: "linear",
    //       yoyo: true,
    //       // immediateRender: true,
    //     },
    //     0
    //   )
    // })
    const opacity0 = () => {
      gsap.to(".full_page_animation", {
        opacity: 0,
        duration: 0.8,
      });
    };
    const opacity1 = () => {
      gsap.to(".full_page_animation", {
        opacity: 1,
        duration: 0.5,
      });
    };
    const childSplit = new SplitText(".p-1", {
      type: "lines",
      linesClass: "split-child",
      wordDelimiter: "*"
    });
    const parentSplit = new SplitText(".p-1", {
      type: "lines",
      linesClass: "split-parent",
      wordDelimiter: "*"
    });

    gsap.from(childSplit.lines, {
      scrollTrigger: {
        trigger: ".p-1",
        scrub: 1,
        start: "top 50%",
        end: "bottom -20%"
      },
      yPercent: 100,
      ease: "power4",
    });
    //RESTRICTING ANIMATION FOR MOBILE SCREEN
    mm.add("(min-width: 1026px)", () => {
      gsap.set(".whoWeAreItem", { scale: 0.7 });
      gsap.set(".loader-wrap-heading", { scale: 1 })
      const hpValues = gsap.utils.toArray(".whoWeAreItem");
      hpValues.forEach((hpValue, i) => {
        gsap.to(hpValue, {
          scrollTrigger: {
            trigger: ".whoWeare_sec",
            // start: "top 80%",
            scrub: 1,
            end: "+=5000"
          },
          motionPath: {
            path: ".elips",
            align: ".elips",
            alignOrigin: [0.5, 0.5],
            start: i * 0.2,
            end: i * 0.2 + 1,
          },
          opacity: 1,
          // repeat: -1,
          // duration: 20,
          ease: "none",
        });

        // hpValue.addEventListener("mouseenter", (e) => {
        //   console.log("ENETERED")
        //   gsap.to(hpValue, {
        //     background: "#dbffad",
        //     borderColor: "#dbffad",
        //     boxShadow: "0px 0px 30px rgba(174, 255, 72, 0.6)",
        //   })
        // });
      });

      const t2 = gsap.timeline();
      t2.to(".full_page_animation", {
        x: 500,
        y: -1500,
        // ease: "Power4.inOut",
        scrollTrigger: {
          trigger: ".full_page_animation",
          pin: true,
          invalidateOnRefresh: true,
          pinSpacing: true,
          // start: "bottom bottom",
          scrub: 1,
          end: "+=700",
          onprogress: () => {
            if (this.progress > 0.9) {
              opacity0();
            } else {
              opacity1();
            }
          },

          onEnterBack: () => {
            opacity1();
          },
          onRefresh: () => {
            t2.kill()
            t2.play()
          },
          onLeave: () => {
            opacity0();
          },
        },
        onComplete: () => {
          opacity0();
        },
        onReverseComplete: () => {
          opacity1();
        },
        onInterrupt: () => opacity1(),
        onComplete: () => {
          // refresh because height changes
          ScrollTrigger.refresh();
        },
        rotate: 180,
        scale: 40,
        // scaleY: 100000,
        // scaleX: 1000000,
        zIndex: -9999,
      });


    });

    const textH2 = document.querySelectorAll(".h2-1");
    if (textH2) {
      gsap.fromTo(
        ".h2-1",
        {
          x: -200,
          backgroundPositionX: "100%",
          scrollTrigger: {
            trigger: ".h2-2",
            scrub: 1,
            start: "top 60%",
            end: "bottom 40%",
          },
        },
        {
          backgroundPositionX: "0%",
          stagger: 1,
          x: 0,
          scrollTrigger: {
            trigger: ".h2-1",
            // markers: true,
            scrub: 1,
            start: "top 60%",
            end: "bottom 40%",
          },
        }
      );

      gsap.fromTo(
        ".h2-2",
        {
          x: 100,
          backgroundPositionX: "100%",
          scrollTrigger: {
            trigger: ".h2-2",
            scrub: 1,
            start: "top 80%",
            end: "bottom 40%",
          },
        },
        {
          backgroundPositionX: "0%",
          stagger: 1,
          x: 0,
          scrollTrigger: {
            trigger: ".h2-2",
            scrub: 1,
            start: "top 80%",
            end: "bottom 40%",
          },
        },
        {}
      );
    }

    const textH3 = document.querySelectorAll(".h2-3");
    if (textH3) {
      gsap.fromTo(
        ".h2-3",
        {
          x: -100,
          backgroundPositionX: "100%",
          scrollTrigger: {
            trigger: ".h2-3",
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        },
        {
          backgroundPositionX: "0%",
          stagger: 1,
          x: 0,
          scrollTrigger: {
            trigger: ".h2-3",
            // markers: true,
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        }
      );

      gsap.fromTo(
        ".h2-4",
        {
          x: 100,
          backgroundPositionX: "100%",
          scrollTrigger: {
            trigger: ".h2-3",
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        },
        {
          backgroundPositionX: "0%",
          stagger: 1,
          x: 0,
          scrollTrigger: {
            trigger: ".h2-3",
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        },
        {}
      );
    }
    const textH5 = document.querySelectorAll(".h2-5");
    if (textH5) {
      gsap.fromTo(
        ".h2-5",
        {
          x: -100,
          backgroundPositionX: "100%",
          scrollTrigger: {
            trigger: ".h2-5",
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        },
        {
          backgroundPositionX: "0%",
          stagger: 1,
          x: 0,
          scrollTrigger: {
            trigger: ".h2-5",
            // markers: true,
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        }
      );
    }
    const textH6 = document.querySelectorAll(".h2-6");
    if (textH6) {
      gsap.fromTo(
        ".h2-6",
        {
          x: -100,
          backgroundPositionX: "100%",
          scrollTrigger: {
            trigger: ".h2-6",
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        },
        {
          backgroundPositionX: "0%",
          stagger: 1,
          x: 0,
          scrollTrigger: {
            trigger: ".h2-6",
            // markers: true,
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        }
      );
    }
    const arr = ["+=100%", "+=100%", "+=100%", "top 0px"]; // Adjust accordingly
    const panels = gsap.utils.toArray(".homeProject_Item");

    const isMobile = window.innerWidth <= 768;
    if (!isMobile) {
      if (panels.length) {
        // Set the width of each panel to 100%
        gsap.set(panels, { width: "100%", minHeight: "100vh" });

        panels.forEach((panel, i) => {
          // Set up the scroll-triggered animation for each panel
          gsap.to(panel, {
            scrollTrigger: {
              trigger: panel,
              start: "top top",
              end: arr[i],
              pin: true,
              scrub: 1,
              invalidateOnRefresh: true,
              pinSpacing: false, // Comment out this line to test without pinSpacing
              // onToggle: (self) => {
              //   if (self.isActive) {
              //     ScrollTrigger.refresh(); // Comment out to avoid recursion
              //   }
              // },
              onRefresh: () => console.log('Refreshed!'),
              // markers: true,
            },
          });
        });

        // Optional: Listening for ScrollTrigger refresh events globally
        // ScrollTrigger.addEventListener('refresh', () => {
        //   console.log('ScrollTrigger refresh event!');
        // });
      }
    }

    const fadeUp = document.querySelectorAll(".text-3 h2");

    if (fadeUp) {
      gsap.set(".fadeUp", { opacity: 0, y: 24 });

      document.querySelectorAll(".fadeUp").forEach((box) => {
        ScrollTrigger.create({
          trigger: box,
          start: "top 90%",
          end: "bottom 20%",
          // markers: true,
          onEnter: () => gsap.to(box, { opacity: 1, y: 0, duration: 0.6 }),
          onLeave: () => gsap.to(box, { opacity: 0, y: 24, duration: 0.6 }),
          onEnterBack: () => gsap.to(box, { opacity: 1, y: 0, duration: 0.6 }),
          onLeaveBack: () => gsap.to(box, { opacity: 0, y: 24, duration: 0.6 }),
        });
      });
    }

    // let tween = gsap.to(".text h2", {
    //   motionPath: {
    //     path: ".elips",
    //     align: ".elips",
    //     alignOrigin: [0.5, 0.5],
    //   },
    //   duration: 4,
    //   yoyo: true,
    // });

    // MotionPathHelper.create(tween);

    // Scroll
    // const lenis = new Lenis({
    //   lerp: 0.6,
    // });

    // lenis.on("scroll", ScrollTrigger.update);
    // gsap.ticker.add((time) => {
    //   lenis.raf(time * 3000);
    // });
    const colorChange = document.querySelector(".colorChange");
    if (colorChange) {
      ScrollTrigger.create({
        trigger: ".DivSectionImage",
        start: "top center",
        end: "bottom center",
        onEnter: () => gsap.to(".colorChange", { color: "white" }),
        onLeave: () => gsap.to(".colorChange", { color: "#212529" }),
        onEnterBack: () => gsap.to(".colorChange", { color: "white" }),
        onLeaveBack: () => gsap.to(".colorChange", { color: "#212529" }),
      });
    }
  });

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="textalign_right testimonialArrow">
        <button className="limeMistBorderbtn" onClick={onClick}>
          <img src={RightArrowIcon} alt="RightArrowIcon" />
        </button>
      </div>
    );
  }

  const onEdge = (direction) => {
    console.log(`Edge reached, direction: ${direction}`);
    // Perform any action you want when the edge is reached
  };

  // const TestimonialSlider = {
  //   centerMode: true,
  //   infinite: false,
  //   // centerPadding: '0px',
  //   dots: false,
  //   arrows: true,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   speed: 500,
  //   initialSlide: 1,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   cssEase: "ease-in-out",
  //   draggable: true,
  //   swipeToSlide: true,
  //   prevArrow: false,
  //   // focusOnSelect: true,
  //   accessibility: true,
  //   nextArrow: <SampleNextArrow />,
  //   pauseOnHover: true,
  //   touchThreshold: 5,
  //   // swipe: true,
  //   touchMove: true,
  //   onEdge: onEdge, // Attach the onEdge event
  //   lazyLoad: "progressive", // or 'ondemand'
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: false,
  //         dots: false,
  //         centerMode: false,
  //       },
  //     },
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 2,
  //         initialSlide: 1,
  //         centerMode: false,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         initialSlide: 1,
  //         centerMode: false,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         initialSlide: 1,
  //         slidesToScroll: 1,
  //         centerMode: false,
  //         infinite: true,
  //       },
  //     },
  //   ],
  // };

  const BnrSliderContent = {
    dots: true,
    arrows: false,
    infinite: true,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "ease-in-out",
    draggable: true,
    swipeToSlide: true,
    // initialSlide:0,
    pauseOnHover: true,
    // swipe: true,
    touchMove: true,
    focusOnSelect: true,
    accessibility: true,
    onEdge: onEdge, // Attach the onEdge event
    lazyLoad: "progressive", // or 'ondemand'
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <>
      {/* <div className="fixed"> */}
      <div className="loader-wrap inset-0 flex justify-center items-center z-50 text-center tracking-widest" >
        <svg viewBox="0 0 1000 1000" fill="black" preserveAspectRatio="none">
          <path id="svg" d="M0,1005S175,995,500,995s500,5,500,5V0H0Z"></path>
        </svg>

        <div className="loader-wrap-heading">
          <div className="brandLogo_preloader mb_34">
            <img
              src={MetaskapeswhiteLogo}
              alt="MetaskapeswhiteLogo"
              className="img-fluid"
            />
          </div>
          {/* <div className="loader_text">We are <span></span> </div> */}
          <div className="loader_text">Innovate. <span>Ignite.</span> <span>Inspire.</span></div>
        </div>
      </div>
      {/* </div> */}

      <section className="Home_bnr">
        {/* <Carousel controls={false} interval={3000}>
          <Carousel.Item>
            <div className="bnr_Overlay">
              <img className="bnrImage" src={TempBNR} alt="TempBNR" />
            </div>
            <Carousel.Caption>
              <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="flexDirection_column displayFlex justifyContent_spacebetween maxheightLeftcontent mobileNone">
                    <div>
                      <h5 className="cerebralGreyClr_text fontSize24 fontWeight400 atypDisplay_Font">
                        OFFICE ADDRESS:
                      </h5>
                      <p className="SteelWoolClr_text fontSize20 fontWeight400 atypDisplay_Font">
                          Metaskapes Architects, 2nd Floor,<br /> No 5 First Main Road,
                          Kamaraj Nagar West,<br /> Thiruvanmiyur, Chennai 600041.
                      </p>
                    </div>
                    <div className="textDirection">
                      <Link
                        className="displayFlex alignItem_center gap12 cerebralGreyClr_text fontSize20 fontWeight400 atypDisplay_Font"
                        to="tel:+91-9884036011"
                      >
                        <img
                          src={BorderWhatsappicon}
                          alt="BorderWhatsappicon"
                        />
                        <span>+91-9884036011</span>
                      </Link>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div
                  className="col-lg-8  displayFlex justifyContent_end rightHeightBnr"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <div className="bnrMain-content">
                    <h1>DESIGN.</h1>
                    <h1>CREATE.</h1>
                    <h2>TRANSFORM.</h2>
                    <p>
                        We at Metaskapes Craft your Dream Space with a User-Centric approach that caters to all your Creative Requirements with our Dynamic Team of Architects and Designers.
                        We create Experiences, not just spaces…

                    </p>
                  </div>
                </div>
              </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>

            <div className="bnr_Overlay">
              <img className="bnrImage" src={TempBNR_two} alt="TempBNR_two" />
            </div>
            <Carousel.Caption>
              <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="flexDirection_column displayFlex justifyContent_spacebetween maxheightLeftcontent mobileNone">
                    <div>
                      <h5 className="cerebralGreyClr_text fontSize24 fontWeight400">
                        OFFICE ADDRESS:
                      </h5>
                      <p className="SteelWoolClr_text fontSize20 fontWeight400">
                          Metaskapes Architects, 2nd Floor,<br /> No 5 First Main Road,
                          Kamaraj Nagar West,<br /> Thiruvanmiyur, Chennai 600041.
                      </p>
                    </div>
                    <div className="textDirection">
                      <Link
                        className="displayFlex alignItem_center gap12 cerebralGreyClr_text fontSize20 fontWeight400 atypDisplay_Font"
                        to="tel:+91-9884036011"
                      >
                        <img
                          src={BorderWhatsappicon}
                          alt="BorderWhatsappicon"
                        />
                        <span>+91-9884036011</span>
                      </Link>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div
                  className="col-lg-8 rightHeightBnr"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <div className="bnrMain-content">
                    <h1>DETAIL.</h1>
                    <h1>DECORATE.</h1>
                    <h2>DELIVER.</h2>
                    <p>
                        Discover Metaskapes: Where Precision meets Performance. Our engineers ensure
                        Meticulous detail, Problem-solving, and Quality control for a seamless
                        project journey from Inception to Turnkey completion.
                    </p>
                  </div>
                </div>
              </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="bnr_Overlay">
              <img
                className="bnrImage"
                src={TempBNR_three}
                alt="TempBNR_three"
              />
            </div>
            <Carousel.Caption>
              <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="flexDirection_column displayFlex justifyContent_spacebetween maxheightLeftcontent mobileNone">
                    <div>
                      <h5 className="cerebralGreyClr_text fontSize24 fontWeight400">
                        OFFICE ADDRESS:
                      </h5>
                      <p className="SteelWoolClr_text fontSize20 fontWeight400">
                          Metaskapes Architects, 2nd Floor,<br /> No 5 First Main Road,
                        Kamaraj Nagar West,<br /> Thiruvanmiyur, Chennai 600041.
                      </p>
                    </div>
                    <div className="textDirection">
                      <Link
                        className="displayFlex alignItem_center gap12 cerebralGreyClr_text fontSize20 fontWeight400 atypDisplay_Font"
                        to="tel:+91-9884036011"
                      >
                        <img
                          src={BorderWhatsappicon}
                          alt="BorderWhatsappicon"
                        />
                        <span>+91-9884036011</span>
                      </Link>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div
                  className="col-lg-8 rightHeightBnr"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <div className="bnrMain-content">
                    <h1>SCOPE.</h1>
                    <h1>IDEATE.</h1>
                    <h2>EXPERIENCE.</h2>
                    <p>
                        At Metaskapes, we transcend your concepts into reality with a passion to amplify the spatial experience of your dream dome. We scale all your projects' aesthetics in Architecture, Interior Design, Urban, Landscape, and Project Management.
                    </p>
                  </div>
                </div>
              </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel> */}
        <div className="sliderWrapper">
          <div className="sliderImage">
            <img src={TempBNR} alt="TempBNR" />
          </div>
          <div className="sliderImage">
            <img src={TempBNR_two} alt="TempBNR_two" />
          </div>
          <div className="sliderImage">
            <img src={TempBNR_three} alt="TempBNR_three" />
          </div>
        </div>

        {/* <div className="bnr_Overlay"
          style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
        >
        </div> */}
        <div className="carousel_caption">
          <div className="container">
            <div className="row">
              <div className="col-lg-1">
                <div className="flexDirection_column displayFlex justifyContent_spacebetween maxheightLeftcontent mobileNone">
                  <div>
                    {/* <h5 className="cerebralGreyClr_text fontSize24 fontWeight400 atypDisplay_Font">
                      OFFICE ADDRESS:
                    </h5>
                    <p className="SteelWoolClr_text fontSize20 fontWeight400 atypDisplay_Font">
                      Metaskapes Architects, 2nd Floor,<br /> No 5 First Main Road,
                      Kamaraj Nagar West,<br /> Thiruvanmiyur, Chennai 600041.
                    </p> */}
                  </div>
                  <div className="textDirection">
                    <Link
                      className="displayFlex alignItem_center gap12 whiteClr_text fontSize20 fontWeight400 atypDisplay_Font"
                      to="tel:+919043099772"
                    >
                      <img src={BorderWhatsappicon} alt="BorderWhatsappicon" />

                      <span>+91-9043099772</span>
                    </Link>
                  </div>
                  <div></div>
                </div>
              </div>
              <div
                className="col-lg-11  displayFlex justifyContent_end rightHeightBnr"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <Slider {...BnrSliderContent}>
                  <div>
                    <div className="bnrMain-content ">
                      <h1 className="custom">DESIGN.</h1>
                      <h1>CREATE.</h1>
                      <h2>TRANSFORM.</h2>
                      <p>
                        We at Metaskapes Craft your Dream Space with a
                        User-Centric approach that caters to all{" "}
                        <br className="MobBr_hide" />
                        your Creative Requirements with our Dynamic Team of
                        Architects and Designers.
                        <br className="MobBr_hide" />
                        We create Experiences, not just spaces…
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="bnrMain-content">
                      <h1>DETAIL.</h1>
                      <h1>DECORATE.</h1>
                      <h2>DELIVER.</h2>
                      <p>
                        Discover Metaskapes: Where Precision meets Performance.
                        Our Team ensure
                        <br className="MobBr_hide" />
                        Meticulous detail, Problem-solving, and Quality control
                        for a seamless
                        <br className="MobBr_hide" />
                        project journey from Inception to Turnkey completion.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="bnrMain-content">
                      <h1>SCOPE.</h1>
                      <h1>IDEATE.</h1>
                      <h2>EXPERIENCE.</h2>
                      <p>
                        At Metaskapes, we transcend your ideas into reality with
                        a passion to amplify the
                        <br className="MobBr_hide" />
                        spatial experience of your dream dome; scaling all your
                        projects' aesthetics in
                        <br className="MobBr_hide" />
                        Architecture, Interior Design, Landscape, and Project
                        Management.
                      </p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whoWeare_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="mb_34 sectionTitle text">
                <h2 className="atypDisplay_Font   h2-1"> Witness Our </h2>
                <h2 className="atypDisplay_Font mb_40  h2-2"> Legacy </h2>
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="cerebralGreyClr_text fontSize24 fontWeight400 syne_Font fadeUp"
                >
                  At Metaskapes, we blend aesthetics with function to create
                  sustainable solutions. Using avant-garde materials and custom
                  furnishings, we transform visions into realities from concept
                  to completion.
                </p>
              </div>
              <div data-aos="fade-up" data-aos-duration="1200">
                <button
                  className="limeMistBorderbtn"
                  onClick={() => navigate(`/about-us`)}
                >
                  Know More
                </button>
              </div>
              <div className="full_page_animation whoWeareFontsizeIncrease">
                <h4> 15+</h4>
                <p>Years of experience</p>
              </div>
            </div>
            <svg
              className="circle_svg"
              width="1474"
              height="576"
              viewBox="0 0 1474 576"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="elips"
                // d="M1227.073,294.437 C1214.054,502.88 1069.261,572.593 928.598,581.626 837.322,580.499 618.989,540.148 616.417,320.564 614.4,148.395 826.176,56.374 924.947,66.495 1214.154,96.129 1225.955,294.449 1225.955,294.449"
                d="M1227.073,294.437 C1214.054,502.88 1069.261,572.593 928.598,581.626 837.322,580.499 618.989,540.148 616.417,320.564 614.4,148.395 826.176,56.374 924.947,66.495 1214.154,96.129 1227.063,293.34 1227.063,293.34 "
                stroke="black"
              ></path>
            </svg>
            <div className="col-lg-8">
              <div>
                <div className="whoWeAreItem">
                  <h3>5+ </h3>
                  <p>Services</p>
                </div>

                <div className="whoWeAreItem">
                  <h3>105+</h3>
                  <p>
                    Projects <br />
                    Handled
                  </p>
                </div>

                <div className="whoWeAreItem">
                  <h3>52+</h3>
                  <p>
                    Corporate
                    <br />
                    Interiors
                  </p>
                </div>

                <div className="whoWeAreItem">
                  <h3>55+</h3>
                  <p>
                    Happy <br />
                    Clients
                  </p>
                </div>

                <div className="whoWeAreItem ">
                  <h3>4+</h3>
                  <p>
                    Smart City
                    <br />
                    Projects
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whatWedo_sec">
        <div className="container">
          <div className="row alignItem_end pb_80">
            <div className="col-lg-9 text">
              <div className="text-2 sectionTitle mobSpace_mb24">
                <h2 className="atypDisplay_Font  h2-3">Where We</h2>
                <h2 className="atypDisplay_Font mb_40 h2-4">Shine</h2>
                <div
                  className="fadeUp"
                  data-aos="fade-up"
                  data-aos-duration="5000"
                  data-aos-delay="500"
                >
                  <p className="cerebralGreyClr_text fontSize34 fontWeight400 syne_Font fadeUp">
                    Crafting innovative commercial spaces that inspire
                    productivity and resonate with your brand's ethos.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row alignItem_end "> */}
          {/* <div
              
              className="col-lg-10"
            > */}
          <div data-aos="fade-up" data-aos-duration="3000" data-aos-delay="500">
            <p className="cerebralGreyClr_text fontSize24 fontWeight500 syne_Font textalign_right fadeUp">
              Embark on a journey of timeless elegance and ergonomic mastery{" "}
              <br className="MobBr_hide" />
              as we enhance every space, turning it into a vibrant{" "}
              <br className="MobBr_hide" />
              symphony of style and functionality. <br className="MobBr_hide" />
            </p>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>

        <div className="VerticalStepsWrapper" id="design-section">
          <div className="VerticalSteps">
            <div className="VerticalStep">
              <div
                data-aos="fade-right"
                data-aos-duration="3000"
                className="DivSectionImage leftToRight"
              >
                <div className="wwd_carousel mb_24">
                  <Carousel controls={false} interval={5000} indicators={true}>
                    <Carousel.Item>
                      <div className="wwd_FirstImgSize">
                        <img
                          src={ArchitecturalDesignImg}
                          alt="ArchitecturalDesignImg"
                        />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wwd_FirstImgSize">
                        <img
                          src={ArchitecturalDesignImg_2}
                          alt="ArchitecturalDesignImg_2"
                        />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wwd_FirstImgSize">
                        <img
                          src={ArchitecturalDesignImg_3}
                          alt="ArchitecturalDesignImg_3"
                        />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wwd_FirstImgSize">
                        <img
                          src={ArchitecturalDesignImg_4}
                          alt="ArchitecturalDesignImg_4"
                        />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wwd_FirstImgSize">
                        <img
                          src={ArchitecturalDesignImg_5}
                          alt="ArchitecturalDesignImg_5"
                        />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wwd_FirstImgSize">
                        <img
                          src={ArchitecturalDesignImg_6}
                          alt="ArchitecturalDesignImg_6"
                        />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wwd_FirstImgSize">
                        <img
                          src={ArchitecturalDesignImg_7}
                          alt="ArchitecturalDesignImg_7"
                        />
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </div>

                <div>
                  <h4 className="atypDisplay_Font fontSize32 fontWeight400 drWhiteClr_text colorChange">
                    ARCHITECTURAL <br /> DESIGN
                  </h4>
                </div>
              </div>
            </div>
            <div className="centerStroke"></div>
            <div className="VerticalStep">
              <div data-aos="fade-left" data-aos-duration="3000" className="DivSectionImage rightToLeft">
                <div className="wwd_carousel mb_24 marginLeft_Auto">
                  <Carousel controls={false} interval={5000} indicators={true}>
                    <Carousel.Item>
                      <div className="wwd_FirstImgSize">
                        <img src={InteriorDesignImg} alt="InteriorDesignImg" />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wwd_FirstImgSize">
                        <img src={interior_2} alt="InteriorDesignImg_2" />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wwd_FirstImgSize">
                        <img src={interior_3} alt="InteriorDesignImg_3" />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wwd_FirstImgSize">
                        <img src={interior_4} alt="InteriorDesignImg_4" />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wwd_FirstImgSize">
                        <img src={interior_5} alt="InteriorDesignImg_5" />
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </div>
                <div >
                  <h4 className="atypDisplay_Font fontSize32 fontWeight400 drWhiteClr_text textalign_right colorChange">
                    INTERIOR <br /> DESIGN
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="VerticalSteps">
            <div className="VerticalStep">
              <div>
                <div
                  data-aos="fade-right"
                  data-aos-duration="3000"
                  className="pb_100 DivSectionImage leftToRight"
                >
                  <div className="wwd_carousel mb_24 ">
                    <Carousel
                      controls={false}
                      interval={5000}
                      indicators={true}
                    >
                      <Carousel.Item>
                        <div className="wwd_FirstImgSize">
                          <img src={landscapes_1} alt="landscapes_1" />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="wwd_FirstImgSize">
                          <img src={landscapes_2} alt="landscapes_2" />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="wwd_FirstImgSize">
                          <img src={landscapes_3} alt="landscapes_3" />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="wwd_FirstImgSize">
                          <img src={landscapes_4} alt="landscapes_4" />
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>

                  <div>
                    <h4 className="atypDisplay_Font fontSize32 fontWeight400 drWhiteClr_text colorChange">
                      LANDSCAPE <br /> DESIGN
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-10">
                    <div data-aos="fade-up" data-aos-duration="1800">
                      <p className="cerebralGreyClr_text fontSize24 fontWeight400 syne_Font mb_30">
                        Our designs encompass Corporate Offices, Residential
                        Interiors, Villas, Resorts, Landscapes, Apartments,
                        Public Urban squares and Facade Exteriors, all crafted
                        for serene elegance.
                      </p>
                    </div>
                    <div
                      data-aos="fade-right"
                      data-aos-duration="2000"
                      className="textalign_center"
                    >
                      <button
                        className="limeMistBorderbtn"
                        onClick={() => handleMenuClick("/services")}
                      >
                        Explore More
                        {/* <img src={RightArrowIcon} alt="RightArrowIcon" /> */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="centerStroke"></div>
            <div className="VerticalStep">
              <div data-aos="fade-left" data-aos-duration="3000" className="DivSectionImage rightToLeft">
                <div className="wwd_carousel mb_24 marginLeft_Auto">
                  <Carousel controls={false} interval={5000} indicators={true}>
                    <Carousel.Item>
                      <div className="wwd_FirstImgSize">
                        <img
                          src={project_manageimg1}
                          alt="project_manageimg1"
                        />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wwd_FirstImgSize">
                        <img
                          src={project_manageimg2}
                          alt="project_manageimg1"
                        />
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </div>
                <div>
                  <h4 className="atypDisplay_Font fontSize32 fontWeight400 drWhiteClr_text textalign_right colorChange">
                    PROJECT <br /> MANAGEMENT
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg_paragraph">
        <div className="container">
          {/* <div className="row"> */}
          <div
            className=""
          // data-aos="fade-up"
          // data-aos-duration="3000"
          // data-aos-delay="1000"
          // data-aos-easing="linear"
          >
            <p className="p-1">
              Each of our architectural* creations is a sanctuary of*
              tranquillity, thoughtfully* crafted to offer an* unparalleled living
              experience.
            </p>
          </div>
          {/* </div> */}
        </div>
      </section>

      <section className="projects_home">
        <div className="container">
          <div className="pb_80">
            <div className="sectionTitle text-3">
              <h2 className="atypDisplay_Font  mb_40 h2-5">PROJECTS</h2>
              <div
                className="fadeUp"
                data-aos="fade-up"
                data-aos-duration="3000"
                data-aos-delay="700"
              >
                <p className="cerebralGreyClr_text fontSize34 fontWeight400 syne_Font fadeUp">
                  Our creations speak volumes about the <br className="MobBr_hide" /> transformative power
                  of extraordinary design, <br className="MobBr_hide" /> leaving an indelible mark on every
                  space we touch.
                </p>
              </div>
            </div>
          </div>


          {/* <p
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out"
                className="cerebralGreyClr_text fontSize24 fontWeight400 syne_Font textalign_center fadeUp"
              >
                Our creations speak volumes about the <br className="MobBr_hide" /> transformative power of
                extraordinary design, <br className="MobBr_hide" /> leaving an indelible mark on every space
                we touch.
              </p> */}
        </div>

        <div className="homeProject_Wrapper">
          <div className="homeProject_Item">
            <img src={verizonimg} alt="verizonimg" />
            <div className=" container">
              <div className="homeProject_InnerContent displayFlex alignItem_center gap12">
                <div data-aos="fade-up" data-aos-duration="2000">
                  <h2>01</h2>
                </div>
                <div data-aos="fade-up" data-aos-duration="2000">
                  <h5>VERIZON</h5>
                  <p>Olympia Tech Park, Chennai</p>
                </div>
              </div>
            </div>
          </div>

          <div className="homeProject_Item">
            <img src={regusimg} alt="regusimg" />
            <div className="container">
              <div className="homeProject_InnerContent displayFlex alignItem_center gap12">
                <div data-aos="fade-up" data-aos-duration="2000">
                  <h2>02</h2>
                </div>
                <div data-aos="fade-up" data-aos-duration="2000">
                  <h5>REGUS</h5>
                  <p>RMZ Phase 2, Chennai</p>
                </div>
              </div>
            </div>
          </div>

          <div className="homeProject_Item">
            <img src={TirupurNewbusImg} alt="TirupurNewbusImg" />
            <div className="container">
              <div className="homeProject_InnerContent displayFlex alignItem_center gap12">
                <div data-aos="fade-up" data-aos-duration="2000">
                  <h2>03</h2>
                </div>
                <div data-aos="fade-up" data-aos-duration="2000">
                  <h5>TIRUPUR NEW BUS TERMINUS</h5>
                  <p>Tirupur</p>
                </div>
              </div>
            </div>
          </div>

          <div className="homeProject_Item">
            <img src={Villaimg} alt="Villaimg" />
            <div className="container">
              <div className="homeProject_InnerContent displayFlex alignItem_center gap12">
                <div data-aos="fade-up" data-aos-duration="2000">
                  <h2>04</h2>
                </div>
                <div data-aos="fade-up" data-aos-duration="2000">
                  <h5>EBM PAPST</h5>
                  <p>Tharamani, Chennai</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="displayFlex justifyContent_center pb_80">
          <div
            data-aos="fade-up"
            data-aos-duration="3000"
            data-aos-easing="ease-in-out"
          >
            <button
              className="lightGreenEffectBtn fadeUp"
              onClick={() => navigate(`/projects`)}
            >
              Explore Our Work
            </button>
          </div>
        </div>
      </section>

      <section className="testimonials_sec mt_60mobile">
        <div className="container">
          <div className="sectionTitle pb_100 text">
            <h2 className="atypDisplay_Font mb_34 h2-6">TESTIMONIALS</h2>
            <div
              className="fadeUp"
            >
              <p
                data-aos="fade-up"
                data-aos-duration="3000"
                className="cerebralGreyClr_text fontSize24 fontWeight400 syne_Font fadeUp">
                Explore the impact of our services <br className="MobBr_hide" /> through the voices of our
                clients.{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <Testimonials
            CommaIcon={CommaIcon}
            // TestimonialSlider={TestimonialSlider}
          />
        </div>
      </section>

      <section className="latestUpdate_sec">
        <div className="container-fluid height100per">
          <div className="position_relative height100per">
            <Marquee
              className="fullImageOnePage marquee"
              loop={111}
              speed={100}
              direction="right"
            >
              <div>
                <img src={singleBlog} alt="GlUpdateone" />

              </div>
              {/* <div className="latestUpdateImg_grid ">
                <div className="">
                  <img src={GlUpdatetwo} alt="GlUpdatetwo" />
                </div>
                <div>
                  {" "}
                  <img src={GlUpdatethree} alt="GlUpdatethree" />
                  <img src={GlUpdateFour} alt="GlUpdateFour" />
                </div> */}

              {/* <div className=''>
              <img src={GlUpdatetwo} alt="GlUpdatetwo" />
            </div> */}
              {/* <div className="">
                  <img src={GlUpdateFive} alt="GlUpdateFive" />
                </div>
                <div className="">
                  <img src={GlUpdatesix} alt="GlUpdatesix" />
                  <img src={GlUpdateseven} alt="GlUpdateseven" />
                </div>
                <div>
                  <img src={GlUpdateone} alt="GlUpdateone" />
                  <img src={GlUpdateseven} alt="GlUpdateseven" />
                </div>
              </div>
              <div className="latestUpdateImg_grid ">
                <div className="">
                  <img src={GlUpdateone} alt="GlUpdateone" />
                  <img src={GlUpdatetwo} alt="GlUpdatetwo" />
                </div>
                <div>
                  {" "}
                  <img src={GlUpdatethree} alt="GlUpdatethree" />
                  <img src={GlUpdateFour} alt="GlUpdateFour" />
                </div> */}

              {/* <div className=''>
              <img src={GlUpdatetwo} alt="GlUpdatetwo" />
            </div> */}
              {/* <div className="">
                  <img src={GlUpdateFive} alt="GlUpdateFive" />
                </div>
                <div className="">
                  <img src={GlUpdatesix} alt="GlUpdatesix" />
                  <img src={GlUpdateseven} alt="GlUpdateseven" />
                </div>
                <div>
                  <img src={GlUpdateone} alt="GlUpdateone" />
                  <img src={GlUpdateseven} alt="GlUpdateseven" />
                </div>
              </div> */}
            </Marquee>

            <div>
              <div className="latestUpdate_content">
                <div>
                  <h1 className="syne_Font textalign_center mb_40">
                    Get the latest <br /> updates and news.
                  </h1>
                </div>
                <div className="displayFlex justifyContent_center ">
                  <div>
                    <button
                      className="lightGreenEffectBtn"
                      onClick={() => navigate(`/thoughts`)}
                    >
                      Latest news
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-aos="fade-up"
        data-aos-duration="3000"
        data-aos-delay="900"
        className="WantToWork_Sec"
      >
        <div className="container-fluid position_relative">
          <h1>
            Let us Craft <br className="MobBr_hide" />
            your Space
          </h1>
          <div className="displayFlex justifyContent_center">
            <div className="zIndex999 position_relative">
              <button
                className="lightGreenEffectBtn width250px"
                onClick={() => {
                  navigate(`/contact-us`);
                  window.scrollTo(0, 0);
                }}
              >Connect
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="contactus_marquee">
        <div className="container-fluid">
          <div className="main-marq">
            <div className="slide-har st1">
              <div className="box">
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Home;
