// import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
// import { loginSchema } from "../../utils/validators/validators";
// import { Post } from "../../services/http-service";
import { useNavigate } from "react-router-dom";
import MetaskapeswhiteLogo from "../../../assets/images/metaskapelogo.svg";
import MetaskapesSecondary from "../../../assets/images/logoSecondary.svg";

import Eyehide from "../../../assets/images/eye_hide.svg";
import Eyeshow from "../../../assets/images/eye_show.svg";
import Slider from "react-slick";
import { handleInputValidation } from "./../../../helper/validator";
import senderRequest from "../../../service/Http";
import { loginStore } from "../../../storage/LoginStorage";
import { toast } from "react-hot-toast";

const Login = () => {
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);

  // const token = loginStore(state => state?.login?.token)

  // useEffect(()=>{
  //   if(token){
  //     navigate('/dashboard')
  //   }
  // },[])
  // const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
  //   useFormik({
  //     initialValues: {
  //       email: "",
  //       password: "",
  //     },
  //     validationSchema: loginSchema,
  //     onSubmit: async (values, { resetForm }) => {
  //       try {
  //         const resp = await Post("superadmin/login", values);
  //         if (resp?.statusCode === 200) {
  //           if (resp?.data?.auth_token)
  //             sessionStorage.setItem("auth_token", resp?.data.auth_token);
  //           sessionStorage.setItem("email", values.email);
  //           navigate("/dashboard");
  //           resetForm();
  //         } else if (resp?.response?.data?.statusCode === 400) {
  //           toast.error(resp?.response?.data?.message);
  //         }
  //       } catch (error) {
  //         console.log("error::: ", error);
  //       }
  //     },
  //   });

  const [currentSlide, setCurrentSlide] = useState(0);

  const loginSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 3000,
    initialSlide: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (slideIndex) => {
      setCurrentSlide(slideIndex);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const method = "post";
      const apiUrl = "api/admin/signin";
      // const token = ''
      const body = { email: email, password: password };
      const response = await senderRequest(method, apiUrl, "", "", body);
      if (response.data.statusCode === 200) {
        console.log(response, "response");
        loginStore({
          token: response?.data?.token,
          user: response?.data?.user,
        });
        navigate("/admin/thoughts");
        toast.success("login successfull");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="accountWrapper">
        <div className="container-fluid">
          <div className="row accountRow alignItem_center">
            <div className="pzero col-lg-8 position_relative">
              <Slider {...loginSlider}>
                <div className="loginFrame one">
                  <div className="loginFrame_caption">
                    <div className="bnr_header">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        style={{ cursor: "pointer" }}
                        href="#"
                      >
                        <img
                          src={MetaskapeswhiteLogo}
                          alt="MetaskapeswhiteLogo"
                        />
                      </a>
                    </div>

                   
                  </div>
                </div>
                <div className="loginFrame two">
                  <div className="loginFrame_caption">
                    <div className="bnr_header">
                      <img
                        src={MetaskapeswhiteLogo}
                        alt="MetaskapeswhiteLogo"
                      />
                    </div>

                  
                  </div>
                </div>
                <div className="loginFrame three">
                  {/* <div className="loginFrame_img">
                  <img src={BannerThree} alt="bannerThree" loading="lazy" />
                </div> */}
                  <div className="loginFrame_caption">
                    <div className="bnr_header">
                      <div>
                        <img
                          src={MetaskapeswhiteLogo}
                          alt="MetaskapeswhiteLogo"
                        />
                      </div>
                    </div>

                    
                  </div>
                </div>
              </Slider>
              <div className="slick_progressbar">
                <div
                  className="slick_progress"
                  // style={progressStyle}
                  style={{ width: `${((currentSlide + 1) / 3) * 100}%` }}
                />
              </div>
            </div>
            <div className="pzero col-lg-4">
              <div className="accountContent">
                <div className="logoAcc">
                  <img src={MetaskapesSecondary} alt="MetaskapesSecondary" />
                </div>
                <h2 className="fontSize24 satindeepBlackClr_text fontWeight500 textalign_center mb_32">
                  Let's get you signed in
                </h2>
                {/* <p className='textalign_center fontSize16 fontWeight400 roycroftPewterClr_text mb_32'>
                  Let’s get started to get a quote.
                </p> */}

                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="InputFrame mb_24">
                    <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                      Email{" "}
                      <span className="blooddonorClr_text fontSize16 ml_4">
                        *{" "}
                      </span>
                    </label>
                    <input
                      type="email"
                      placeholder="Example: email@gmail.com"
                      name="email"
                      value={email}
                      required
                      pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                      onChange={(e) =>
                        handleInputValidation(e.target.value, setEmail, 4)
                      }
                    />
                  </div>

                  <div className="InputFrame mb_24 displayFlex alignItem_center">
                    <div className="width100per">
                      <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                        Password
                        <span className="blooddonorClr_text fontSize16 ml_4">
                          *{" "}
                        </span>
                      </label>
                      <input
                        type={showPass ? "text" : "password"}
                        placeholder="Example: John Doe"
                        name="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="emptyBtn"
                        onClick={() => setShowPass(!showPass)}
                      >
                        {showPass ? (
                          <img src={Eyeshow} alt="Eyeshow" />
                        ) : (
                          <img src={Eyehide} alt="Eyehide" />
                        )}
                      </button>
                    </div>
                  </div>

                  {/* <div className="textalign_right">
                    <p
                      onClick={() => navigate("/forgot-password")}
                      className=" fontSize14 fontWeight500 mb_16 SteelWoolClr_text cursorPointer emptyBtn"
                    >
                      Forgot Password ?
                    </p>
                  </div> */}
                  <button
                    type="submit"
                    className="MainButton greenGoneWild_btnclr width100per justifyContent_center"
                  >
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
