import React, { useEffect, useRef, useState } from "react";
import senderRequest from "../service/Http";
import Slider from "react-slick";
import ProjectManagerIcon from "../assets/images/project_manager_icon.svg";
import RightArrowIcon from "../assets/images/rightArrow.svg";
const Testimonials = ({ CommaIcon }) => {
  const [testimonials, setTestimonials] = useState([]);
  const sliderRef = useRef(null);
  useEffect(() => {
    handleTestimonials();
  }, []);

  const onEdge = (direction) => {
    console.log(`Edge reached, direction: ${direction}`);
    // Perform any action you want when the edge is reached
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="textalign_right testimonialArrow">
        <button className="limeMistBorderbtn" onClick={onClick}>
          <img src={RightArrowIcon} alt="RightArrowIcon" />
        </button>
      </div>
    );
  }

  const TestimonialSlider = {
    centerMode: true,
    infinite: testimonials?.length > 3 ? true : false,
    // centerPadding: '0px',
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    initialSlide: 1,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "ease-in-out",
    draggable: true,
    swipeToSlide: testimonials?.length > 3 ? true : false,
    prevArrow: false,
    // focusOnSelect: true,
    accessibility: true,
    nextArrow: <SampleNextArrow />,
    pauseOnHover: true,
    touchThreshold: 5,
    // swipe: true,
    touchMove: testimonials?.length > 3 ? true : false,
    onEdge: onEdge, // Attach the onEdge event
    lazyLoad: "progressive", // or 'ondemand'
    responsive: [
      {
        breakpoint: 1024,
        settings: { 
          slidesToShow: 3,
          slidesToScroll: 1,
          // swipeToSlide: testimonials?.length > 3 ? true : false,
          // infinite: testimonials?.length > 3 ? true : false,
          dots: false,
          centerMode: true,
          // touchMove: testimonials?.length > 3 ? true : false,
        },
      },
      { 
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          initialSlide: 1,
           slidesToScroll: 1,
          centerMode: false,
          infinite:true,
          touchMove: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
          centerMode: false,
          infinite: true,
          touchMove: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
          slidesToScroll: 1,
          centerMode: false,
          infinite:true,
          touchMove:true,
        },
      },
    ],
  };

  const handleTestimonials = async () => {
    try {
      const res = await senderRequest("get", `api/testimonial/get-all`, "", "");
      if (res.data.statusCode === 200) {
        setTestimonials(res?.data?.data);
      } else {
        setTestimonials([]);
      }
    } catch (error) {
      console.log(error);
      setTestimonials([]);
    }
  };
  return testimonials.length ? (
    <div
      className="fadeUp"
      data-aos="fade-up"
      data-aos-duration="5000"
      data-aos-delay="500"
    >
      <Slider ref={sliderRef} {...TestimonialSlider}>
        {testimonials?.map((item, index) => (
          <div className="testimonial_Item" key={index}>
            <div>
              <div className="commaIcon mb_34">
                <img
                  className="ml_auto display_block"
                  src={CommaIcon}
                  alt="CommaIcon"
                />
              </div>
              <div className="mb_24">
                <p>{item?.feedback}</p>
              </div>
            </div>
            <div className="displayFlex alignItem_center gap16">
              <div className="testimonial_profile">
                <img src={ProjectManagerIcon} alt="Tempdb" />
              </div>
              <div>
                <h6 className="drWhiteClr_text fontSize18 fontWeight500 syne_Font">
                  {item?.company_name}
                </h6>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  ) : null;
};

export default Testimonials;
