import React, { useEffect, useState } from "react";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import AddIcon from "../../../assets/images/add_icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import senderRequest from "../../../service/Http";
import toast from "react-hot-toast";
import Upload from "../../../components/Upload";
const CareerEdit = () => {
  const id = useParams();
  // console.log(id, "productid");

  useEffect(() => {
    handleSingleCareer();
  }, [id]);
  const [role, setRole] = useState("");
  const [iconUrl, setIconUrl] = useState([]);

  const navigate = useNavigate();
  const [jobdesc, setJobDesc] = useState("");
  // const [responsibility, setResponsibility] = useState("");
  const [keySkills, setKeySkills] = useState("");
  const [status, setStatus] = useState("");

  const [newIconURL, setNewIconURL] = useState([]);

  const [editIcon, setEditIcon] = useState(false);

  const [inputFields, setInputFields] = useState([{ id: 1, Requirements: "" }]);
  const handleInputValidationAdd = (value, index) => {
    const newInputFields = [...inputFields];
    newInputFields[index].Requirements = value;
    setInputFields(newInputFields);
  };
  const handleNewInputAdd = () => {
    setInputFields([...inputFields, { id: Date.now(), Requirements: "" }]);
  };

  const handleInputRemove = (id) => {
    const newInputFields = inputFields.filter((field) => field.id !== id);
    setInputFields(newInputFields);
  };

  const handleSingleCareer = async () => {
    console.log("single");
    try {
      const response = await senderRequest(
        "get",
        `api/career/fetch-job/${id.id}`,
        "",
        ""
      );
      const careerData = response.data.data[0];

      // Update state with fetched data
      console.log(careerData, "careerData");
      setRole(careerData.roles);
      setJobDesc(careerData.description);
      setKeySkills(careerData.keywords);
      setIconUrl(careerData?.icon_url);
      setStatus(careerData.status ? "active" : "inactive");
      setInputFields(JSON.parse(careerData.responsibility));
    } catch (error) { }
  };

  const handlIconUpload = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("icon", newIconURL[0].file);

      const response = await senderRequest(
        "post-photo",
        "api/image/icon",
        "",
        "",
        formData
      );
      if (response.status === 200) {
        console.log("Image uploaded successfully");
        // setTimeout(() => {
        handleEdit(e, response.data.data);
        console.log("Image uploaded successfully");
        // }, 5000);
      }
    } catch (error) { }
  };

  const handleEdit = async (e, iconUrl) => {
    e.preventDefault();
    if (
      !role ||
      !jobdesc ||
      !keySkills ||
      !status ||
      inputFields.length === 0
    ) {
      toast.error("Please fill in all required fields.");

      return;
    }
    try {
      let body;
      if (iconUrl) {
        body = {
          career_id: id.id,
          roles: role,
          description: jobdesc,
          icon_url: iconUrl,
          // responsibility: responsibility,
          keywords: keySkills,
          status: status === "active" ? true : false,
          responsibility: JSON.stringify(inputFields),
        };
      } else {
        body = {
          career_id: id.id,
          roles: role,
          description: jobdesc,
          // responsibility: responsibility,
          keywords: keySkills,
          status: status === "active" ? true : false,
          responsibility: JSON.stringify(inputFields),
        };
      }

      const response = await senderRequest(
        "post",
        "api/career/edit",
        "",
        "",
        body
      );
      if (response.data.statusCode === 200) {
        navigate(-1);
        setRole("");
        setJobDesc("");
        setKeySkills("");
        setStatus("");
        setInputFields([]);
        toast.success(response.data.message);
      }
      console.log(body);
    } catch (err) {
      console.log(err);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = async (e) => {
    setIsLoading(true);
    try {
      if (editIcon) {
        await handlIconUpload(e);
      } else {
        await handleEdit(e);
      }
    } catch (error) {
      console.error("Error during upload or edit", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="whiteFrame">
      <h5 className="fontWeight600 fontSize20 mb_24 blackClr_text">
        Edit Career
      </h5>

      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Role
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="Enter Role"
                name="Role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Job Description
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="Enter Role Description"
                name="desc"
                value={jobdesc}
                onChange={(e) => setJobDesc(e.target.value)}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Status
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <select
                name="status"
                className="form-control"
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="" disabled>
                  Select Status
                </option>
                <option value="active">Active </option>
                <option value="inactive">InActive</option>
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Key Skills
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="Ex: #HTML, #CSS, #JavaScript"
                name="Location"
                value={keySkills}
                onChange={(e) => setKeySkills(e.target.value)}
              />
            </div>
          </div>
        </div>

        {inputFields?.map((inputField, index) => (
          <div className="flex gap-4 mb-3 justify-start items-center">
            <div className="col-11">
              <textarea
                id="message"
                rows="4"
                className="block p-2.5 w-full text-sm font-semibold text-gray-800 bg-formbg rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-0 focus:border-blue-500 focus:placeholder-[#5570f1]  focus:text-black focus:font-medium dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder={`Requirements ${index + 1}`}
                pattern="[A-Za-z0-9\p{L}]+"
                value={inputFields[index].Requirements}
                onChange={(e) =>
                  handleInputValidationAdd(e.target.value, index)
                }
              ></textarea>
            </div>
            <div>
              {inputField.id === 1 ? (
                <img
                  className="greenGoneWildFilter cursor-pointer"
                  src={AddIcon}
                  alt="EditIcon"
                  onClick={() => handleNewInputAdd()}
                />
              ) : (
                <img
                  className="greenGoneWildFilter cursor-pointer"
                  src={DeleteIcon}
                  alt="EditIcon"
                  onClick={() => handleInputRemove(inputField.id)}
                />
              )}
            </div>
          </div>
        ))}

        <div className="mt-2 mb-3">
          {editIcon ? (
            <Upload count={1} setImages={setNewIconURL} images={newIconURL} />
          ) : (
            <>
              <p className="text-lg ps-1 font-medium pb-3">Icon image</p>
              <img src={iconUrl} alt="icon" />
              <button
                className="MainButton p-3 bg-gray-500 text-white"
                onClick={() => setEditIcon(true)}
              >
                Edit
              </button>
            </>
          )}
        </div>

        <button
          className="MainButton greenGoneWild_btnclr"
          onClick={handleButtonClick}
          disabled={isLoading} // Disable the button when loading
        >
          {isLoading ? (
            <>
              <svg
                className="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                ></path>
              </svg>
              Uploading...
            </>
          ) : (
            "Save"
          )}
        </button>
      </form>
    </div>
  );
};

export default CareerEdit;
