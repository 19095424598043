import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../../UserLayout/Navbar";
import Footer from "../../UserLayout/Footer";
import { gsap } from "gsap";
import ScrollSmoother from "gsap/ScrollSmoother";

const Customer = () => {
  const location = useLocation();

  useEffect(() => {
    gsap.registerPlugin(ScrollSmoother);

    const isMobile = window.innerWidth <= 768;

    if (
      !isMobile &&
      !location.pathname.startsWith('/careers') &&
      !location.pathname.startsWith('/about-us') &&
      !location.pathname.startsWith('/project') &&
      !location.pathname.startsWith('/contact-us') &&
      !location.pathname.startsWith('/thoughts')
    ) {
      console.log("here", location.key);
      const scrollSmoother = ScrollSmoother.create({
        content: ".customerLayout",
        smooth: 4,
        normalizeScroll: true,
        ignoreMobileResize: true,
      });
      console.log(scrollSmoother, "scrollSmoother");

      return () => scrollSmoother.kill();
    }
  }, [location.pathname, location.key]);




  return (
    <div className="customerLayout">
      <Navbar />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Customer;
