import React, { useState } from "react";
import axios from "axios";

import senderRequest from "../../../service/Http";
import { handleInputValidation } from "../../../helper/validator";
import toast from "react-hot-toast";
import { json, useNavigate } from "react-router-dom";
// import Upload from "antd/es/upload/Upload";
import EditIcon from "../../../assets/images/edit_icon.svg";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import AddIcon from "../../../assets/images/add_icon.svg";
import Upload from "./../../../components/Upload";

const ThoughtsAdd = () => {
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [hashtags, setHashtags] = useState("");
  const [inputFields, setInputFields] = useState([{ id: 1, description: "" }]);

  const navigate = useNavigate();

  const [isUploading, setIsUploading] = useState(false);

  // const handleFileChange = (e) => {
  //   console.log(e.target.files, "handleFile");
  //   setFile(e.target.files);
  // };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setData((prevData) => ({
  //     ...prevData,
  //     [name]: value
  //   }));
  // };

  const [imageId, setImageId] = useState("");

  const handleSubmit = async (e, imageid) => {
    e.preventDefault();

    if (!title || !inputFields || !hashtags) {
      toast.error("Please fill all the fields");
      return;
    }
    try {
      let body = {
        image_id: imageid,
        title: title,
        paragraph: JSON.stringify(inputFields),
        hashtags: hashtags,
      };

      const response = await senderRequest(
        "post",
        "api/blogs/add",
        "",
        "",
        body
      );

      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
        setHashtags("");
        setFile(null);
        setTitle("");
        setDescription("");
        setIsUploading(false);
        navigate("/admin/thoughts");
      } else {
        toast.error(response.data.message);
      }
      console.log(response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    // setIsUploading(true);
    if (!file) {
      toast.error("Please select an image");
      return;
    }
    if (!title) {
      toast.error("Please enter title");
      return;
    }

    if (!hashtags) {
      toast.error("Please enter hashtags");
      return;
    }

    // Check if there is at least one field with an empty description
    const hasEmptyDescription = inputFields.some(field => field.description.length === 0);

    if (hasEmptyDescription) {
      toast.error("Please enter description");
      return;
    }




    const formData = new FormData();

    formData.append("files", file[0]?.file);

    // formData.append("files[]", file);

    try {
      const response = await senderRequest(
        "post-photo",
        "api/image/upload",
        "",
        "",
        formData
      );

      console.log(typeof response.status, "response");

      if (response.status === 200) {
        console.log("Image uploaded successfully");
        setImageId(response.data.data.id);
        toast.success("Image uploaded successfully");
        await handleSubmit(e, response.data.data.id);
        console.log("Image uploaded successfully");
      } else {
        console.error("Image upload failed");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const handleInputValidationAdd = (value, index) => {
    const newInputFields = [...inputFields];
    newInputFields[index].description = value;
    setInputFields(newInputFields);
  };

  const handleNewInputAdd = () => {
    setInputFields([...inputFields, { id: Date.now(), description: "" }]);
  };

  const handleInputRemove = (id) => {
    const newInputFields = inputFields.filter((field) => field.id !== id);
    setInputFields(newInputFields);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSaveClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await handleUpload(e);
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="whiteFrame">
      <h5 className="fontWeight600 fontSize20 mb_24 blackClr_text">
        Add Thoughts
      </h5>

      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14 mandatory">
                Thoughts Title
                {/* <span className="blooddonorClr_text fontSize16 ml_4">* </span> */}
              </label>
              <input
                type="text"
                placeholder="Enter Thoughts Title"
                name="title"
                value={title}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setTitle, 1)
                }
                required
              />
            </div>
          </div>

          {/* <div className="col-md-6">
            <label
              htmlFor="uploadblogImg"
              className="UploadFrame width100per greenGoneWildClr_text display_block fontWeight600 fontSize14"
            >
              Upload Thoughts Image
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                required
              />
              <button onClick={handleUpload}>Upload Image</button>
            </label>
          </div> */}
          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14 mandatory">
                Hashtags 
                <span className="blooddonorClr_text fontSize12 ml_4 ms-2">(Add it with #) </span>
              </label>
              <input
                type="text"
                placeholder="#Hashtags "
                name="description"
                value={hashtags}
                onChange={(e) => setHashtags(e.target.value)}
                required
              />
            </div>
          </div>
          {/* <div className="">
            <textarea
              id="message"
              rows="4"
              className="block p-2.5 w-full text-sm font-semibold text-gray-800 bg-formbg rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-0 focus:border-blue-500 focus:placeholder-[#5570f1]  focus:text-black focus:font-medium dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Hashtags *"
              pattern="[A-Za-z0-9\p{L}]+"
              value={hashtags}
              onChange={(e) => setHashtags(e.target.value)} // setNewDescription(e.target.value)}
            ></textarea>
          </div> */}
          <div>
            {inputFields.map((inputField, index) => (
              <div className="flex gap-4 mb-3 justify-start items-center">
                {/* <div className=''> */}
                <div className="col-11">
                  <textarea
                    id="message"
                    rows="4"
                    className="block p-2.5 w-full text-sm font-semibold text-gray-800 bg-formbg rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-0 focus:border-blue-500 focus:placeholder-[#5570f1]  focus:text-black focus:font-medium dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={`Description ${index + 1} *`}
                    pattern="[A-Za-z0-9\p{L}]+"
                    value={inputFields[index].description}
                    onChange={(e) =>
                      handleInputValidationAdd(e.target.value, index)
                    }
                  ></textarea>
                </div>
                <div>
                  {inputField.id === 1 ? (
                    <img
                      className="greenGoneWildFilter cursor-pointer"
                      src={AddIcon}
                      alt="EditIcon"
                      onClick={() => handleNewInputAdd()}
                    />
                  ) : (
                    <img
                      className="greenGoneWildFilter cursor-pointer"
                      src={DeleteIcon}
                      alt="EditIcon"
                      onClick={() => handleInputRemove(inputField.id)}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="mt-3">
            <Upload count={1} setImages={setFile} images={file} />
          </div>
        </div>

        <button
          type="submit"
          className={`MainButton greenGoneWild_btnclr mt-3 flex items-center justify-center ${isLoading ? "cursor-not-allowed" : ""
            }`}
          onClick={handleSaveClick}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <svg
                className="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                ></path>
              </svg>
              Uploading...
            </>
          ) : (
            "Save"
          )}
        </button>
      </form>
    </div>
  );
};

export default ThoughtsAdd;
