import React from 'react'

const AdminDashboard = () => {
  return (
    <div className='row'>
      <div className='col-md-4'>
        <div className='whiteFrame'>Projects <span>10</span>  </div>

      </div>
      <div className='col-md-4'>
        <div className='whiteFrame'>Services   <span>15</span> </div>

      </div>
      <div className='col-md-4'>
        <div className='whiteFrame'>Careers  <span>5</span></div>

      </div>
    </div>
  )
}

export default AdminDashboard
