import React, { useEffect, useState } from "react";
import SearchIcon from "../../../assets/images/search_icon.svg";
import BlogOneImg from "../../../assets/images/blogOne_img.png";
import RightArrowIcon from "../../../assets/images/rightArrow.svg";
import searchIcon from "../../../assets/images/search_icon.svg";
import searchClose from "../../../assets/images/search_close.svg";
import LeftGreenIcon from "../../../assets/images/leftGreen_icon.svg";

import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
import { useGSAP } from "@gsap/react";
import senderRequest from "../../../service/Http";
import { Link, useNavigate } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const Thoughts = () => {

  useGSAP(() => {

    console.log("ANIMATION FILE TRIGGERED - SERVICE FILE");

    // const smoother = ScrollSmoother.create({
    //   content: ".customerLayout",
    //   smooth: 2,
    //   normalizeScroll: true,
    //   ignoreMobileResize: true,
    // });

    // const leftToRight = document.querySelectorAll(".leftToRight");
    // const rightToLeft = document.querySelectorAll(".rightToLeft");

    // rightToLeft.forEach((e) => {
    //   gsap.from(e, {
    //     x: 1500,
    //     y: 300,
    //     scrollTrigger: {
    //       trigger: e,
    //       start: "top 80%",
    //       end: "+=300",
    //       scrub: 1,
    //     },
    //     rotate: 45,
    //     duration: 2.5,
    //   });
    // });

    // leftToRight.forEach((e) => {
    //   gsap.from(e, {
    //     x: -1500,
    //     y: 300,
    //     scrollTrigger: {
    //       trigger: e,
    //       start: "top 80%",
    //       end: "+=300",
    //       scrub: 1,
    //     },
    //     rotate: -45,
    //     duration: 2.5,
    //   });
    // });

    const hpValues = gsap.utils.toArray(".blogItem");
    hpValues.forEach((hpValue, i) => {
      gsap.fromTo(
        hpValue,
        {
          scrollTrigger: {
            trigger: hpValue,
            scrub: 1,
            opacity: 0,
          },
        },
        {
          scrollTrigger: {
            trigger: hpValue,
            scrub: 1,
            opacity: 1,
          },
        }
      );
    });

    const tl = gsap.timeline({
      ease: "power2.inOut",
    });

    // tl.from(".blogMain_img ", {
    //   scale: 0.1,
    //   duration: 1,
    //   transformOrigin: "top center",
    // }).to(
    //   {},
    //   {
    //     duration: 1,
    //   }
    // );
    // ScrollTrigger.create({
    //   trigger: "#image",
    //   start: "top top",
    //   end: "+=120%",
    //   pin: true,
    //   animation: tl,
    //   scrub: 1,
    //   pinSpacing: false,
    //   once: true,
    // });
  });

  useEffect(() => {
    handleBlogs();
    window.scrollTo(0, 0);
  }, []);

  const [latestBlogs, setLatestBlogs] = useState([]);

  const [blogs, setBlogs] = useState([]);
  const handleBlogs = async () => {
    try {
      const response = await senderRequest("get", "api/blogs/get-all", {});
      if (response.data.statusCode === 200) {
        setBlogs(response?.data?.data[0]);
        setLatestBlogs(response?.data?.data.slice(1));
      } else {
        setBlogs([]);
      }
      console.log(response, "response");
    } catch (error) {
      setBlogs([]);
    }
  };

  const [searchText, setSearchText] = useState("");

  // useEffect(() => {
  //   if (searchText.length > 1) {
  //     handelSearch();
  //   }
  // }, [searchText]);

  const [dropDown, setDropDown] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  const handelSearch = async () => {
    try {
      const response = await senderRequest(
        "get",
        `api/blogs/get-all?search=${searchText}`,
        {}
      );
      if (response.data.statusCode === 200) {
        setSearchResult(response?.data?.data);
        setToggle(true);
      } else {
        setSearchResult([]);
        setToggle(true);
      }
    } catch (error) {
      setToggle(true);
    }
  };

  const navigate = useNavigate();

  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    setToggle(false);
  }, []);

  console.log(blogs, "blogs");
  return (
    <>
      <section className="commonPage_bnr thought_bnr">
        <div className="container">
          <div className="row alignItem_center">
            <div
              data-aos="fade-down-right"
              data-aos-duration="1500"
              className="col-lg-6 mobSpace_mb24"
            >
              <p className="fontSize24  fontWeight400 cerebralGreyClr_text syne_Font">
                Explore our gallery of thoughts discussed among our experts
                whilst designing and executing our projects.
              </p>
            </div>
            <div
              data-aos="fade-down-left"
              data-aos-duration="1500"
              className="col-lg-6"
            >
              <div className="sectionTitle">
                <h2 className="fontWeight600 atypDisplay_Font textalign_right">
                  THOUGHTS
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blogImagepart">
        <div className="container ">
          <div className="relative">
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="GroupSearchinput"
            >
              <input
                type="text"
                name="Search"
                value={searchText}
                placeholder="Search keywords"
                onChange={(e) => setSearchText(e.target.value)}
              />

              <div className="inputgroup_append">
                <span className="GroupSearchinput_text">
                  {!toggle ? (
                    <button
                      type="button"
                      className="emptyBtn"
                      onClick={searchText.length > 2 ? handelSearch : () => { }}
                    >
                      <img src={SearchIcon} alt="SearchIcon" />
                    </button>
                  ) : (
                    <div
                      onClick={() => {
                        // handleSearch();
                        setSearchResult([]);
                        setSearchText("");
                        setToggle(false);
                      }}
                    >
                      <img src={searchClose} alt="Search close" />
                    </div>
                  )}
                </span>
              </div>
            </div>
            <div className="">
              {searchResult?.map((item, index) => (
                <div
                  className="blogMain_img  flex justify-between items-center "
                  key={index}
                >
                  <Link to={`/thoughts/${item?.blog_id}`}>
                    <div className="flex justify-start items-center gap-4 ">
                      <img
                        src={item?.image_url}
                        className="rounded-md"
                        style={{ width: "12%", height: "12%" }}
                      />
                      <div>
                        <p className="fontWeight600 text-white">
                          {item?.title}
                        </p>
                        <p className=" text-xs fontWeight400 text-white">
                          {item?.hashtags?.split("#")}
                        </p>
                      </div>
                    </div>
                  </Link>
                  <Link to={`/thoughts/${item?.blog_id}`}>
                    <svg
                      width="65"
                      height="19"
                      viewBox="0 0 65 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        y1="9.35718"
                        x2="64"
                        y2="9.35718"
                        stroke="#DBFFAD"
                      />
                      <path
                        d="M64.0004 9.85693C58.2446 7.75327 56.0572 5.85572 54.2861 0.999791"
                        stroke="#DBFFAD"
                      />
                      <path
                        d="M64.0004 9.85693C58.2446 11.9606 56.0572 13.8581 54.2861 18.7141"
                        stroke="#DBFFAD"
                      />
                    </svg>
                  </Link>
                </div>
              ))}
              {toggle && searchResult.length === 0 && (
                <div className="text-center">
                  <p className="fontWeight600 text-white mb-4">
                    No result found
                  </p>
                </div>
              )}
            </div>
          </div>

          <div
            data-aos="fade-up"
            data-aos-duration="1200"
            className="blogMain_img"
          >

            <Link to={`/thoughts/${blogs?.blog_id}`}>
              <img src={blogs.image_url} alt="BlogOneImg" />
            </Link>

            <button className="secondaryBtn position_absolute right_20 top_20 zIndex1">
              {blogs?.created_date}
            </button>
          </div>

          <div className="displayFlex alignItem_center justifyContent_spacebetween MobFlexWrapgap16">
            <div className="mobSpace_mb16">
              <h2
                data-aos="fade-up"
                data-aos-duration="1300"
                className="mobSpace_mb16 drWhiteClr_text fontWeight400 atypDisplay_Font fontSize40"
              >
                <Link to={`/thoughts/${blogs?.blog_id}`}>
                  {blogs.title}
                </Link>
              </h2>

              <ul
                data-aos="fade-up"
                data-aos-duration="1400"
                className="displayFlex alignItem_center gap30 emptyList mb-4"
              >
                {blogs.hashtags?.split("#").filter(item => item).map((item, index) => (
                  <li
                    key={index} // Ideally, use a unique identifier if available
                    className="fontSize16 fontWeight400 blankaGreenClr_text syne_Font textdecoration_underline"
                  >
                    {item} {/* Add back the '#' symbol if needed */}
                  </li>
                ))}
              </ul>

              {/* {JSON.stringify(blogs.paragraph) &&
              JSON.parse(blogs.paragraph).map((item, index) => (
                <p
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  className="fontSize16 fontWeight400 drWhiteClr_text syne_Font mb-3 mt-2"
                >
                  {item.description}
                </p>
              ))} */}
            </div>
            <div data-aos="fade-up" data-aos-duration="1400"></div>

            <button
              className="limeMistBorderbtn"
              onClick={() => navigate(`/thoughts/${blogs.blog_id}`)}
            >
              <img src={RightArrowIcon} alt="RightArrowIcon" />
            </button>
          </div>
        </div>
      </section>
      <section className="latestBlog">
        <div className="container">
          <h3
            data-aos="fade-up"
            data-aos-duration="1500"
            className="fontSize32  fontWeight500 drWhiteClr_text syne_Font mb_40"
          >
            Latest Thoughts
          </h3>

          <div className="row">
            {latestBlogs?.map((blog, index) => (
              <div
                className="col-lg-4 mb_34 leftToRight cursor-pointer"
                onClick={() => navigate(`/thoughts/${blog.blog_id}`)}
              >
                <div
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="blogItem"
                >
                  <div className="blogItemImage">
                    <img src={blog.image_url} alt="BlogOneImg" />
                    <button className="secondaryBtn position_absolute right_20 top_20 zIndex1">
                      {blog?.created_date}
                    </button>
                  </div>
                  <div className="displayFlex alignItem_center justifyContent_spacebetween">
                    <div>
                      <h5 className="fontSize16 fontWeight400 drWhiteClr_text atypDisplay_Font">
                        {blog.title}
                      </h5>
                    </div>
                    <div>
                      <p className="fontSize14 fontWeight400 blankaGreenClr_text syne_Font textdecoration_underline">
                        {blog.hashtags.split("#")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <section className="contactus_marquee">
        <div className="container-fluid">
          <div className="main-marq">
            <div className="slide-har st1">
              <div className="box">
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Thoughts;
