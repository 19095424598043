// import { useFormik } from "formik";
import React from 'react'
import { useNavigate } from 'react-router-dom'
// import { OTPSchema } from "../../utils/validators/validators";
// import { Post } from "../../services/http-service";
// import toast from "react-hot-toast";
// import { getEmailFromSession } from "../../utils/helpers/helper";
import OtpverificationIcon from '../../../assets/images/otpVerification_icon.svg'
import ParticlesAnimation from '../../../components/ParticlesAnimation'

const VerifyOtp = () => {
  const navigate = useNavigate()
  // const { values, errors, handleSubmit, handleBlur, handleChange, touched } =
  //   useFormik({
  //     initialValues: {
  //       email: getEmailFromSession() ?? '',
  //       otp: "",
  //     },
  //     validationSchema: OTPSchema,
  //     onSubmit: async (values, { resetForm }) => {
  //       try {
  //         values.email = sessionStorage.getItem("email");
  //         const resp = await Post("auth/signup/verify-otp", values);
  //         if (resp?.statusCode === 200) {
  //           navigate("/reset-password");
  //           resetForm();
  //         } else if (resp?.response?.data?.statusCode === 400) {
  //           toast.error(resp?.response?.data?.message);
  //         }
  //       } catch (error) {
  //         console.log("error::: ", error);
  //       }
  //     },
  //   });

  return (
    <div className='auth-page-wrapper pt-5'>
      <div className='auth-one-bg-position auth-one-bg' id='auth-particles'>
        <div className='bg-overlay'></div>

        <div className='shape'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.1'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            viewBox='0 0 1440 120'
            fill='#f5f5f5'
          >
            <path d='M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z' />
          </svg>
        </div>
        {/* <canvas className="particles-js-canvas-el" width="1898" height="475" style="width: 100%; height: 100%;" ></canvas> */}

        <ParticlesAnimation />
      </div>
      <div className='auth-page-content'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-8 col-lg-6 col-xl-5'>
              <div className='loginFrame'>
                <div className='mb_24 mlr_auto textalign_center'>
                  <img
                    className='greenGoneWildFilter'
                    src={OtpverificationIcon}
                    alt='OtpverificationIcon'
                  />
                </div>
                <h5 className='textalign_center fontSize18 fontWeight600 blackClr_text mb_8'>
                  OTP Verification
                </h5>
                <p className='SteelWoolClr_text fontSize14 mb_24 fontWeight500 textalign_center'>
                  Enter the OTP Send to this Email Address{' '}
                  <span className='blackClr_text'>
                    {/* {getEmailFromSession() ?? ''}  */}
                  </span>
                </p>

                <form>
                  <div className='InputFrame mb_24'>
                    <label className='roycroftPewterClr_text display_block fontWeight600 fontSize14'>
                      OTP
                      <span className='blooddonorClr_text fontSize16 ml_4'>
                        *{' '}
                      </span>
                    </label>
                    <input
                      type='text'
                      placeholder='Enter Otp'
                      name='fullName'
                    />
                  </div>
                  <button className='MainButton greenGoneWild_btnclr width100per justifyContent_center' type='submit'>
                    Send OTP
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyOtp
