import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BlogOneimg from "../../../assets/images/blogOne_img.png";
import EditIcon from "../../../assets/images/edit_icon.svg";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import AddIcon from "../../../assets/images/add_icon.svg";
import ViewIcon from "../../../assets/images/view_icon.svg";
import senderRequest from "../../../service/Http";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import BusinessQuery from "./BusinessQuery";
import DeleteModal from "../DeleteModal";
const CareerList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [openModal, setOpenModal] = useState(false);
  const [career, setCareer] = useState([]);
  const route = pathname?.split("/")[1];

  const [id, setId] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [show, setShow] = useState(false);

  useEffect(() => {
    setType("ADDCAREER");
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalContent, setModalContent] = useState([]);

  const [type, setType] = useState("ADDCAREER");

  useEffect(() => {
    handleCareer();
  }, []);
  const handleCareer = async () => {
    try {
      const response = await senderRequest(
        "get",
        "api/career/fetch-admin-jobs",
        "",
        "",
        {}
      );
      if (response.data.statusCode === 200) {
        console.log(response, "response");
        setCareer(response?.data?.data);
      } else {
        setCareer("");
      }
    } catch (error) {
      setCareer("");
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      const response = await senderRequest(
        "delete",
        `api/career/delete-jobs/${id}`,
        "",
        "",
        {}
      );
      if (response.data.statusCode === 200) {
        console.log(response, "response");
        setOpenDeleteModal(false);
        handleCareer();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
        <li className="me-2 text-end" onClick={() => setType("ADDCAREER")}>
          <a
            href="#"
            aria-current="page"
            className={
              type === "ADDCAREER"
                ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
                : "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
            }
          >
            Add career
          </a>
        </li>
        <li className="me-2" onClick={() => setType("CAREERQUERY")}>
          <a
            href="#"
            className={
              type === "CAREERQUERY"
                ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
                : "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
            }
          >
            Career queries
          </a>
        </li>
      </ul>

      {type === "ADDCAREER" ? (
        <>
          <div className="flex justify-end mb_24">
            <div>
              <button
                className="MainButton greenGoneWild_btnclr"
                onClick={() => navigate("./add-career")}
              >
                <img src={AddIcon} alt="AddIcon" />
                <span>Add Career</span>
              </button>
            </div>
          </div>

          <div className="relative overflow-x-auto sm:rounded-lg border">
            <table className="w-full min-w-[1400px] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    S.No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Role(s)
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Description
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Responsibility
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Keywords
                  </th>
                  <th scope="col" className="px-2 py-2 min-w-[150px]">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {career &&
                  career?.map((item, index) => (
                    <tr
                      key={item.id}
                      className="bg-white border-b font-medium dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <th
                        scope="row"
                        className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {index + 1}
                      </th>
                      <td className="px-2 py-4 text-black">{item?.roles}</td>
                      <td className="px-2 py-4 text-black">
                        {item?.description?.slice(0, 30)}
                      </td>
                      <td
                        class={`px-2 py-4 ${
                          item?.status
                            ? "greenGoneWildClr_text"
                            : "text-red-800"
                        }`}
                      >
                        {" "}
                        {item?.status ? "Active" : "InActive"}
                      </td>
                      <td className="px-2 py-4 text-black">
                        {item?.responsibility
                          ? JSON.parse(item.responsibility)[0]?.Requirements
                          : null}
                      </td>
                      <td className="px-2 py-4 text-black">
                        {item.keywords.split("#")}
                      </td>
                      <td className="px-2 py-1">
                        <div className="flex items-center gap-3">
                          <button
                            className="actionBtn col-3"
                            onClick={() => {
                              setShow(true);
                              setModalContent(item);
                            }}
                          >
                            <img
                              className="verifiedBlackFilter"
                              src={ViewIcon}
                              alt="ViewIcon"
                            />
                          </button>
                          <button
                            className="actionBtn"
                            onClick={() =>
                              navigate(`./edit-career/${item?.id}`)
                            }
                          >
                            <img
                              className="greenGoneWildFilter"
                              src={EditIcon}
                              alt="EditIcon"
                            />
                          </button>
                          <button
                            className="actionBtn"
                            onClick={() => {
                              setId(item?.id);
                              setOpenDeleteModal(true);
                            }}
                          >
                            <img
                              className="bloodDonorFilter"
                              src={DeleteIcon}
                              alt="DeleteIcon"
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <BusinessQuery />
      )}

      <Modal
        show={show}
        size="md"
        onHide={handleClose}
        centered
        className="TestimonialsPopup"
      >
        <Modal.Header>
          <Modal.Title>Admin Career </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <img
            src={modalContent.image_url}
            alt="Image"
            className="w-full rounded-md  object-cover"
          /> */}
          <p className="blackClr_text text-lg font-medium  pt-3 pb-2">
            Role: {modalContent?.roles}
          </p>
          <p className="blackClr_text text-md font-normal   pb-2">
            Description: {modalContent?.description}
          </p>
          <p className="blackClr_text text-md font-normal   pb-2">
            Status: {modalContent?.status ? "Active" : "InActive"}
          </p>
          <p className="blackClr_text text-md font-normal   pb-2">
            Responsibility : <br></br>
            {modalContent?.responsibility
              ? JSON.parse(modalContent?.responsibility).map((item) => (
                  <p>{item?.Requirements}</p>
                ))
              : null}
          </p>
          <p className="blackClr_text text-md font-normal   pb-2">
            Keywords :{modalContent?.keywords?.split("#")}
          </p>
        </Modal.Body>
      </Modal>
      <DeleteModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        handleDelete={handleDelete}
        id={id}
        name={"career"}
      />
    </>
  );
};

export default CareerList;
