import React from 'react'

const Loader = () => {
  return (
    <div id="loading-wrapper">
    <div id="loading-text">Uploading..</div>
    <div id="loading-content"></div>
  </div>
  )
}

export default Loader