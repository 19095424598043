import React, { useEffect, useRef, useState } from "react";
import { handleInputValidation } from "./../../../helper/validator";
import { UploadOutlined } from "@ant-design/icons";
// import { Button, message, Upload } from "antd";
import AddIcon from "../../../assets/images/add_icon.svg";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import { toast } from "react-hot-toast";
import senderRequest from "../../../service/Http";
import { useNavigate } from "react-router-dom";
import Upload from "../../../components/Upload";
import { Button } from "flowbite-react";

const ProjectAdd = () => {
  const [projectName, setProjectName] = useState("");
  const [location, setLocation] = useState("");
  const [areaName, setAreaName] = useState("");
  const [year, setYear] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [clientName, setClientName] = useState("");
  const [category, setCategory] = useState("");
  const [iconUrl, setIconUrl] = useState("");

  const [isUploading, setIsUploading] = useState(false);

  const [teamcredits, setTeamcredits] = useState([{ id: 1, teamcredits: "" }]);

  const [yearsList, setYearsList] = useState([]);
  console.log(yearsList, "yearsList");

  const [images, setImages] = useState([]);
  const [coverImg, setCoverImg] = useState([]);
  const [video, setVideo] = useState("");
  const [YtLink, setYtLink] = useState("");

  console.log(video?.length, "video", video);

  useEffect(() => {
    // Function to generate a list of years starting from 2023 up to the current year
    const generateYearsList = () => {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = currentYear; i >= 2009; i--) {
        years.push(i.toString());
      }
      return years;
    };

    // Set the list of years when the component mounts
    setYearsList(generateYearsList());
  }, []);

  const [inputFields, setInputFields] = useState([{ id: 1, description: "" }]);
  const [newinputFields, setnewInputFields] = useState([
    { id: 1, paragraph: "" },
    { id: 2, paragraph: "" },
  ]);

  const handleInputValidationAdd = (value, index) => {
    const newInputFields = [...inputFields];
    newInputFields[index].description = value;
    setInputFields(newInputFields);
  };

  const handleTeamValidationAdd = (value, index) => {
    const newInputFields = [...teamcredits];
    newInputFields[index].teamcredits = value;
    setTeamcredits(newInputFields);
  };

  console.log(newinputFields, "newinputFields");
  const handleNewDescription = (value, index) => {
    const newInputFields = [...newinputFields];
    if (newInputFields[index]) {
      newInputFields[index].paragraph = value;
      setnewInputFields(newInputFields);
    } else {
      console.error(`Element at index ${index} does not exist.`);
    }
  };

  const handleNewInputAdd = () => {
    setInputFields([...inputFields, { id: Date.now(), description: "" }]);
  };

  const handleNewTeamAdd = () => {
    setInputFields([...inputFields, { id: Date.now(), description: "" }]);
  };

  const handleInputRemove = (id) => {
    const newInputFields = inputFields.filter((field) => field.id !== id);
    setInputFields(newInputFields);
  };
  const handleTeamRemove = (id) => {
    const newInputFields = inputFields.filter((field) => field.id !== id);
    setInputFields(newInputFields);
  };

  const categoryValue = [
    {
      id: 1,
      category: "Apartments",
    },
    {
      id: 2,
      category: "Landscape",
    },
    {
      id: 3,
      category: "Villas & Resorts",
    },
    {
      id: 4,
      category: "Residence",
    },
    {
      id: 5,
      category: "Commercial Complexes",
    },
    {
      id: 6,
      category: "Hotels",
    },
    {
      id: 7,
      category: "Corporate Office Interiors",
    },
    {
      id: 8,
      category: "Bus Terminals",
    },
  ];

  const [buttonState, setButtonState] = useState("");

  const navigate = useNavigate();

  const handleCoverImage = async (e) => {
    e.preventDefault();
    if (
      !projectName ||
      !location ||
      !year ||
      !clientName ||
      !category ||
      !title
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    const hasEmptyDescription = inputFields.some(field => field.description.length === 0);

    if (hasEmptyDescription) {
      toast.error("Please enter description");
      return;
    }
    setIsUploading(true);
    setButtonState("Cover Image Upload Started");
    toast.success("Cover Image Upload Stareted");
    try {
      const formData = new FormData();
      formData.append("cover", coverImg[0].file);

      const response = await senderRequest(
        "post-photo",
        "api/image/cover",
        "",
        "",
        formData
      );
      if (response.status === 200) {
        console.log("Image uploaded successfully");
        // setTimeout(() => {
        setButtonState("Cover Image Upload Completed");
        toast.success("Cover Image Upload Completed");
        handlIconUpload(e, response.data.data.id);
        // }, 5000);
      }
    } catch (error) { }
  };

  const handlIconUpload = async (e, coverImageId) => {
    e.preventDefault();
    toast.success("Icon Image Upload Started");
    setButtonState("Icon Image Upload Started");
    try {
      const formData = new FormData();
      formData.append("icon", iconUrl[0].file);

      const response = await senderRequest(
        "post-photo",
        "api/image/icon",
        "",
        "",
        formData
      );
      if (response.status === 200) {
        console.log("Image uploaded successfully");
        // setTimeout(() => {
        toast.success("Icon Image Upload Completed");
        setButtonState("Icon Image Upload Completed");
        if (video?.length !== 0) {
          handleVideoUpload(e, coverImageId, response?.data?.data);
        } else {
          handleImage(e, coverImageId, response?.data?.data);
        }

        // }, 5000);
      }
    } catch (error) { }
  };

  const handleVideoUpload = async (e, coverImageId, IconUrl) => {
    e.preventDefault();
    const toastId = toast.loading("Video Upload Started");
    // setButtonState("Video Upload Started");
    const formData = new FormData();
    formData.append("icon", video);
    try {
      const response = await senderRequest(
        "post-photo",
        "api/image/icon",
        "",
        "",
        formData
      );
      if (response.status === 200) {
        console.log("Image uploaded successfully");
        // setTimeout(() => {
        toast.dismiss(toastId);
        toast.success("Video Upload Completed");
        setButtonState("Video Upload Completed");
        handleImage(e, coverImageId, IconUrl, response?.data?.data);
        // }, 5000);
      }
    } catch (error) { }
  };
  const handleImage = async (e, coverImageId, IconUrl, videourl) => {
    e.preventDefault();
    toast.success("Carousel Image Upload Started");
    setButtonState("Carousel Image Upload Started");
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append("files", images[i].file);
    }

    if (!images) {
      toast.error("Please select an image");
      return;
    }

    try {
      const response = await senderRequest(
        "post-photo",
        "api/image/upload",
        "",
        "",
        formData
      );
      console.log("response", response);
      if (response.status === 200) {
        console.log("Image uploaded successfully");
        console.log("response", response.data.data.id);

        toast.success("Carousel Image Upload Completed");
        setButtonState("Carousel Image Upload Completed");
        await handleProjectSubmit(
          e,
          coverImageId,
          IconUrl,
          videourl,
          response.data.data.id
        );

        // toast.success("Image uploaded successfully");
      } else {
        toast.error("Image upload failed");
        console.error("Image upload failed");
      }
    } catch (error) {
      toast.error(error?.message);
      console.error("Network error:", error);
    }
  };
  const handleProjectSubmit = async (
    e,
    coverImageId,
    IconUrl,
    videourl,
    imageId
  ) => {
    toast.success("Upload Completed");
    e.preventDefault();
    try {
      // console.log(body, "body");
      if (
        !projectName ||
        !location ||
        !year ||
        !clientName ||
        !inputFields ||
        !category ||
        !title
      ) {
        toast.error("Please fill all the fields");
        return;
      }

      const body = {
        name: projectName,
        client_name: clientName,
        location: location,
        year_of_completion: year,
        area: areaName,
        title: title,
        sub_title: subTitle,
        category: category,
        image_id: imageId,
        cover_image_id: coverImageId,
        icon_url: IconUrl,
        video_url: videourl,
        description: JSON.stringify(inputFields),
        youtube_link: YtLink,
        // paragraph: JSON.stringify(newinputFields),
      };

      console.log(body, "body");

      const response = await senderRequest(
        "post",
        "api/project/add",
        "",
        "",
        body
      );
      if (response.data.statusCode === 200) {
        console.log(response, "response");
        setAreaName("");
        setClientName("");
        setLocation("");
        setProjectName("");
        setTitle("");
        setSubTitle("");
        setYear("");
        setCategory("");
        setIsUploading(false);
        // setFileList([]);
        // setDescription();
        setImages([]);
        setCoverImg([]);
        setVideo([]);
        setIconUrl([]);
        setnewInputFields([{ id: 1, paragraph: "" }]);
        setInputFields([{ id: 1, description: "" }]);
        navigate("/admin/projects");
        toast.success("Project added successfully");
      }
    } catch (error) {
      setAreaName("");
      setClientName("");
      setLocation("");
      setIsUploading(false);
      setProjectName("");
      setTitle("");
      setSubTitle("");
      setYear("");
      setCategory("");
      setImages([]);
      setCoverImg([]);
      setVideo([]);
      setIconUrl([]);
      // setFileList([]);
      // setDescription();
      setnewInputFields([{ id: 1, paragraph: "" }]);
      setInputFields([{ id: 1, description: "" }]);
      toast.error(error?.message);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Remove spaces from the file name
      const newFileName = file.name.replace(/\s+/g, "");
      const renamedFile = new File([file], newFileName, { type: file.type });
      console.log(renamedFile, "renamedFile");
      setVideo(renamedFile);
    }
  };

  const fileInputRef = useRef(null);
  const handleRemoveVideo = () => {
    setVideo("");
    clearFileInput();
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleUploadClick = async (e) => {
    e.preventDefault();
    if (!iconUrl[0]?.file || !images[0]?.file || !coverImg[0]?.file) {
      toast.error("Please select an image");
      return;
    }

    setLoading(true);
    try {
      await handleCoverImage(e);
      toast.success("Upload successful!");
    } catch (error) {
      toast.error("Upload failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  console.log(images, "fileList");
  return (
    <div className="whiteFrame">
      <h5 className="fontWeight600 fontSize20 mb_24 blackClr_text">
        Add Project
      </h5>

      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Project Name
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="Project Name"
                value={projectName}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setProjectName, 7)
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Location
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="Location"
                value={location}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setLocation, 7)
                }
                name="Experience"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Client Name
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="Client Name"
                value={clientName}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setClientName, 7)
                }
                name="Experience"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Area Name (Sqft)
              </label>
              <input
                type="tel"
                placeholder="Area Name"
                name="desc"
                value={areaName}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setAreaName, 2)
                }
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Year Of COMPLETION
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <select
                name="YEAR OF COMPLETION"
                onChange={(e) => setYear(e.target.value)}
              >
                <option value="" selected disabled>
                  YEAR OF COMPLETION
                </option>
                {yearsList.map((item) => (
                  <option value={item}>{item}</option>
                ))}
                <option value={"In-Progress"}>In-Progress</option>
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                TITLE
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="TITLE"
                name="desc"
                value={title}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setTitle, 7)
                }
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                SUBTITLE
              </label>
              <input
                type="text"
                placeholder="SUBTITLE"
                name="desc"
                value={subTitle}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setSubTitle, 7)
                }
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                CATEGORY
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <select
                name="category"
                className="w-100"
                onChange={(e) => setCategory(e.target.value)}
              >
                <option
                  className="text-black ps-3 pb-2"
                  value=""
                  disabled
                  selected
                >
                  Select Category
                </option>
                {categoryValue.map((item) => (
                  <option
                    className="text-black ps-3 pb-2"
                    value={item.category}
                  >
                    {item.category}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* {teamcredits.map((teamcredit, index) => (
            <div className="flex gap-4 mb-3 justify-start items-center">
              
              <div className="col-11">
                <textarea
                  id="message"
                  rows="4"
                  className="block p-2.5 w-full text-sm font-semibold text-gray-800 bg-formbg rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-0 focus:border-blue-500 focus:placeholder-[#5570f1]  focus:text-black focus:font-medium dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder={`Team credits ${index + 1}`}
                  pattern="[A-Za-z0-9\p{L}]+"
                  value={teamcredits[index].teamcredits}
                  onChange={(e) =>
                    handleTeamValidationAdd(e.target.value, index)
                  }
                ></textarea>
              </div>
              <div>
                {teamcredit.id === 1 ? (
                  <img
                    className="greenGoneWildFilter cursor-pointer"
                    src={AddIcon}
                    alt="EditIcon"
                    onClick={() => handleNewTeamAdd()}
                  />
                ) : (
                  <img
                    className="greenGoneWildFilter cursor-pointer"
                    src={DeleteIcon}
                    alt="EditIcon"
                    onClick={() => handleTeamRemove(teamcredits.id)}
                  />
                )}
              </div>
            </div>
          ))} */}

          {/* <div className="grid grid-cols-2 gap-4">
            <div className="">
              <textarea
                id="message"
                rows="4"
                className="block p-2.5 w-full text-sm font-semibold text-gray-800 bg-formbg rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-0 focus:border-blue-500 focus:placeholder-[#5570f1]  focus:text-black focus:font-medium dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Left Side Content "
                pattern="[A-Za-z0-9\p{L}]+"
                value={newinputFields[0]?.paragraph}
                onChange={(e) => handleNewDescription(e.target.value, 0)} // setNewDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="">
              <textarea
                id="message"
                rows="4"
                className="block p-2.5 w-full text-sm font-semibold text-gray-800 bg-formbg rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-0 focus:border-blue-500 focus:placeholder-[#5570f1]  focus:text-black focus:font-medium dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Right Side Content "
                pattern="[A-Za-z0-9\p{L}]+"
                value={newinputFields[1]?.paragraph}
                onChange={(e) => handleNewDescription(e.target.value, 1)}
              ></textarea>
            </div>
          </div> */}

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Youtube Video Link
               
              </label>
              <input
                type="text"
                placeholder=""
                name="desc"
                value={YtLink}
                onChange={(e) => setYtLink(e.target.value)}
              // onChange={(e) =>
              //   handleInputValidation(e.target.value, setTitle, 7)
              // }
              />
            </div>
          </div>

          {inputFields.map((inputField, index) => (
            <div className="flex gap-4 mb-3 justify-start items-center">
              {/* <div className=''> */}
              <div className="col-11">
                <textarea
                  id="message"
                  rows="4"
                  className="block p-2.5 w-full text-sm font-semibold text-gray-800 bg-formbg rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-0 focus:border-blue-500 focus:placeholder-[#5570f1]  focus:text-black focus:font-medium dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder={`Description ${index + 1}`}
                  pattern="[A-Za-z0-9\p{L}]+"
                  value={inputFields[index].description}
                  onChange={(e) =>
                    handleInputValidationAdd(e.target.value, index)
                  }
                ></textarea>
              </div>
              <div>
                {inputField.id === 1 ? (
                  <img
                    className="greenGoneWildFilter cursor-pointer"
                    src={AddIcon}
                    alt="EditIcon"
                    onClick={() => handleNewInputAdd()}
                  />
                ) : (
                  <img
                    className="greenGoneWildFilter cursor-pointer"
                    src={DeleteIcon}
                    alt="EditIcon"
                    onClick={() => handleInputRemove(inputField.id)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-3">
          <p className="text-lg ps-1 font-medium pb-3">Cover Photo *</p>
          <Upload count={1} setImages={setCoverImg} images={coverImg} />
        </div>
        {/* <div className="mt-3">
          <p className="text-lg ps-1 font-medium pb-3">Video (Optional)</p>
          <div className="border border-black rounded-lg  p-3  flex flex-wrap cursor-pointer">
            <input
              type="file"
              accept="video/mp4"
              ref={fileInputRef}
              className="cursor-pointer"
              onChange={(e) => handleFileChange(e)}
            />
            {video?.length !== 0 && (
              <Button className="text-black" onClick={handleRemoveVideo}>
                Remove
              </Button>
            )}
          </div>
        </div> */}
        <div className="mt-5">
          <p className="text-lg ps-1 font-medium pb-3">Carousel Images *</p>
          <Upload count={50} setImages={setImages} images={images} />
        </div>
        <div className="mt-2">
          <p className="text-lg ps-1 font-medium pb-3">Icon image *</p>
          <Upload count={1} setImages={setIconUrl} images={iconUrl} />
        </div>

        <p
          className={`MainButton greenGoneWild_btnclr mt-5 w-28 ${loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          onClick={!loading ? handleUploadClick : undefined}
        >
          {loading ? <> <svg
            className="animate-spin h-5 w-5 mr-2 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
            ></path>
          </svg>
            Uploading...</> : "Update"}
        </p>
      </form>
    </div>
  );
};

export default ProjectAdd;
