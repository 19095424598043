import React from "react";
import { Outlet, Route, useRoutes } from "react-router-dom";
import Customer from "../pages/Customer/Customer";
import Login from "../pages/Admin/auth/Login";
import ForgotPassword from "../pages/Admin/auth/ForgotPassword";
import VerifyOtp from "../pages/Admin/auth/VerifyOtp";
import ResetPassword from "../pages/Admin/auth/ResetPassword";
import Admin from "../pages/Admin/Admin";
import AdminDashboard from "../pages/Admin/AdminDashboard";
import ProtectedRoute from "./ProtectedRoute";
import PageNotFound from "../pages/PageNotFound";
import Home from "../pages/Customer/Home";
import Queries from "../pages/Admin/Queries";
import Testimonials from "../pages/Admin/Testimonials/Testimonials";
import AddTestimonials from "../pages/Admin/Testimonials/AddTestimonials";

import CareerList from "../pages/Admin/Careers/CareerList";
import CareerAdd from "../pages/Admin/Careers/CareerAdd";
import CareerEdit from "../pages/Admin/Careers/CareerEdit";
import ProjectAdd from "../pages/Admin/Projects/ProjectAdd";
import ProjectEdit from "../pages/Admin/Projects/ProjectEdit";
import ServicesAdd from "../pages/Admin/Services/ServicesAdd";
import ServicesEdit from "../pages/Admin/Services/ServicesEdit";
import ProjectList from "../pages/Admin/Projects/ProjectList";
import ServicesList from "../pages/Admin/Services/ServicesList";
import Thoughts from "../pages/Customer/Thoughts/Thoughts";
import ThoughtDetail from "../pages/Customer/Thoughts/ThoughtDetail";
import Projects from "../pages/Customer/Projects/Projects";
import ProjectDetail from "../pages/Customer/Projects/ProjectDetail";
import Careers from "../pages/Customer/Careers";
import ContactUs from "../pages/Customer/ContactUs";
import AboutUs from "../pages/Customer/AboutUs";
import Services from "../pages/Customer/Services";


import ThoughtsAdd from "../pages/Admin/Thoughts/ThoughtsAdd";
import ThoughtsList from "../pages/Admin/Thoughts/ThoughtsList";

export const Routes = () => {
  const routes = useRoutes([
    // {
    //   path: '*',
    //   element: <PageNotFound />
    // },
    {
      path: "/admin/login",
      element: <Login />,
    },
    {
      path: "/admin/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/admin/verify-otp",
      element: <VerifyOtp />,
    },
    {
      path: "/admin/reset-password",
      element: <ResetPassword />,
    },
    {
      element: <Customer />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/thoughts",
          element: <Thoughts />,
        },
        {
          path: "/thoughts/:id",
          element: <ThoughtDetail />,
        },
        {
          path: "/projects",
          element: <Projects />,
        },
        {
          path: "/projects/:id",
          element: <ProjectDetail />,
        },
        {
          path: "/careers",
          element: <Careers />,
        },
        {
          path: "/about-us",
          element: <AboutUs />,
        },
        {
          path: "/services",
          element: <Services />,
        },
        {
          path: "/contact-us",
          element: <ContactUs />,
        },
      ],
    },
    {
      path: "/admin",
      element: <Admin />,
      children: [
        {
          path: "dashboard",
          element: (
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          ),
        },
        {
          path: "thoughts",
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute>
                  <ThoughtsList />
                </ProtectedRoute>
              ),
            },
            {
              path: "add-thoughts",
              element: (
                <ProtectedRoute>
                  <ThoughtsAdd />
                </ProtectedRoute>
              ),
            },
            // {
            //   path: "edit-thoughts",
            //   element: (
            //     <ProtectedRoute>
            //       <Thoughts />
            //     </ProtectedRoute>
            //   ),
            // },
          ],
        },
        {
          path: "career",
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute>
                  <CareerList />
                </ProtectedRoute>
              ),
            },
            {
              path: "add-career",
              element: (
                <ProtectedRoute>
                  <CareerAdd />
                </ProtectedRoute>
              ),
            },
            {
              path: "edit-career/:id",
              element: (
                <ProtectedRoute>
                  <CareerEdit />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "projects",
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute>
                  <ProjectList />
                </ProtectedRoute>
              ),
            },
            {
              path: "add-project",
              element: (
                <ProtectedRoute>
                  <ProjectAdd />
                </ProtectedRoute>
              ),
            },
            {
              path: "edit-project/:id",
              element: (
                <ProtectedRoute>
                  <ProjectEdit />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "services",
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute>
                  <ServicesList />
                </ProtectedRoute>
              ),
            },
            {
              path: "add-service",
              element: (
                <ProtectedRoute>
                  <ServicesAdd />
                </ProtectedRoute>
              ),
            },
            {
              path: "edit-service",
              element: (
                <ProtectedRoute>
                  <ServicesEdit />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "queries",
          element: (
            <ProtectedRoute>
              <Queries />
            </ProtectedRoute>
          ),
        },
        {
          path: "testimonials",
          element: (
            <ProtectedRoute>
              <Testimonials />
            </ProtectedRoute>
          ),
        },
        {
          path: "testimonials/add-testimonial",
          element: (
            <ProtectedRoute>
              <AddTestimonials />
            </ProtectedRoute>
          ),
        },
      ],
    },
  ]);
  return <>{routes}</>;
};
