import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
// import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from "draftjs-to-html";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import AddIcon from "../../../assets/images/add_icon.svg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import senderRequest from "../../../service/Http";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Upload from "../../../components/Upload";

const CareerAdd = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [htmlContent, setHtmlContent] = useState("");

  const [iconUrl, setIconUrl] = useState("");

  // const onEditorStateChange = (newEditorState) => {
  //   console.log(newEditorState, "newEditorState");
  //   setEditorState(newEditorState);
  //   const contentState = newEditorState.getCurrentContent();
  //   const html = draftToHtml(convertToRaw(contentState));
  //   setHtmlContent(html);
  // };
  // console.log(editorState, "editorState", htmlContent);
  const [inputFields, setInputFields] = useState([{ id: 1, Requirements: "" }]);
  const handleInputValidationAdd = (value, index) => {
    const newInputFields = [...inputFields];
    newInputFields[index].Requirements = value;
    setInputFields(newInputFields);
  };
  const handleNewInputAdd = () => {
    setInputFields([...inputFields, { id: Date.now(), Requirements: "" }]);
  };

  const handleInputRemove = (id) => {
    const newInputFields = inputFields.filter((field) => field.id !== id);
    setInputFields(newInputFields);
  };

  const [role, setRole] = useState("");
  const [jobdesc, setJobDesc] = useState("");
  // const [requirements, setRequirements] = useState('')
  const [keySkills, setKeySkills] = useState("");
  const [status, setStatus] = useState("");

  const navigate = useNavigate();

  const handlIconUpload = async (e) => {
    e.preventDefault();
    try {

      if (!role || !jobdesc || !status) {
        toast.error("Please fill in all required fields.");
        return;
      }

      const hasEmptyDescription = inputFields.some(field => field.Requirements.length === 0);

      if (hasEmptyDescription) {
        toast.error("Please enter description");
        return;
      }

      if (!iconUrl[0]) {
        toast.error("Please select an image");
        return;
      }

      const formData = new FormData();
      formData.append("icon", iconUrl[0].file);

      const response = await senderRequest(
        "post-photo",
        "api/image/icon",
        "",
        "",
        formData
      );
      if (response.status === 200) {
        console.log("Image uploaded successfully");
        // setTimeout(() => {
        handleSubmit(e, response.data.data);
        console.log("Image uploaded successfully");
        // }, 5000);
      }
    } catch (error) { }
  };

  const handleSubmit = async (event, imageid) => {
    event.preventDefault();

    if (!role || !jobdesc || !status || inputFields.length === 0) {
      toast.error("Please fill in all required fields.");
      return;
    }

    const data = {
      roles: role,
      description: jobdesc,
      keywords: keySkills,
      status: status,
      icon_url: imageid,
      responsibility: JSON.stringify(inputFields),
    };
    console.log(data, "data");
    // senderRequest
    const response = await senderRequest(
      "post",
      "api/career/add",
      "",
      "",
      data
    );

    if (response.data.statusCode === 200) {
      toast.success(response.data.message);
      navigate("/admin/career");
      setRole("");
      setJobDesc("");
      setKeySkills("");
      setStatus("");
      setInputFields([{ id: 1, Requirements: "" }]);
      console.log(response, "response");
    }
  };


  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await handlIconUpload(e);
    } catch (error) {
      console.error("Icon upload failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="whiteFrame">
      <h5 className="fontWeight600 fontSize20 mb_24 blackClr_text">
        Add Career
      </h5>

      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Role
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="Enter Role"
                name="Role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Job Description
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="Enter Role Description"
                name="desc"
                value={jobdesc}
                onChange={(e) => setJobDesc(e.target.value)}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Status
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <select
                name="status"
                className="form-control"
                id="status"
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="" selected disabled>
                  Select Status
                </option>
                <option value="active">Active </option>
                <option value="inactive">InActive</option>
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Key Skills
              </label>
              <input
                type="text"
                placeholder="Ex: #HTML, #CSS, #JavaScript"
                name="Location"
                value={keySkills}
                onChange={(e) => setKeySkills(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* <div className="">
          <label className='roycroftPewterClr_text display_block fontWeight600 fontSize14'>
            responsibility

          </label>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            toolbar={{
              options: [
                "inline",
                "blockType",
                "list",
                "textAlign",
                "link",
                "history",
              ],
              inline: {
                options: ["bold", "italic", "underline", "strikethrough"],
              },
              blockType: {
                options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
              },
              list: { options: ["unordered", "ordered"] },
              textAlign: { options: ["left", "center", "right"] },
              link: { inDropdown: false },
              history: { inDropdown: false },
            }}
            placeholder="Enter your text here..."
            onEditorStateChange={onEditorStateChange}
          />

          <div
            dangerouslySetInnerHTML={{
              __html: draftToHtml(convertToRaw(editorState.getCurrentContent()))
            }}
          />
        </div> */}
        {inputFields.map((inputField, index) => (
          <div className="flex gap-4 mb-3 justify-start items-center">
            {/* <div className=''> */}
            <div className="col-11">
              <textarea
                id="message"
                rows="4"
                className="block p-2.5 w-full text-sm font-semibold text-gray-800 bg-formbg rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-0 focus:border-blue-500 focus:placeholder-[#5570f1]  focus:text-black focus:font-medium dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder={`Requirements ${index + 1}`}
                pattern="[A-Za-z0-9\p{L}]+"
                value={inputFields[index].Requirements}
                onChange={(e) =>
                  handleInputValidationAdd(e.target.value, index)
                }
              ></textarea>
            </div>
            <div>
              {inputField.id === 1 ? (
                <img
                  className="greenGoneWildFilter cursor-pointer"
                  src={AddIcon}
                  alt="EditIcon"
                  onClick={() => handleNewInputAdd()}
                />
              ) : (
                <img
                  className="greenGoneWildFilter cursor-pointer"
                  src={DeleteIcon}
                  alt="EditIcon"
                  onClick={() => handleInputRemove(inputField.id)}
                />
              )}
            </div>
          </div>
        ))}

        <div className="mt-2 mb-3">
          <p className="text-lg ps-1 font-medium pb-3">Icon image</p>
          <Upload count={1} setImages={setIconUrl} images={iconUrl} />
        </div>

        <button
          className={`MainButton greenGoneWild_btnclr flex items-center justify-center ${isLoading ? "cursor-not-allowed" : ""
            }`}
          onClick={handleClick}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <svg
                className="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                ></path>
              </svg>
              Uploading...
            </>
          ) : (
            "Save"
          )}
        </button>
      </form>
    </div >
  );
};

export default CareerAdd;
