// import { useFormik } from "formik";
import React, { useState } from 'react'
// import { PasswordValidationSchema } from "../../utils/validators/validators";
// import { Post } from "../../services/http-service";
// import { getEmailFromSession } from "../../utils/helpers/helper";
// import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom'
import Eyehide from '../../../assets/images/eye_hide.svg'
import Eyeshow from '../../../assets/images/eye_show.svg'
import ResetpasswordIcon from '../../../assets/images/reset-password_icon.svg'
import ParticlesAnimation from '../../../components/ParticlesAnimation'

const ResetPassword = () => {
  const navigate = useNavigate()
  const [showPass, setShowPass] = useState(false)
  const [showConfPass, setShowConfPass] = useState(false)
  // const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
  //   useFormik({
  //     initialValues: {
  //       email: getEmailFromSession(),
  //       new_password: "",
  //       confirm_password: "",
  //     },
  //     validationSchema: PasswordValidationSchema,
  //     onSubmit: async (values, { resetForm }) => {
  //       try {
  //         const resp = await Post("auth/reset-password", values);
  //         if (resp.statusCode === 200) {
  //           toast.success(resp.message);
  //           sessionStorage.clear();
  //           navigate("/");
  //           resetForm();
  //         }
  //       } catch (error) {
  //         console.log("error::: ", error);
  //       }
  //     },
  //   });

  return (
    <div className='auth-page-wrapper pt-5'>
      <div className='auth-one-bg-position auth-one-bg' id='auth-particles'>
        <div className='bg-overlay'></div>

        <div className='shape'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.1'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            viewBox='0 0 1440 120'
            fill='#f5f5f5'
          >
            <path d='M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z' />
          </svg>
        </div>
        {/* <canvas className="particles-js-canvas-el" width="1898" height="475" style="width: 100%; height: 100%;" ></canvas> */}

        <ParticlesAnimation />
      </div>
      <div className='auth-page-content'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-8 col-lg-6 col-xl-5'>
              <div className='loginFrame'>
                <div className='mb_24 mlr_auto textalign_center'>
                  <img
                    className='greenGoneWildFilter'
                    src={ResetpasswordIcon}
                    alt='ResetpasswordIcon'
                  />
                </div>
                <h5 className='textalign_center fontSize18 fontWeight600 blackClr_text mb_8'>
                  Reset Password
                </h5>
                <p className='SteelWoolClr_text fontSize14 mb_24 fontWeight500 textalign_center'>
                  Pleae Enter Your New Password
                </p>

                <form>
                  <div className='InputFrame mb_24 displayFlex alignItem_center'>
                    <div className='width100per'>
                      <label className='roycroftPewterClr_text display_block fontWeight600 fontSize14'>
                        Password
                        <span className='blooddonorClr_text fontSize16 ml_4'>
                          *{' '}
                        </span>
                      </label>
                      <input
                        type='text'
                        placeholder='Example: John Doe'
                        name='Password'
                      />
                    </div>
                    <div className='input-group-append'>
                      <button
                        type='button'
                        className='emptyBtn'
                        onClick={() => setShowPass(!showPass)}
                      >
                        {showPass ? (
                          <img src={Eyeshow} alt='Eyeshow' />
                        ) : (
                          <img src={Eyehide} alt='Eyehide' />
                        )}
                      </button>
                    </div>
                  </div>

                  <div className='InputFrame mb_24 displayFlex alignItem_center'>
                    <div className='width100per'>
                      <label className='roycroftPewterClr_text display_block fontWeight600 fontSize14'>
                        Confirm Password
                        <span className='blooddonorClr_text fontSize16 ml_4'>
                          *{' '}
                        </span>
                      </label>
                      <input
                        type='text'
                        placeholder='Confirm Password'
                        name='Password'
                      />
                    </div>
                    <div className='input-group-append'>
                      <button
                        type='button'
                        className='emptyBtn'
                        onClick={() => setShowPass(!showPass)}
                      >
                        {showPass ? (
                          <img src={Eyeshow} alt='Eyeshow' />
                        ) : (
                          <img src={Eyehide} alt='Eyehide' />
                        )}
                      </button>
                    </div>
                  </div>

                  <button type='submit' className='MainButton greenGoneWild_btnclr width100per justifyContent_center'>
                    Reset Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
