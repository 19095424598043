import Header from './Layout/Header'
import { Routes } from './routes/Routes'
import { Toaster } from 'react-hot-toast'
import { useEffect } from 'react'
import './style.scss'
import AOS from 'aos'
import 'aos/dist/aos.css'
import AnimatedCursor from 'react-animated-cursor'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
function App () {
  const path = window.location.pathname
  console.log()
  useEffect(() => {
    AOS.init({ duration: 1000 })
    console.log('AOS initialized')
  }, [])

  useEffect(() => {
    // Check if the URL contains 'admin'
    if (path.startsWith('/admin')) {
      console.log("#hello in if")
      document.documentElement.style.setProperty(
        '--background-color',
        'white'
      )
    } else {
      console.log("#hello in else")
      // Otherwise, set it to the desired background color
      document.documentElement.style.setProperty(
        '--background-color',
        '#121315'
      )
    }
  }, [])
  return (
    <>
      {/* {path.startsWith('/admin') && <Header />} */}
      <SkeletonTheme baseColor="#202020" highlightColor="#444">      
        {path.startsWith('/admin')
        ? ''
        : window.innerWidth > 768 && (
            <AnimatedCursor
              innerSize={10}
              outerSize={35}
              color='219, 255, 173'
              outerAlpha={0.2}
              innerScale={1}
              outerScale={2}
              outerStyle={{
                mixBlendMode: 'exclusion'
              }}
              clickables={['a', 'button', '.link']}
            />
          )}

      <Toaster />
      <Routes />
      </SkeletonTheme>
    </>
  )
}

export default App
