import React from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Import the default styles

const ResponsiveSkeleton = ({ class_name }) => {
    return <Skeleton className={class_name} />;
};

const ResponsiveSkeleton_Tag = ({ width }) => {
    return <Skeleton width={width} />;
};




export default { ResponsiveSkeleton, ResponsiveSkeleton_Tag };
