import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BlogOneimg from "../../../assets/images/blogOne_img.png";
import EditIcon from "../../../assets/images/edit_icon.svg";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import AddIcon from "../../../assets/images/add_icon.svg";
import ViewIcon from "../../../assets/images/view_icon.svg";
import senderRequest from "../../../service/Http";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import DeleteModal from "../DeleteModal";

const ProjectList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [projects, setProjects] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(null);
  const route = pathname?.split("/")[1];
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [modalContent, setModalContent] = useState([]);
  const handleShow = (project) => {
    setShow(true);
    setModalContent(project);
  };

  useEffect(() => {
    handleProjects();
  }, []);
  const handleProjects = async () => {
    try {
      const response = await senderRequest(
        "get",
        "api/project/get-all",
        "",
        "",
        {}
      );
      if (response.data.statusCode === 200) {
        console.log(response, "response");
        setProjects(response?.data?.data);
      } else {
        setProjects("");
      }
    } catch (error) {
      setProjects("");
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      const response = await senderRequest(
        "delete",
        `api/project/delete/${id}`,
        "",
        "",
        {}
      );
      if (response.data.statusCode === 200) {
        console.log(response, "response");
        setOpenModal(false);
        handleProjects();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  console.log(projects, "projects");

  return (
    <>
      <div className="displayFlex alignItem_center justifyContent_spacebetween mb_24">
        <div>
          <h2 className="fontSize24 fontWeight600 blackClr_text">Project</h2>
        </div>
        <div>
          <button
            className="MainButton greenGoneWild_btnclr"
            onClick={() => navigate("./add-project")}
          >
            <img src={AddIcon} alt="AddIcon" />
            <span>Add Project</span>
          </button>
        </div>
      </div>
      {projects?.length !== 0 ? (
        <div className="Table_Wrapper tableResponsive">
          <table className="mb_24">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Image(s)</th>
                <th>Project Name</th>
                <th>Year of Completion</th>
                <th>Category</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {projects?.map((project, index) => (
                <tr>
                  <td>{index + 1} </td>
                  <td className="adminblog_frame">
                    <img src={project.cover_image_url} alt="BlogOneimg" />
                  </td>
                  <td>{project.name}</td>
                  <td>{project.year_of_completion}</td>
                  <td>{project?.category}</td>
                  <td>
                    <div className="displayFlex alignItem_center gap12">
                      <div>
                        <button
                          className="actionBtn"
                          onClick={() => handleShow(project)}
                        >
                          <img
                            className="verifiedBlackFilter"
                            src={ViewIcon}
                            alt="ViewIcon"
                          />
                        </button>
                      </div>
                      <div>
                        <button
                          className="actionBtn"
                          onClick={() =>
                            navigate(`./edit-project/${project.project_id}`)
                          }
                        >
                          <img
                            className="greenGoneWildFilter"
                            src={EditIcon}
                            alt="EditIcon"
                          />
                        </button>
                      </div>
                      <div>
                        <button
                          className="actionBtn"
                          onClick={() => {
                            setId(project?.project_id);
                            setOpenModal(true);
                          }}
                        >
                          <img
                            className="bloodDonorFilter"
                            src={DeleteIcon}
                            alt="DeleteIcon"
                          />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        "No Data Found"
      )}

      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        centered
        className="TestimonialsPopup"
      >
        <Modal.Header>
          <Modal.Title>Projects</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={modalContent.cover_image_url}
            alt="Image"
            className="w-full rounded-md  object-cover"
          />
          <p className="blackClr_text text-lg font-medium  pt-3 pb-2">
            Title: {modalContent?.title}
          </p>
          <p className="blackClr_text text-lg font-medium  pt-3 pb-2">
            SubTitle: {modalContent?.sub_title}
          </p>
          <p className="blackClr_text text-lg font-medium  pt-3 pb-2">
            {modalContent?.paragraph &&
              modalContent?.paragraph.map((item) => item.paragraph)}
          </p>
          <p className="blackClr_text text-lg font-medium  pt-3 pb-2">
            Category: {modalContent?.category}
          </p>
          <p className="blackClr_text text-md font-normal   pb-2">
            Year of Completion:{modalContent?.year_of_completion}
          </p>
          <p className="blackClr_text text-md font-normal   pb-2">
            Area {modalContent?.area} Sqft
          </p>
          <p className="blackClr_text text-md font-normal   pb-2">
            Clint Name: {modalContent?.client_name}
          </p>
          <p className="blackClr_text text-md font-normal   pb-2">
            Location: {modalContent?.location}
          </p>
          <p className="blackClr_text text-md font-normal   pb-2">
            Description{" "}
            {modalContent?.description &&
              modalContent?.description?.map((item) => item?.description)}
          </p>
          <p></p>
          <p></p>
        </Modal.Body>
      </Modal>

      <DeleteModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleDelete={handleDelete}
        id={id}
        name={"Project"}
      />
    </>
  );
};

export default ProjectList;
