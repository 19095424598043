import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ThoughtsOneimg from "../../../assets/images/blogOne_img.png";
import EditIcon from "../../../assets/images/edit_icon.svg";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import AddIcon from "../../../assets/images/add_icon.svg";
import ViewIcon from "../../../assets/images/view_icon.svg";
import senderRequest from "../../../service/Http";
import ThoughtsEditModel from "./ThoughtsEditModel";
import toast from "react-hot-toast";
import Item from "antd/es/list/Item";
import Modal from "react-bootstrap/Modal";
import DeleteModal from "../DeleteModal";

const ThoughtsList = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { pathname } = useLocation();
  const route = pathname?.split("/")[1];

  const [blogs, setThoughts] = useState([]);
  const [modalContent, setModalContent] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [paragraph, setParagraph] = useState("");
  const [image, setImage] = useState(null);
  const [hashtags, setHashtags] = useState([]);
  const [image_id, setImage_id] = useState("");
  const [inputFields, setInputFields] = useState([{ id: 1, description: "" }]);

  useEffect(() => {
    handleThoughts();
  }, []);

  const handleThoughts = async () => {
    try {
      const response = await senderRequest("get", "api/blogs/get-all", {});
      if (response.data.statusCode === 200) {
        setThoughts(response?.data?.data);
      } else {
        setThoughts([]);
      }
      console.log(response, "response");
    } catch (error) {
      setThoughts([]);
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      const response = await senderRequest(
        "delete",
        `api/blogs/delete/${id}`,
        {}
      );
      if (response.data.statusCode === 200) {
        console.log(response, "response");
        handleThoughts();
        setOpenDeleteModal(false);
        toast.success(response.data.message);
      } else {
      }
    } catch (error) {}
  };

  // console.log(JSON.parse(item?.paragraph.description[0]), "description");
  return (
    <>
      <div className="displayFlex alignItem_center justifyContent_spacebetween mb_24">
        <div>
          <h2 className="fontSize24 fontWeight600 blackClr_text">Thoughts</h2>
        </div>
        <div>
          <button
            className="MainButton greenGoneWild_btnclr"
            onClick={() => navigate("./add-thoughts")}
          >
            <img src={AddIcon} alt="AddIcon" />
            <span>Add Thoughts</span>
          </button>
        </div>
      </div>
      <div className="Table_Wrapper tableResponsive">
        <table className="mb_24">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Thoughts Image</th>
              <th>Thoughts Title</th>
              <th>Thoughts Desc</th>
              <th>HashTags</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {blogs &&
              blogs?.map((item, index) => (
                <tr>
                  <td> {index + 1}</td>
                  <td className="adminblog_frame">
                    <img src={item.image_url} alt="ThoughtsOneimg" />
                  </td>
                  <td>{item?.title}</td>
                  <td>
                    {item?.paragraph
                      ? JSON?.parse(item?.paragraph)[0]?.description?.slice(
                          0,
                          30
                        )
                      : ""}
                  </td>
                  <td>{item?.hashtags}</td>
                  <td>
                    <div className="displayFlex alignItem_center gap12">
                      <div>
                        <button
                          className="actionBtn"
                          onClick={() => {
                            setShow(true);
                            setModalContent(item);
                          }}
                        >
                          <img
                            className="verifiedBlackFilter"
                            src={ViewIcon}
                            alt="ViewIcon"
                          />
                        </button>
                      </div>
                      <div>
                        <button
                          className="actionBtn"
                          onClick={() => {
                            setTitle(item.title);
                            // setParagraph(item.paragraph);
                            setInputFields(JSON.parse(item.paragraph));
                            setImage(item.image_url);
                            setHashtags(item.hashtags);
                            setOpenModal(true);
                            setId(item.blog_id);
                            setImage_id(item.image_id);
                          }}
                        >
                          <img
                            className="greenGoneWildFilter"
                            src={EditIcon}
                            alt="EditIcon"
                          />
                        </button>
                      </div>
                      <div>
                        <button
                          className="actionBtn"
                          onClick={() => {
                            setId(item.blog_id);
                            setOpenDeleteModal(true);
                          }}
                        >
                          <img
                            className="bloodDonorFilter"
                            src={DeleteIcon}
                            alt="DeleteIcon"
                          />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        centered
        className="ThoughtssPopup"
      >
        <Modal.Header>
          <Modal.Title>Thoughts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={modalContent.image_url}
            alt="Image"
            className="w-full h-72 object-cover"
          />
          <p className="blackClr_text text-lg font-medium  pt-3 pb-2">
            {modalContent?.title}
          </p>
          <p className="text-xs">
            {modalContent?.paragraph &&
              JSON.parse(modalContent?.paragraph)?.map(
                (item) => item.description
              )}
          </p>
          <p className="blackClr_text text-xs font-medium  pt-3 pb-2">
            {modalContent?.hashtags}
          </p>
          <p></p>
          <p></p>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        centered
        className="ThoughtsPopup"
      >
        <Modal.Header>
          <Modal.Title>Thoughts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={modalContent.image_url}
            alt="Image"
            className="w-full h-72 object-cover"
          />
          <p className="blackClr_text text-lg font-medium  pt-3 pb-2">
            {modalContent?.title}
          </p>
          <p className="text-xs">
            {modalContent?.paragraph &&
              JSON.parse(modalContent?.paragraph)?.map(
                (item) => item.description
              )}
          </p>
          <p className="blackClr_text text-xs font-medium  pt-3 pb-2">
            {modalContent?.hashtags}
          </p>
          <p></p>
          <p></p>
        </Modal.Body>
      </Modal>

      <ThoughtsEditModel
        openModal={openModal}
        setOpenModal={setOpenModal}
        id={id}
        title={title}
        setTitle={setTitle}
        inputFields={inputFields}
        setInputFields={setInputFields}
        image={image}
        setImage={setImage}
        image_id={image_id}
        setImageId={setImage_id}
        hashtags={hashtags}
        setHashtags={setHashtags}
        handleThoughts={handleThoughts}
      />

      <DeleteModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        handleDelete={handleDelete}
        id={id}
        name={"thoughts"}
      />
    </>
  );
};

export default ThoughtsList;
