import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BlogOneimg from "../../../assets/images/blogOne_img.png";
import EditIcon from "../../../assets/images/edit_icon.svg";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import AddIcon from "../../../assets/images/add_icon.svg";
import ViewIcon from "../../../assets/images/view_icon.svg";
import senderRequest from "../../../service/Http";
import toast from "react-hot-toast";
import AddTestimonialsModal from "./AddTestimonialsModal";
import Modal from "react-bootstrap/Modal";
import DeleteModal from "../DeleteModal";
import EditTestimonialsModal from "./EditTestimonialsModal";

const ServicesList = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [id, setId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalContent, setModalContent] = useState([]);
  const { pathname } = useLocation();
  const route = pathname?.split("/")[1];

  const [testimonials, setTestimonials] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  useEffect(() => {
    handleTestimonials();
  }, []);

  const handleTestimonials = async () => {
    try {
      const res = await senderRequest("get", `api/testimonial/get-all`, "", "");
      if (res.data.statusCode === 200) {
        setTestimonials(res?.data?.data);
      } else {
        setTestimonials([]);
      }
    } catch (error) {
      console.log(error);
      setTestimonials([]);
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      const response = await senderRequest(
        "delete",
        `api/testimonial/delete/${id}`,
        "",
        ""
      );
      if (response.data.statusCode === 200) {
        console.log(response, "47");
        handleTestimonials();
        setOpenDeleteModal(false);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setOpenDeleteModal(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="service_smoother  displayFlex alignItem_center justifyContent_spacebetween mb_24">
        <div>
          <h2 className="fontSize24 fontWeight600 blackClr_text">
            Testimonials
          </h2>
        </div>
        <div>
          <button
            className="MainButton greenGoneWild_btnclr"
            onClick={() => setOpenModal(true)}
          >
            <img src={AddIcon} alt="AddIcon" />
            <span>Add Testimonials</span>
          </button>
        </div>
      </div>
      {testimonials?.length > 0 ? (
        <div className="Table_Wrapper tableResponsive">
          <table className="mb_24">
            <thead>
              <tr>
                <th>S.No</th>
                {/* <th>Name</th> */}
                <th>Company Name</th>
                {/* <th>Desigination Name</th> */}
                <th>Feedback</th>
                {/* <th>Tags</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {testimonials?.map((item, i) => (
                <tr>
                  <td> {i + 1}</td>
                  {/* <td>{item?.name}</td> */}
                  <td>{item?.company_name}</td>
                  {/* <td>{item?.designation}</td> */}
                  <td>
                    {item?.feedback?.length > 30
                      ? item?.feedback?.slice(0, 30) + "..."
                      : item.feedback}
                  </td>
                  {/* <td>
                    {item?.tags
                      ? item?.tags?.split("#")?.length > 20
                        ? item.tags.split("#").slice(0, 20)
                        : item.tags.split("#")
                      : null}
                  </td> */}
                  <td>
                    <div className="displayFlex alignItem_center gap12">
                      <div>
                        <button
                          className="actionBtn"
                          onClick={() => {
                            setShow(true);
                            setModalContent(item);
                          }}
                        >
                          <img
                            className="verifiedBlackFilter"
                            src={ViewIcon}
                            alt="ViewIcon"
                          />
                        </button>
                      </div>
                      <div>
                        <button
                          className="actionBtn"
                          onClick={() => {
                            setId(item?.testimonial_id);
                            setOpenEditModal(true);
                          }}
                        >
                          <img
                            className="greenGoneWildFilter"
                            src={EditIcon}
                            alt="EditIcon"
                          />
                        </button>
                      </div>
                      <div>
                        <p
                          className="actionBtn cursor-pointer"
                          // onClick={(e) => {

                          //   handleDelete(e, item?.testimonial_id);
                          // }}
                          onClick={() => {
                            setId(item?.testimonial_id);
                            setOpenDeleteModal(true);
                          }}
                        >
                          <img
                            className="bloodDonorFilter"
                            src={DeleteIcon}
                            alt="DeleteIcon"
                          />
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        "No Data Found"
      )}

      <Modal
        show={show}
        size="md"
        onHide={handleClose}
        centered
        className="TestimonialsPopup"
      >
        <Modal.Header>
          <Modal.Title>Testimonials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-2">
            <p className="blackClr_text text-lg font-medium  pt-3 pb-2">
              Name: <br></br>
              {modalContent?.name}
            </p>
            <p className="blackClr_text text-lg font-medium  pt-3 pb-2">
              Comapany Name:<br></br> {modalContent?.company_name}
            </p>
            <p className="blackClr_text text-lg font-medium  pt-3 pb-2">
              Desigination:<br></br> {modalContent?.designation}
            </p>
            <p className="blackClr_text text-md font-normal   pb-2">
              <span className="text-lg font-medium "> Feedback:</span> <br></br>{" "}
              {modalContent?.feedback}
            </p>
          </div>
          <p></p>
          <p></p>
        </Modal.Body>
      </Modal>

      <AddTestimonialsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleTestimonials={handleTestimonials}
      />

      <EditTestimonialsModal
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        handleTestimonials={handleTestimonials}
        id={id}
      />

      <DeleteModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        handleDelete={handleDelete}
        id={id}
        name={"thoughts"}
      />
    </>
  );
};

export default ServicesList;
