import React, { useEffect, useState } from "react";
import { handleInputValidation } from "./../../../helper/validator";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import AddIcon from "../../../assets/images/add_icon.svg";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import senderRequest from "../../../service/Http";
import toast from "react-hot-toast";
import Upload from "./../../../components/Upload";
const ProjectEdit = () => {
  const [projectName, setProjectName] = useState("");
  const [location, setLocation] = useState("");
  const [areaName, setAreaName] = useState("");
  const [year, setYear] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [clientName, setClientName] = useState("");
  const [category, setCategory] = useState("");
  const [inputFields, setInputFields] = useState([{ id: 1, description: "" }]);
  const [coverUrl, setCoverUrl] = useState("");
  const [newIconURL, setNewIconURL] = useState([]);

  const [editIcon, setEditIcon] = useState(false);
  // const [coverImgId,]
  const [iconUrl, setIconUrl] = useState("");
  const [iconId, setIconId] = useState("");
  const [coverId, setCoverId] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [newCover, SetNewCover] = useState("");
  const [newImg, setImg] = useState([]);
  const [video, setVideo] = useState([]);
  console.log(video, "video");
  const [curoselImgId, setCuroselImgId] = useState([
    imgUrl ? imgUrl?.map((item) => item.image_id) : null,
  ]);

  console.log(curoselImgId, "curoselImgId")

  // console.log(
  //   imgUrl?.find((item) => item.file),
  //   "imgUrl"
  // );

  const [initialSet, setInitialset] = useState(true);

  useEffect(() => {
    if (imgUrl?.length > 0 && initialSet) {
      setCuroselImgId(imgUrl ? imgUrl?.map((item) => item.image_id) : null);
      setInitialset(false);
    }
  }, [imgUrl, initialSet]);

  const [activeStates, setActiveStates] = useState([]);

  console.log(imgUrl, "activeStates", curoselImgId);

  const handleClick = (index) => {
    console.log(index, "index");
    const newActiveStates = activeStates;
    console.log(newActiveStates, "newActiveStates");
    newActiveStates[index] = true;
    setActiveStates(newActiveStates);
  };
  const [coverimgEdit, setCoverimgEdit] = useState(true);
  const [videoEdit, setVideoEdit] = useState(true);
  const [YtLink, setYtLink] = useState("");
  const [newinputFields, setnewInputFields] = useState([
    { id: 1, paragraph: "" },
    { id: 2, paragraph: "" },
  ]);

  const params = useParams();
  console.log(params, "params");

  const [yearsList, setYearsList] = useState([]);
  console.log(yearsList, "yearsList");

  const navigate = useNavigate();

  useEffect(() => {
    // Function to generate a list of years starting from 2023 up to the current year
    const generateYearsList = () => {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = currentYear; i >= 2009; i--) {
        years.push(i.toString());
      }
      return years;
    };

    // Set the list of years when the component mounts
    setYearsList(generateYearsList());
  }, []);

  useEffect(() => {
    handleProjects();
  }, [params]);


  const handleImage = async (e) => {
    e.preventDefault();

    if (!imgUrl || imgUrl.length === 0) {
      toast.error("Please select an image");
      return;
    }

    for (let index = 0; index < imgUrl.length; index++) {
      const img = imgUrl[index];
      if (img.file) {
        let formData = new FormData();
        formData.append("files", img.file);

        let api;



        if (curoselImgId[index] !== null && curoselImgId[index] !== undefined) {
          // If there is a corresponding carousel image ID
          console.log(curoselImgId[index], "curoselImgId[index]");
          if (curoselImgId[index] !== img.id) {
            // If the current image ID matches the corresponding carousel image ID
            api = `api/image/update/${curoselImgId[index]}/nocover`;
          }
        } else {
          // If there is no corresponding carousel image ID (new image)
          api = `api/image/update/${image_id}/newupload`;
        }

        try {
          const response = await senderRequest(
            "post-photo",
            api,
            "",
            "",
            formData
          );
          console.log("response", response);
          if (response.status === 200) {
            console.log("Image uploaded successfully");
            toast.success("Image uploaded successfully");
          } else {
            toast.error("Image upload failed");
            console.error("Image upload failed");
            return;  // Exit if an upload fails
          }
        } catch (error) {
          toast.error(error?.message);
          console.error("Network error:", error);
          return;  // Exit if there's a network error
        }
      }
    }

    // Redirect after all images have been processed
    navigate("/admin/projects");
  };


  const [image_id, setImageId] = useState("");

  const handleProjects = async (e) => {
    try {
      const response = await senderRequest(
        "get",
        `api/project/get/${params?.id}`,
        "",
        ""
      );
      if (response.data.statusCode === 200) {
        console.log(response.data, "response");
        setProjectName(response?.data.data[0]?.name);
        setLocation(response?.data.data[0]?.location);
        setAreaName(response?.data.data[0]?.area);
        setYear(response?.data.data[0]?.year_of_completion);
        setTitle(response?.data.data[0]?.title);
        setSubTitle(response?.data.data[0]?.sub_title);
        setClientName(response?.data.data[0]?.client_name);
        setCategory(response?.data.data[0]?.category);
        setCoverUrl(response?.data.data[0]?.cover_image_url);
        setCoverId(response?.data.data[0]?.cover_image_id);
        setImgUrl(response?.data.data[0]?.image_url);
        setIconUrl(response?.data.data[0]?.icon_url);
        setImageId(response?.data.data[0]?.image_id);
        // setIconId();
        setVideo(response?.data.data[0]?.video_url);
        console.log(response?.data.data[0]?.cover_image_url, "hello");
        setInputFields(JSON.parse(response?.data.data[0]?.description));
        setYtLink(response?.data.data[0]?.youtube_link);
        // setnewInputFields(JSON.parse(response?.data.data[0]?.paragraph));
        // setProjects(response?.data);
      } else {
        // setProjects();
      }
    } catch (error) {
      // setProjects("");
    }
  };

  const [coverImg, setCoverImg] = useState("");

  const handleCoverImage = async (e) => {
    e.preventDefault();
    // setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append("files", newCover[0].file);

      const response = await senderRequest(
        "post-photo",
        `api/image/update/${coverId}/cover`,
        "",
        "",
        formData
      );
      if (response.status === 200) {
        console.log("Image uploaded successfully");
        toast.success("cover image Uploaded successfully");
      }
      else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error("Please Select Cover image")
    }
  };

  const handleVideoUpload = async (e, IconUrl) => {
    e.preventDefault();
    const toastVideo = toast.loading("Video uploading ...");
    const formData = new FormData();
    formData.append("icon", video);
    try {
      const response = await senderRequest(
        "post-photo",
        "api/image/icon",
        "",
        "",
        formData
      );
      if (response.status === 200) {
        console.log("Image uploaded successfully");
        toast.dismiss(toastVideo);
        return response.data.data;
      } else {
        toast.dismiss(toastVideo);
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.dismiss(toastVideo);
      toast.error("No Video file uploaded");
    }
  };

  const handleEdit = async (e, IconUrl, videoUrl) => {
    e.preventDefault();
    try {
      if (
        !projectName ||
        !location ||
        !year ||
        !clientName ||
        !inputFields ||
        !category ||
        !title
      ) {
        toast.error("Please fill all the fields");
        return;
      }
      let body = {
        project_id: params?.id,
        name: projectName,
        location: location,
        area: areaName,
        year_of_completion: year,
        title: title,
        sub_title: subTitle,
        client_name: clientName,
        category: category,
        description: JSON.stringify(inputFields),
        paragraph: JSON.stringify(newinputFields),
        youtube_link: YtLink,
      };

      if (IconUrl) {
        body.icon_url = IconUrl;
      }

      if (videoUrl) {
        body.video_url = videoUrl;
      }

      const response = await senderRequest(
        "put",
        `api/project/update`,
        "",
        "",
        body
      );
      if (response.data.statusCode === 200) {
        console.log(response, "response");
        // handleProjects();

        navigate("/admin/projects");
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleInputValidationAdd = (value, index) => {
    const newInputFields = [...inputFields];
    newInputFields[index].description = value;
    setInputFields(newInputFields);
  };

  const handleNewDescription = (value, index) => {
    console.log(value, index);
    const newInputFields = [...newinputFields];
    console.log(newInputFields, "newInputFields");
    if (newInputFields[index]) {
      newInputFields[index].paragraph = value;
      console.log(newInputFields, "newInputFields");
      setnewInputFields(newInputFields);
    } else {
      console.error(`Element at index ${index} does not exist.`);
    }
  };

  const handleNewInputAdd = () => {
    setInputFields([...inputFields, { id: Date.now(), description: "" }]);
  };

  const handleInputRemove = (id) => {
    const newInputFields = inputFields.filter((field) => field.id !== id);
    setInputFields(newInputFields);
  };

  console.log(coverUrl, "inputFields");

  const handlIconUpload = async (e) => {
    e.preventDefault();
    const iconToast = toast.loading("Uploading icons...");
    try {
      const formData = new FormData();
      formData.append("icon", newIconURL[0].file);

      const response = await senderRequest(
        "post-photo",
        "api/image/icon",
        "",
        "",
        formData
      );
      console.log(response, "responseIconUrl");
      if (response.status === 200) {
        console.log("Image uploaded successfully");
        // setTimeout(() => {
        toast.dismiss(iconToast)
        return response.data.data;
        // if (!coverimgEdit) {
        //   handleCoverImage(e, response.data.data);
        // } else {
        //   handleEdit(e, response.data.data);
        // }

        console.log("Image uploaded successfully");
        // }, 5000);
      } else {
        toast.dismiss(iconToast)
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.dismiss(iconToast)
      toast.error("No icon file selected ")
    }
  };

  const categoryValue = [
    {
      id: 1,
      category: "Apartments",
    },
    {
      id: 2,
      category: "Landscape",
    },
    {
      id: 3,
      category: "Villas & Resorts",
    },
    {
      id: 4,
      category: "Residence",
    },
    {
      id: 5,
      category: "Commercial Complexes",
    },
    {
      id: 6,
      category: "Hotels",
    },
    {
      id: 7,
      category: "Corporate Office Interiors",
    },
    {
      id: 8,
      category: "Bus Terminals",
    },
  ];

  console.log(projectName, "categoryValue");

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleApiClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    setDisabled(true);

    try {
      let iconUrl, coverData, videoUrl, imageId;

      if (editIcon) {
        iconUrl = await handlIconUpload(e);
        if (!iconUrl) {
          throw new Error("Icon upload failed");
        }
      }

      if (!coverimgEdit) {
        await handleCoverImage(e);
      }

      if (!videoEdit) {
        videoUrl = await handleVideoUpload(e);
      }

      let imageResponse;

      if (imgUrl.some((item) => item.file)) {
        imageResponse = await handleImage(e);

      }

      if (!imageResponse || imageResponse === 200) {
        await handleEdit(e, iconUrl, videoUrl);
      }
    } catch (error) {
      console.error("Error handling API calls", error);
      toast.error(error.message || "Error handling API calls");
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newFileName = file.name.replace(/\s+/g, "");
      const renamedFile = new File([file], newFileName, { type: file.type });

      setVideo(renamedFile);
    }
  };

  return (
    <div className="whiteFrame">
      <h5 className="fontWeight600 fontSize20 mb_24 blackClr_text">
        Edit Project
      </h5>

      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Project Name
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="Project Name"
                value={projectName}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setProjectName, 7)
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Location
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="Location"
                value={location}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setLocation, 7)
                }
                name="Experience"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Client Name
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="Client Name"
                value={clientName}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setClientName, 7)
                }
                name="Experience"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Area Name (Sqft)
              </label>
              <input
                type="tel"
                placeholder="Area Name"
                name="desc"
                value={areaName}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setAreaName, 2)
                }
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Year Of COMPLETION
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <select
                name="YEAR OF COMPLETION"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              >
                <option value="" selected disabled>
                  YEAR OF COMPLETION
                </option>
                {yearsList.map((item) => (
                  <option value={item}>{item}</option>
                ))}
                <option value={"In-Progress"}>In-Progress</option>
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                TITLE
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="TITLE"
                name="desc"
                value={title}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setTitle, 7)
                }
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                SUBTITLE
              </label>
              <input
                type="text"
                placeholder="SUBTITLE"
                name="desc"
                value={subTitle}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setSubTitle, 7)
                }
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                CATEGORY
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <select
                name="category"
                className="w-100"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option
                  className="text-black ps-3 pb-2"
                  value=""
                  disabled
                  selected
                >
                  Select Category
                </option>
                {categoryValue?.map((item) => (
                  <option
                    className="text-black ps-3 pb-2"
                    value={item.category}
                  >
                    {item.category}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Youtube Video Link
                {/* <span className="blooddonorClr_text fontSize16 ml_4">* </span> */}
              </label>
              <input
                type="text"
                placeholder=""
                name="desc"
                value={YtLink}
                onChange={(e) => setYtLink(e.target.value)}
              // onChange={(e) =>
              //   handleInputValidation(e.target.value, setTitle, 7)
              // }
              />
            </div>
          </div>

          {inputFields?.map((inputField, index) => (
            <div className="flex gap-4 mb-3 justify-start items-center">
              <div className="col-11">
                <textarea
                  id="message"
                  rows="4"
                  className="block p-2.5 w-full text-sm font-semibold text-gray-800 bg-formbg rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-0 focus:border-blue-500 focus:placeholder-[#5570f1]  focus:text-black focus:font-medium dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder={`Description ${index + 1}`}
                  pattern="[A-Za-z0-9\p{L}]+"
                  value={inputFields[index].description}
                  onChange={(e) =>
                    handleInputValidationAdd(e.target.value, index)
                  }
                ></textarea>
              </div>
              <div>
                {inputField.id === 1 ? (
                  <img
                    className="greenGoneWildFilter cursor-pointer"
                    src={AddIcon}
                    alt="EditIcon"
                    onClick={() => handleNewInputAdd()}
                  />
                ) : (
                  <img
                    className="greenGoneWildFilter cursor-pointer"
                    src={DeleteIcon}
                    alt="EditIcon"
                    onClick={() => handleInputRemove(inputField.id)}
                  />
                )}
              </div>
            </div>
          ))}

          {/* <div className="grid grid-cols-2 gap-4">
            <div className="">
              <textarea
                id="message"
                rows="4"
                className="block p-2.5 w-full text-sm font-semibold text-gray-800 bg-formbg rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-0 focus:border-blue-500 focus:placeholder-[#5570f1]  focus:text-black focus:font-medium dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Left Side Content *"
                pattern="[A-Za-z0-9\p{L}]+"
                value={
                  newinputFields[0] ? newinputFields[0]?.paragraph : "" || ""
                }
                onChange={(e) => handleNewDescription(e.target.value, 0)} // setNewDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="">
              <textarea
                id="message"
                rows="4"
                className="block p-2.5 w-full text-sm font-semibold text-gray-800 bg-formbg rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-0 focus:border-blue-500 focus:placeholder-[#5570f1]  focus:text-black focus:font-medium dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Right Side Content *"
                pattern="[A-Za-z0-9\p{L}]+"
                value={
                  newinputFields[1] ? newinputFields[1]?.paragraph : "" || ""
                }
                onChange={(e) => handleNewDescription(e.target.value, 1)}
              ></textarea>
            </div>
          </div> */}

          <div>
            <p className="text-lg font-medium"> Cover Photo</p>
            <div className=" mb-3">
              {coverimgEdit ? (
                <div className="flex flex-col gap-4 ">
                  <img src={`${coverUrl}`} alt="" className="w-64" />
                  <div className="w-16">
                    <p
                      onClick={() => {
                        setCoverimgEdit(false);
                      }}
                      className="MainButton cursor-pointer p-3 m-auto text-center mt-3 bg-gray-500 text-white"
                    >
                      Edit
                    </p>
                  </div>
                </div>
              ) : (
                <Upload count={1} setImages={SetNewCover} images={newCover} />
              )}
            </div>
          </div>
          <div>
            <p className="text-lg font-medium mb-2">Carousel Images</p>
            <Upload
              count={50}
              setImages={setImgUrl}
              images={imgUrl}
              Edit={true}
              curoselImgId={curoselImgId}
            />
          </div>

          {/* <div className="mt-3">
            <p className="text-lg ps-1 font-medium pb-3">Video</p>

           
                {
                  video?.length ? VideoComp(video) : ""
                }
                <button
                  onClick={() => setVideoEdit(false)}
                  className="MainButton p-3 mt-3 bg-gray-500 text-white cursor-pointer"
                >
                  Edit
                </button>
              </div>
            ) : (
              <div className="mb-3">
                <input
                  type="file"
                  accept="video/mp4"
                  className=""
                  onChange={(e) => handleFileChange(e)}
                />
              </div>
            )}
          </div> */}

          <div className="mt-2 mb-3">
            <p className="text-lg ps-1 font-medium pb-3 ">Icon image</p>
            {editIcon ? (
              <Upload count={1} setImages={setNewIconURL} images={newIconURL} />
            ) : (
              <>
                <img src={iconUrl} alt="icon" />
                <button
                  className="MainButton p-3 mt-3 bg-gray-500 text-white"
                  onClick={() => setEditIcon(true)}
                >
                  Edit
                </button>
              </>
            )}
          </div>
        </div>
        <button
          className="MainButton greenGoneWild_btnclr mt-5"
          onClick={handleApiClick}
          disabled={disabled}
        >
          {loading ? <> <svg
            className="animate-spin h-5 w-5 mr-2 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
            ></path>
          </svg>
            Uploading...</> : "Update"}
        </button>
      </form>
    </div>
  );
};

export default ProjectEdit;

const VideoComp = (source) => {
  console.log("SOURCE", source)
  return <div className="rounded-lg">
    <video autoplay="true" muted controls="false" >
      <source src={source} type="video/mp4" />
      <source src={source} type="video/ogg" />
      Your browser does not support the video tag.
    </video>
  </div>
}