import React, { useState, useEffect } from "react";
import MetaskapeswhiteLogo from "../assets/images/metaskapelogo.svg";
import HumburgerIcon from "../assets/images/humburger_icon.svg";
import SquareToggleIcon from "../assets/images/squareToggle.svg";
import CloseIcon from "../assets/images/close_icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";

import InstagramLogo from "../assets/images/instagram_logo.svg";
import linkedinIcon from "../assets/images/linkedin_icon.svg";
import WhatsappLogo from "../assets/images/whatsapp_icon.svg";
import FacebookLogo from "../assets/images/facebook_icon.svg";
const Navbar = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const [activeItem, setActiveItem] = useState("");
  const location = useLocation();

  useEffect(() => {
    setActiveItem(location.pathname); // Update active item when location changes
  }, [location.pathname]);

  const handleMenuClick = (path) => {
    navigate(path); // Navigate to the specified path
    setIsOpen(false); // Close the menu after navigation
    setActiveItem(path);
  };

  const [fix, setFix] = useState(false);
  function setFixed() {
    if (window.scrollY >= 100) {
      setFix(true);
    } else {
      setFix(false);
    }
  }
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", setFixed);
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpen) {
      // document.body.style.overflow = 'hidden'; // Disable scrolling
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
      // document.body.style.overflow = 'auto'; // Enable scrolling
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  return (
    <>
      <div className="loader fixed top-0 left-0 w-full h-screen overflow-hidden opacity-0 invisible">
        <svg
          id="shape-overlays"
          className="overlay h-full w-full"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <path
            fill="#17181A"
            className="overlay__path"
            vectorEffect="non-scaling-stroke"
            d="M 0 0 V 0 Q 50 0 100 0 V 0 z"
          ></path>
        </svg>
      </div>
      <header
        className={`${
          pathname === "/" ? "position_absolute width100per" : ""
        }  ${fix ? "" : ""}`}
      >
        <div className="container">
          <nav>
            <div className="brandLogo">
              <a href="/">
                <img src={MetaskapeswhiteLogo} onClick={() => setIsOpen(false)} alt="MetaskapeswhiteLogo" />
              </a>
            </div>
            <div>
              {isOpen ? (
                <button onClick={toggleMenu} className="menToggle">
                  <img src={CloseIcon} alt="CloseIcon" />
                </button>
              ) : (
                <button onClick={toggleMenu} className="squareToggle">
                  <img src={SquareToggleIcon} alt="SquareToggleIcon" />
                </button>
              )}
              <div className={isOpen ? "tr__overlay show" : "tr__overlay"}>
                <div className="container">
                  <div className="row">
                    <div className="Main_menu col-lg-8 mobSpace_mb34">
                      <ul>
                        <li
                          className={activeItem === "/about-us" ? "active" : ""}
                        >
                          <a href="/about-us"  >
                            <button
                              data-text="ABOUT"
                              onClick={() => {
                                setIsOpen(false);
                                // navigate("/about-us");
                                setActiveItem("/about-us");
                              }}
                            >
                              <div className="navigation__dot"></div>
                              <span>ABOUT</span>
                            </button>
                          </a>
                        </li>
                        <li
                          className={activeItem === "/services" ? "active" : ""}
                        >
                          <Link to="/services">
                            <button
                              data-text="SERVICES"
                              onClick={() => {
                                setIsOpen(false);
                                setActiveItem("/services");
                              }}
                            >
                              <div className="navigation__dot"></div>
                              <span>SERVICES</span>
                            </button>
                          </Link>
                        </li>
                        <li
                          className={activeItem === "/projects" ? "active" : ""}
                        >
                          <Link to="/projects">
                            <button
                              data-text="PROJECTS"
                              onClick={() => {
                                setIsOpen(false);
                                setActiveItem("/projects");
                              }}
                            >
                              <div className="navigation__dot"></div>
                              <span>PROJECTS</span>
                            </button>
                          </Link>
                        </li>
                        <li
                          className={activeItem === "/thoughts" ? "active" : ""}
                        >
                          <Link to="/thoughts">
                            <button
                              data-text="THOUGHTS"
                              onClick={() => {
                                setIsOpen(false);
                                setActiveItem("/thoughts");
                              }}
                            >
                              <div className="navigation__dot"></div>
                              <span>THOUGHTS</span>
                            </button>
                          </Link>
                        </li>
                        <li
                          className={activeItem === "/careers" ? "active" : ""}
                        >
                          <Link to="/careers">
                            <button
                              data-text="CAREER"
                              onClick={() => {
                                setIsOpen(false);
                                setActiveItem("/careers");
                              }}
                            >
                              <div className="navigation__dot"></div>
                              <span>CAREER</span>
                            </button>
                          </Link>
                        </li>

                        <li
                          className={
                            activeItem === "/contact-us" ? "active" : ""
                          }
                        >
                          <Link to="/contact-us">
                            <button
                              data-text="LET'S CONNECT"
                              onClick={() => {
                                setIsOpen(false);
                                setActiveItem("/contact-us");
                              }}
                            >
                              <div className="navigation__dot"></div>
                              <span>LET'S CONNECT</span>
                            </button>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="col-lg-4 displayFlex alignItem_end justifyContent_end">
                      <div className="socialMediaLinks ">
                        <ul>
                          <li>
                            <Link
                              className="MobSpaceBetween"
                              target="_blank"
                              to="https://www.facebook.com/people/Metaskapes-Chennai/100083225022674/"
                            >
                              <img src={FacebookLogo} alt="FacebookLogo" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://www.instagram.com/metaskapes/"
                              target="_blank"
                            >
                              <img src={InstagramLogo} alt="InstagramLogo" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.linkedin.com/company/metaskapes/about/"
                              className="TwitterWidth_243px"
                            >
                              <img src={linkedinIcon} alt="linkedinIcon" />
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="https://wa.me/+919043099772"
                              target="_blank"
                            >
                              <img src={WhatsappLogo} alt="WhatsappLogo" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Navbar;
