import React, { useState, useRef, useEffect } from 'react'
import Slider from 'react-slick'

import architecture_service from '../../assets/images/architecture_service-2.png'
import construction_service from '../../assets/images/Construction_img.jpg'
import projectmanage_service from '../../assets/images/projectmanage_service-2.jpg'
import interior_service from '../../assets/images/interior_service-2.jpg'
import residential_service from '../../assets/images/residential_service-2.jpg'
import city_service from '../../assets/images/city_service.jpg'
import landscape_service from '../../assets/images/landscape-service.jpg'
import exterior_service from '../../assets/images/Building-Exterior_img.png'
import consult_service from '../../assets/images/consult_service.jpg'

import service1 from '../../assets/images/services_1.svg'
import service2 from '../../assets/images/services_2.svg'
import service3 from '../../assets/images/services_3.svg'
import service4 from '../../assets/images/services_4.svg'
import service5 from '../../assets/images/services_5.svg'
import service6 from '../../assets/images/services_6.svg'

import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
import { useGSAP } from "@gsap/react";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { useNavigate } from 'react-router-dom'
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const Services = () => {

  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const sliderRef = useRef(null);
  useGSAP(() => {
    console.log("ANIMATION FILE TRIGGERED - SERVICE FILE");

    // const smoother = ScrollSmoother.create({
    //   content: ".customerLayout",
    //   smooth: 2,
    //   normalizeScroll: true,
    //   ignoreMobileResize: true,
    // });

    const isMobile = window.innerWidth <= 768

    const imagesInAnimation = document.querySelectorAll(".servicesImages");
    const contentInAnimation = document.querySelectorAll(".servicesContent");

    if (!isMobile) {

      imagesInAnimation.forEach((e) => {
        gsap.from(e, {
          x: 1500,
          y: 300,
          scrollTrigger: {
            trigger: e,
            start: "top 80%",
            end: "+=300",
            scrub: 1,
          },
          rotate: 45,
          duration: 2.5,
        });
      });

      contentInAnimation.forEach((e) => {
        gsap.from(e, {
          x: -1500,
          y: 300,
          scrollTrigger: {
            trigger: e,
            start: "top 80%",
            end: "+=300",
            scrub: 1,
          },
          rotate: -45,
          duration: 2.5,
        });
      });
    }

    if (isMobile) {

      ScrollTrigger.getAll().forEach(trigger => trigger.kill());

      imagesInAnimation.forEach((image, index) => {
        if (contentInAnimation[index]) {
          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: image,
              start: "top 80%",
              end: "+=300",
              scrub: 1,
            }
          });

         
          tl.from(image, {
            x: 1500,
            y: 300,
            rotate: 45,
            duration: 2.5,
             ease: "power2.out"
          });

          
          tl.from(contentInAnimation[index], {
            x: -1500,
            y: 300,
            rotate: -45,
            duration: 2.5,
            delay: 0.5,
             ease: "power2.out"
          });
        }
      });
  }




    // gsap.to(".text h2", {
    //   backgroundPositionX: "0%",
    //   stagger: 1,
    //   scrollTrigger: {
    //     trigger: ".text h2",
    //     scrub: 1,
    //     start: "top center",
    //     end: "bottom center",
    //   },
    // });

    const textH2 = document.querySelectorAll(".h2-1");
  if (textH2) {
    gsap.fromTo(
      ".h2-1",
      {
        x: -100,
        backgroundPositionX: "100%",
        scrollTrigger: {
          trigger: ".h2-2",
          scrub: 1,
          start: "top 60%",
          end: "bottom 20%",
        },
      },
      {
        backgroundPositionX: "0%",
        stagger: 1,
        x: 0,
        scrollTrigger: {
          trigger: ".h2-1",
          // markers: true,
          scrub: 1,
          start: "top 70%",
          end: "bottom 20%",
        },
      }
    );

    gsap.fromTo(
      ".h2-2",
      {
        x: 100,
        backgroundPositionX: "100%",
        scrollTrigger: {
          trigger: ".h2-2",
          scrub: 1,
          start: "top 70%",
          end: "bottom 20%",
        },
      },
      {
        backgroundPositionX: "0%",
        stagger: 1,
        x: 0,
        scrollTrigger: {
          trigger: ".h2-2",
          scrub: 1,
          start: "top 70%",
          end: "bottom 20%",
        },
      },
      {}
    );
  }



  // gsap.fromTo(
  //   ".heading_animation",
  //   {
  //     // x: -700,
  //     backgroundPosition: "-1000% left",
  //     scrollTrigger: {
  //       trigger: ".heading_animation",
  //       scrub: 1,
  //       // markers: true,
  //     },
  //   },
  //   {
  //     // x: 0,
  //     css: {
  //       backgroundImage:
  //         "background: linear-gradient(90deg, #000 0%, #000 90%, transparent 100%)",
  //       backgroundPosition: "200% center",
  //     },
  //     scrollTrigger: {
  //       trigger: ".heading_animation",
  //       scrub: 1,
  //       // markers: true,
  //     },
  //   }
  // );
});

const handleNextSlide = () => {
  if (sliderRef.current) {
    sliderRef.current.slickNext(); // This triggers the next slide action
  }
};


const onEdge = (direction) => {
  console.log(`Edge reached, direction: ${direction}`);
  // Perform any action you want when the edge is reached
};
const ourProcess = {
  dots: false,
  arrows: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 500,
  initialSlide: 1,
  slidesToShow: 3,
  // slidesToScroll: 1,
  cssEase: 'linear',
  draggable: true,
  swipeToSlide: true,
  pauseOnHover: true,
  accessibility: true,
  touchThreshold: 5,
  // swipe: true,
  touchMove: true,
  onEdge: onEdge, // Attach the onEdge event
  lazyLoad: 'progressive', // or 'ondemand'
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        centerMode: true
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        initialSlide: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        initialSlide: 1
      }
    }
  ]
}

return (
  <>
    <section className='commonPage_bnr services_bnr'>
      <div className='container'>
        <div className='row alignItem_center'>
          <div data-aos="fade-down-right" data-aos-duration="1500" className='col-lg-6 mobSpace_mb24'>

            <p className='fontSize24  fontWeight400 cerebralGreyClr_text syne_Font'>Evoking Emotional Resonance Through Thoughtfully Designed Architectural
              Spaces</p>
          </div>
          <div className='col-lg-6'>

            <div data-aos="fade-down-left"
              data-aos-duration="1500" className='sectionTitle text-right '>
              <h2 className='atypDisplay_Font  drWhiteClr_text_one'>WHAT WE <br className='MobBr_hide' /> DO</h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pb_100'>
      <div className='container'>
        <div className='row justifyContent_center'>
          {/* col-lg-8 */}
          <div className='col-lg-9'>
            <h5 data-aos="fade-up" data-aos-duration="800" className='textalign_center fontSize40 atypDisplay_Font fontWeight300 drWhiteClr_text mb_32'>Craft illuminating design solutions and tailored architectural innovations at any
              scale. Our designs blend futuristic concepts with minimalistic elegance and opulent interiors</h5>
            <p data-aos="fade-up" data-aos-duration="900" className='textalign_center fontSize30 syne_Font fontWeight400 SteelWoolClr_text'>We deliver such excellence with ease through our project management prowess.</p>
          </div>
        </div>
      </div>
    </section>


    <section className='services_sec'>
      <div className='container'>
        <div className='servicesItem'>
          <div className='row alignItem_center flexDirectionColumnReverse'>
            <div className='col-lg-6 '>

              <div className='servicesContent'>
                {/* <h4>CATEGORY 1</h4> */}
                <h1>O1</h1>
                <h2>Architectural Design</h2>
                <p className='syne_Font fontSize20 fontWeight400 SteelWoolClr_text'>
                  Metaskapes masters Architectural projects of all sizes in India.
                  Specializing in sustainable solutions, from mixed-use buildings to
                  urban spaces, we excel in quality and innovation, surpassing client
                  expectations with visionary architects and expert project managers.

                </p>
              </div>
            </div>
            <div className='col-lg-5 offset-lg-1 mobSpace_mb24'>
              <div className='servicesImages'>
                <img src={architecture_service} alt="architecture_service" />

              </div>

            </div>
          </div>
        </div>



        <div className='servicesItem'>
          <div className='row alignItem_center flexDirectionColumnReverse'>
            <div className='col-lg-6'>

              <div className='servicesContent'>
                {/* <h4>CATEGORY 2</h4> */}
                <h1>O2</h1>
                <h2>Construction</h2>
                <p className='syne_Font fontSize20 fontWeight400 SteelWoolClr_text'>
                  From initial site preparation and foundational work to crafting user-centric
                  interiors and optimizing lighting design, we meticulously handle every
                  aspect of construction to bring your dream space to life. We excel in
                  pre-construction planning, project scheduling, and post-construction finishes.

                </p>
              </div>
            </div>
            <div className='col-lg-5 offset-lg-1 mobSpace_mb24'>
              <div className='servicesImages'>
                <img src={construction_service} alt="construction_service" />

              </div>

            </div>
          </div>
        </div>





        <div className='servicesItem'>
          <div className='row alignItem_center flexDirectionColumnReverse'>
            <div className='col-lg-6 '>

              <div className='servicesContent'>
                {/* <h4>CATEGORY 3</h4> */}
                <h1>O3</h1>
                <h2>Project Management</h2>
                <p className='syne_Font fontSize20 fontWeight400 SteelWoolClr_text'>
                  Our project management team - Metamatrics excels in strategic planning,
                  resource allocation, and risk management, ensuring a seamless project journey.
                  We prioritize detail, proactive problem-solving, and collaboration, maintaining
                  quality and meeting deadlines with cutting-edge methods.

                </p>
              </div>
            </div>
            <div className='col-lg-5 offset-lg-1 mobSpace_mb24'>
              <div className='servicesImages'>
                <img src={projectmanage_service} alt="projectmanage_service" />

              </div>

            </div>
          </div>
        </div>



        <div className='servicesItem'>
          <div className='row alignItem_center flexDirectionColumnReverse'>
            <div className='col-lg-6 '>

              <div className='servicesContent'>
                {/* <h4>CATEGORY 4</h4> */}
                <h1>O4</h1>
                <h2>Interior Design</h2>
                <p className='syne_Font fontSize20 fontWeight400 SteelWoolClr_text'>
                  Pioneers in Corporate interiors, we set design benchmarks, merging
                  opulence with elegance for all your dream space. From Vibrant Residential
                  to Luxury Villa suites, our spaces harmonize technology and craftsmanship,
                  spanning diverse styles. Renowned for bespoke solutions, we consistently
                  surpass expectations with our attention to detail and innovative approach,
                  earning acclaim for unparalleled execution.

                </p>
              </div>
            </div>
            <div className='col-lg-5 offset-lg-1 mobSpace_mb24'>
              <div className='servicesImages'>
                <img src={interior_service} alt="interior_service" />

              </div>

            </div>
          </div>
        </div>


        <div className='servicesItem'>
          <div className='row alignItem_center flexDirectionColumnReverse'>
            <div className='col-lg-6 '>

              <div className='servicesContent'>
                {/* <h4>CATEGORY 5</h4> */}
                <h1>O5</h1>
                <h2>Residential</h2>
                <p className='syne_Font fontSize20 fontWeight400 SteelWoolClr_text'>Our expertise ranges from crafting tailor-made residences to pioneering modular
                  constructions. Through careful planning and execution, we shape eco-friendly, smart homes
                  that redefine contemporary living with harmony and sophistication. From single-family homes
                  and townhouses to condominiums and tiny homes, our projects showcase luxury, innovation,
                  and sustainability, elevating residential design to extraordinary heights with our bespoke
                  solutions.</p>
              </div>
            </div>
            <div className='col-lg-5 offset-lg-1 mobSpace_mb24'>
              <div className='servicesImages'>
                <img src={residential_service} alt="residential_service" />

              </div>

            </div>
          </div>
        </div>
        <div className='servicesItem'>
          <div className='row alignItem_center flexDirectionColumnReverse'>
            <div className='col-lg-6'>

              <div className='servicesContent'>
                {/* <h4>CATEGORY 6</h4> */}
                <h1>O6</h1>
                <h2>Urban Planning</h2>
                <p className='syne_Font fontSize20 fontWeight400 SteelWoolClr_text'>
                  We embrace a forward-thinking approach to urban planning, integrating
                  Smart-city infrastructure, Sustainable Transportation, and Energy-efficient
                  buildings to craft dynamic urban environments. Our inclusive design and
                  community-focused urbanism foster vibrant public spaces that encourage
                  interaction. Committed to urban resilience and Climate-adaptive design,
                  we ensure cities thrive despite environmental challenges.

                </p>
              </div>
            </div>
            <div className='col-lg-5 offset-lg-1 mobSpace_mb24'>
              <div className='servicesImages'>
                <img src={city_service} alt="city_service" />

              </div>

            </div>
          </div>
        </div>













        <div className='servicesItem'>
          <div className='row alignItem_center flexDirectionColumnReverse'>
            <div className='col-lg-6 '>

              <div className='servicesContent'>
                {/* <h4>CATEGORY 7</h4> */}
                <h1>O7</h1>
                <h2>Landscapes</h2>
                <p className='syne_Font fontSize20 fontWeight400 SteelWoolClr_text'>
                  We prioritize sustainable landscape design by integrating native plants,
                  water-saving techniques, and recycled materials. Focusing on ecological
                  restoration, we create visually appealing, low-maintenance landscapes that
                  foster biodiversity, natural balance, and a deep connection between people
                  and the environment, benefiting both communities and ecosystems.

                </p>
              </div>
            </div>
            <div className='col-lg-5 offset-lg-1 mobSpace_mb24'>
              <div className='servicesImages'>
                <img src={landscape_service} alt="landscape_service" />

              </div>

            </div>
          </div>
        </div>
        <div className='servicesItem'>
          <div className='row alignItem_center flexDirectionColumnReverse'>
            <div className='col-lg-6 '>

              <div className='servicesContent'>
                {/* <h4>CATEGORY 8</h4> */}
                <h1>O8</h1>
                <h2>Consulting</h2>
                <p className='syne_Font fontSize20 fontWeight400 SteelWoolClr_text'>
                  Our firm offers comprehensive Architectural consulting, specializing in
                  Design, Project Management, and Turnkey Executions. From ensuring
                  Building regulations to Conceptualization and Construction Documentation,
                  we handle all technical aspects. With expertise in Cost estimation, Site Analysis,
                  Zoning and more, we deliver innovative solutions for Prefabrication,
                  Urban Redevelopment and Green Building Consulting.

                </p>
              </div>
            </div>
            <div className='col-lg-5 offset-lg-1 mobSpace_mb24'>
              <div className='servicesImages'>
                <img src={consult_service} alt="consult_service" />

              </div>

            </div>
          </div>
        </div>


        <div className='servicesItem'>
          <div className='row alignItem_center flexDirectionColumnReverse'>
            <div className='col-lg-6 '>

              <div className='servicesContent'>
                {/* <h4>CATEGORY 9</h4> */}
                <h1>O9</h1>
                <h2>Exterior Designs</h2>
                <p className='syne_Font fontSize20 fontWeight400 SteelWoolClr_text'>
                  We specialize in crafting striking Building Exteriors, seamlessly merging
                  Aesthetics with Functionality. Our expertise spans Façade treatments,
                  Roofing and Structural Enhancements, prioritizing Sustainability
                  and Energy Efficiency. With a focus on durability and visual harmony,
                  our designs contribute to environmental sustainability and the timeless
                  appeal of the surrounding landscape.

                </p>
              </div>
            </div>
            <div className='col-lg-5 offset-lg-1 mobSpace_mb24'>
              <div className='servicesImages'>
                <img src={exterior_service} alt="exterior_service" />

              </div>

            </div>
          </div>
        </div>
      </div>
    </section>



    <section className='ourExpertise_sec'>
      <div className='container'>




        <div data-aos="fade-up" data-aos-duration="1200" className='ourExpertiseItems'>
          <div className='ourExpertCircleOne'>
            <img src={service3} alt='SketchupCircle' />
            {/* <p className='fontSize14 fontWeight400  syne_Font blackClr_text'>SKETCHUP</p> */}

          </div>
          <div className='ourExpertCircleTwo'>
            <img src={service1} alt='Autocad' />
            {/* <p className='fontSize14 fontWeight400  syne_Font blackClr_text'>AUTOCAD</p> */}
          </div>
          <div className='ourExpertCircleThree'>

            <img src={service2} alt='AutodeskCircle' />
            {/* <p className='fontSize14 fontWeight400  syne_Font blackClr_text'>AUTODESK</p> */}
          </div>

          {/* <div>

              <img src={DsMaxCircle} alt='DsMaxCircle ' />
            </div> */}
        </div>
        <div className='row justifyContent_center'>
          <div className='col-lg-7'>
            <h2 data-aos="fade-up" data-aos-duration="1500" className='textalign_center fontSize40 atypDisplay_Font fontWeight500 drWhiteClr_text mb_32'>Our Features</h2>
            <p data-aos="fade-up" data-aos-duration="1700" className='cerebralGreyClr_text fontSize24 syne_Font fontWeight400 textalign_center'>
              We leverage advanced design tools, crafting precise 3D models and parametric designs, ensuring every project embodies creativity and excellence, redefining architectural innovation.
            </p>
          </div>
        </div>



        <div data-aos="fade-up" data-aos-duration="1800" className='ourExpertiseItems'>

          <div className='ourExpertCircleFour'>
            <img src={service4} alt='Autocad' />
            {/* <p className='fontSize14 fontWeight400  syne_Font blackClr_text'>AUTOCAD</p> */}
          </div>
          <div className='ourExpertCircleFive'>

            <img src={service5} alt='AutodeskCircle' />
            {/* <p className='fontSize14 fontWeight400  syne_Font blackClr_text'>AUTODESK</p> */}
          </div>

          <div className='ourExpertCircleSix'>

            <img src={service6} alt='DsMaxCircle ' />
            {/* <p className='fontSize14 fontWeight400  syne_Font blackClr_text'>3DS MAX</p> */}
          </div>
        </div>

      </div>
    </section>



    <section className='ourProcess_sec'>
      <div className="container">
        <div className='sectionTitle text-2'>
          <h2 className="fontWeight600 atypDisplay_Font h2-1  textalign_center">OUR </h2>
          <h2 className="fontWeight600 atypDisplay_Font  h2-2  mb_34 textalign_center">PROCESS </h2>

          <div className='row mb_40 justifyContent_center' data-aos="fade-up" data-aos-duration="2200" data-aos-delay="900" data-aos-easing="ease">
            <div className='col-lg-8'>
              <p className="SteelWoolClr_text fontSize24 fontWeight400 syne_Font textalign_center fadeUp">
                At Metaskapes, we prioritize your vision, crafting bespoke designs that foster emotional
                connections. We celebrate realizing your dream space, ready for you to inhabit and enjoy for
                years to come. Committed to excellence, we guide you through each stage of the
                architectural journey, delivering inspiring and enduring spaces.

              </p>
            </div>
          </div>

        </div>
      </div>


      <div data-aos="fade-up" data-aos-duration="2400" className='ourProcess_slider'>
        <Slider ref={sliderRef} {...ourProcess}>
          <div className='ourProcessItem op_first'>
            <div>
              <h5>1.</h5>
              <h4>Discovery</h4>
            </div>
            <div>

              <p className='syne_Font SteelWoolClr_text fontSize20 fontWeight400'>
                Our process begins with comprehensive discovery, delving into your aspirations,
                preferences, and project challenges through discussions, site visits, and analysis.
              </p>

            </div>

          </div>

          <div className='ourProcessItem op_second'>
            <div>
              <h5>2.</h5>
              <h4>Conceptualization</h4>
            </div>

            <div>
              <p className='syne_Font SteelWoolClr_text fontSize20 fontWeight400'>We then translate this insight into innovative design concepts, presenting a range of ideas
                via sketches, mood boards, and 3D renderings.</p>

            </div>
          </div>
          <div className='ourProcessItem op_third'>
            <div>
              <h5>3.</h5>
              <h4>Defining Goals</h4>
            </div>
            <div>
              <p className='syne_Font SteelWoolClr_text fontSize20 fontWeight400'>Upon selecting a preferred concept, we refine it further, integrating client feedback to ensure
                goal alignment. Detailed architectural drawings, material selections, and feasibility studies
                mark this refinement stage.</p>
            </div>
          </div>
          <div className='ourProcessItem op_fourth'>
            <div>
              <h5>4.</h5>
              <h4>Execution & Delivery</h4>
            </div>

            <div>
              <p className='syne_Font SteelWoolClr_text fontSize20 fontWeight400'>Metaskapes collaborates with
                contractors, engineers, and
                craftsmen, overseeing construction
                to ensure quality and timelines.
                Thorough inspections ensure every
                detail meets exacting standards.</p>
            </div>
          </div>
        </Slider>
      </div>

    </section>

    <section data-aos="fade-up"
      data-aos-duration="3000"
      data-aos-delay="900" className="WantToWork_Sec">
      <div className='circleAnimationLinerOne'></div>
      <div className='circleAnimationLinerTwo'></div>

      <div className="container-fluid position_relative">
        <div className='circleAnimationLinerThree'></div>
        <div className='circleAnimationLinerfour'></div>
        {/* <h1>Want to work <br /> with us?</h1> */}
        <h1>Let’s Transform <br className='MobBr_hide' />
          your Space
        </h1>
        <div className="displayFlex justifyContent_center">
          <div className='zIndex999 position_relative'><button className="lightGreenEffectBtn" onClick={() => navigate(`/contact-us`)}>Connect with Us</button></div>
        </div>
      </div>
    </section>
    {/* <section className='contactus_marquee'>
        <div className='container-fluid'>
          <div className="main-marq">
            <div className="slide-har st1">
              <div className="box">
                <div className="item">
                  <h3>CONTACT <span>US</span></h3>
                </div>
                <div className="item">
                  <h3>CONTACT <span>US</span></h3>
                </div>
                <div className="item">
                  <h3>CONTACT <span>US</span></h3>
                </div>
                <div className="item">
                  <h3>CONTACT <span>US</span></h3>
                </div>
                <div className="item">
                  <h3>CONTACT <span>US</span></h3>
                </div>
                <div className="item">
                  <h3>CONTACT <span>US</span></h3>
                </div>
                <div className="item">
                  <h3>CONTACT <span>US</span></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
  </>
)
}

export default Services
