import React, { useEffect } from 'react'
import { Userlogin } from '../storage/LoginStorage'
import { Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import senderRequest from '../service/Http'

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate()
  const UserInfo = Userlogin(state => state?.login)

  // useEffect(() => {
  //   handleJwtVerify()
  // }, [])

  // const handleJwtVerify = async () => {
  //   try {
  //     const response = await senderRequest(
  //       'post',
  //       'api/auth/jwt-verify',
  //       '',
  //       UserInfo?.token,
  //       {}
  //     )
  //     console.log(response, 'response')
  //     debugger
  //     if (response?.statusCode === 200) {
  //       return <Outlet />
  //     } else {
  //       console.log(response, 'response')
  //       window.location.href = '/admin/login'
  //     }
  //   } catch (error) {}
  // }
  console.log(UserInfo, 'userinfo')

  if (UserInfo?.token?.length > 0) {
    return children
  } else {
    window.location.href = '/admin/login'
    return null
    // navigate('/admin/login')
  }
}

export default ProtectedRoute
