import React, { useEffect, useState } from "react";
import PerfecientImg from "../../../assets/images/Perfecient_img.png";
import PerfecientIcon from "../../../assets/images/Perfecient_icon.svg";

import VillaImg from "../../../assets/images/Villa_img.jpg";
import VillaIcon from "../../../assets/images/Villa_icon.svg";
import CadburyImg from "../../../assets/images/cadbury_img.jpg";
import CadburyIcon from "../../../assets/images/cadbury_icon.svg";
import ClarianttwoImg from "../../../assets/images/clariant_two_img.jpg";
import ClarianttwoIcon from "../../../assets/images/clariant_two_icon.svg";
import ClariantImg from "../../../assets/images/clariant_img.jpg";
import ClariantIcon from "../../../assets/images/clariant_icon.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import senderRequest from "../../../service/Http";
import { Link, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Loader from "./../../../components/Loader";

const Projects = () => {
  const [category, setCategory] = useState("all");
  const [projects, setProjects] = useState([]);
  const [dummyProjectSkeleton, setDummyProjectSkeleton] = useState([
    1, 2, 3, 4,
  ]);

  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    handleProjects();
  }, [category]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const url = new URL(window.location);
    const categoryFromUrl = url.searchParams.get("category");
    if (categoryFromUrl) {
      setCategory(
        categoryFromUrl === "Villas" ? "Villas & Resorts" : categoryFromUrl
      );
    }
  }, [location]);

  const handleTabClick = (categoryName, event) => {
    event.preventDefault();
    setCategory(categoryName);
    const url = new URL(window.location);
    url.searchParams.set("category", categoryName);
    window.history.pushState({}, "", url);
  };

  const handleProjects = async () => {
    setLoading(true);
    try {
      const response = await senderRequest(
        "get",
        category === "all"
          ? `api/project/get-all`
          : `api/project/get-project/${category}`,
        "",
        "",
        {}
      );
      if (response.data.statusCode === 200) {
        setProjects(response?.data?.data);
        setLoading(false);
      } else {
        setProjects([]);
        setLoading(false);
      }
    } catch (error) {
      setProjects([]);
      setLoading(false);
    }
  };

  return (
    <>
      <section className="commonPage_bnr projects_bnr">
        <div className="container">
          <div className="row alignItem_center">
            <div
              data-aos="fade-down-right"
              data-aos-duration="1500"
              className="col-lg-6 mobSpace_mb24"
            >
              <p className="fontSize24 fontWeight400 cerebralGreyClr_text syne_Font">
                Discover our innovative, functional, and stylish designs that
                transform spaces into inspiring environments, reshaping
                landmarks.
              </p>
            </div>
            <div
              data-aos="fade-down-left"
              data-aos-duration="1500"
              className="col-lg-6"
            >
              <div className="sectionTitle">
                <h2 className="atypDisplay_Font textalign_right">PROJECTS</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-11">
              <div
                data-aos="fade-up"
                data-aos-duration="1800"
                className="projectsTab"
              >
                <Tabs
                  activeKey={`${category}`}
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={(k, e) => handleTabClick(k, e)}
                >
                  <Tab eventKey="all" title="All"></Tab>
                  <Tab eventKey="Apartments" title="Apartments"></Tab>
                  <Tab eventKey="Landscape" title="Landscape"></Tab>
                  <Tab
                    eventKey="Villas & Resorts"
                    title="Villas & Resorts"
                  ></Tab>
                  <Tab eventKey="Residence" title="Residence"></Tab>
                  <Tab
                    eventKey="Commercial Complexes"
                    title="Commercial Complexes"
                  ></Tab>
                  <Tab eventKey="Hotels" title="Hotels"></Tab>
                  <Tab
                    eventKey="Corporate Office Interiors"
                    title="Corporate Office Interiors"
                  ></Tab>
                  <Tab eventKey="Bus Terminals" title="Bus Terminals"></Tab>
                </Tabs>
              </div>
            </div>
          </div>

          {projects.length > 0
            ? projects.map((project, index) => {
                const mappedItem = (index + 1) % 5 === 0 ? 5 : (index + 1) % 5;
                return (
                  <div key={index}>
                    {mappedItem === 1 && (
                      <div className={"projectsFullviewleft"}>
                        <div className="row">
                          <Link to={`/projects/${project?.project_id}`}>
                            <div
                              data-aos="fade-right"
                              data-aos-duration="2000"
                              className="col-lg-10"
                            >
                              <div className="mb_20">
                                <div className="projectImg">
                                  <img
                                    src={`${project.cover_image_url}`}
                                    alt="PerfecientImg"
                                    className="width100per borderRadius30"
                                  />
                                </div>
                              </div>
                              <div className="projectTitlePart">
                                <div className="project_icon">
                                  <img
                                    src={`${project.icon_url}`}
                                    alt="project logo"
                                  />
                                </div>
                                <div>
                                  <h5 className="fontSize48 syne_Font drWhiteClr_text fontWeight400">
                                    {project.name},{" "}
                                    <span className="fontSize24">
                                      {project.location}
                                    </span>
                                  </h5>
                                  <p className="fontSize16 syne_Font limeMistClr_text fontWeight400 textdecoration_underline">
                                    {project.category}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    )}
                    {mappedItem === 2 && (
                      <div className="projectsMediumviewRight">
                        <Link to={`/projects/${project?.project_id}`}>
                          <div className="row">
                            <div
                              data-aos="fade-left"
                              className="col-lg-7 offset-lg-4"
                            >
                              <div className="mb_20 projectImg">
                                <img
                                  src={`${project.cover_image_url}`}
                                  alt="project cover"
                                  className="width100per borderRadius30"
                                />
                              </div>
                              <div className="projectTitlePart">
                                <div className="project_icon">
                                  <img
                                    src={`${project.icon_url}`}
                                    alt="project icon"
                                  />
                                </div>
                                <div>
                                  <h5 className="fontSize48 syne_Font drWhiteClr_text fontWeight400">
                                    {project.name},{" "}
                                    <span className="fontSize24">
                                      {project.location}
                                    </span>
                                  </h5>
                                  <p className="fontSize16 syne_Font limeMistClr_text fontWeight400 textdecoration_underline">
                                    {project.category}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )}
                    {mappedItem === 3 && (
                      <div className="projectsFullviewRight ">
                        <Link to={`/projects/${project?.project_id}`}>
                          <div className="row justifyContent_end">
                            <div data-aos="fade-right" className="col-lg-10">
                              <div className="mb_20 projectImg">
                                <img
                                  src={`${project.cover_image_url}`}
                                  alt="CadburyImg"
                                  className="width100per borderRadius30"
                                />
                              </div>
                              <div className="projectTitlePart">
                                <div className="project_icon">
                                  <img
                                    src={`${project.icon_url}`}
                                    alt="CadburyIcon"
                                  />
                                </div>
                                <div>
                                  <h5 className="fontSize48 syne_Font drWhiteClr_text fontWeight400">
                                    {project.name},{" "}
                                    <span className="fontSize24">
                                      {project.location}
                                    </span>
                                  </h5>
                                  <p className="fontSize16 syne_Font limeMistClr_text fontWeight400 textdecoration_underline">
                                    {project.category}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )}
                    {mappedItem === 4 && (
                      <div className="projectsSmallview_spacebetween ">
                        <div className="row alignItem_center">
                         
                            <div
                              data-aos="fade-right"
                              className="col-lg-7 offset-lg-2 mobSpace_mb34"
                            >
                            <Link to={`/projects/${project?.project_id}`}>
                              <div className="mb_20 projectImg">
                                <img
                                  src={`${project.cover_image_url}`}
                                  alt="ClarianttwoImg"
                                  className="width100per borderRadius30"
                                />
                              </div>
                              <div className="projectTitlePart">
                                <div className="project_icon">
                                  <img
                                    src={`${project.icon_url}`}
                                    alt="ClarianttwoIcon"
                                  />
                                </div>
                                <div>
                                  <h5 className="fontSize48 syne_Font drWhiteClr_text fontWeight400">
                                    {project.name},{" "}
                                    <span className="fontSize24">
                                      {project.location}
                                    </span>
                                  </h5>
                                  <p className="fontSize16 syne_Font limeMistClr_text fontWeight400 textdecoration_underline">
                                    {project.category}
                                  </p>
                                </div>
                              </div>
                            </Link>
                            </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            : loading && dummyProjectSkeleton.map((project, index) => {
                const mappedItem = (index + 1) % 5 === 0 ? 5 : (index + 1) % 5;
                return (
                  <div key={index}>
                    {mappedItem === 1 && (
                      <div className={"projectsFullviewleft"}>
                        <div className="row">
                          <div
                            data-aos="fade-right"
                            data-aos-duration="2000"
                            className="col-lg-10"
                          >
                            <div className="mb_20">
                              <div className="">
                                <Skeleton className="projectImg_skeleton" />
                              </div>
                            </div>
                            <div className="displayFlex alignItem_center gap30">
                              <div>
                                <>
                                  <Skeleton />
                                </>
                              </div>
                              <div>
                                <h5 className="fontSize48 syne_Font drWhiteClr_text fontWeight400">
                                  <>
                                    <Skeleton width="70px" />
                                  </>
                                  <span className="fontSize24">
                                    <>
                                      <Skeleton width="30px" />
                                    </>
                                  </span>
                                </h5>
                                <p className="fontSize16 syne_Font limeMistClr_text fontWeight400 textdecoration_underline">
                                  <>
                                    <Skeleton width="100px" />
                                  </>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {mappedItem === 2 && (
                      <div className="projectsMediumviewRight">
                        <div className="row">
                          <div
                            data-aos="fade-left"
                            className="col-lg-7 offset-lg-4"
                          >
                            <div className="mb_20 ">
                              <Skeleton className="projectImg_skeleton" />
                            </div>
                            <div className="displayFlex alignItem_center gap30">
                              <div>
                                <>
                                  <Skeleton />
                                </>
                              </div>
                              <div>
                                <h5 className="fontSize48 syne_Font drWhiteClr_text fontWeight400">
                                  <>
                                    <Skeleton width="100px" />
                                  </>
                                  <span className="fontSize24">
                                    <>
                                      <Skeleton width="30px" />
                                    </>
                                  </span>
                                </h5>
                                <p className="fontSize16 syne_Font limeMistClr_text fontWeight400 textdecoration_underline">
                                  <>
                                    <Skeleton width="100px" />
                                  </>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {mappedItem === 3 && (
                      <div className="projectsFullviewRight ">
                        <div className="row justifyContent_end">
                          <div data-aos="fade-right" className="col-lg-10">
                            <div className="mb_20 ">
                              <Skeleton className="projectImg_skeleton" />
                            </div>
                            <div className="displayFlex alignItem_center gap30">
                              <div>
                                <>
                                  <Skeleton />
                                </>
                              </div>
                              <div>
                                <h5 className="fontSize48 syne_Font drWhiteClr_text fontWeight400">
                                  <>
                                    <Skeleton width="100px" />
                                  </>
                                  <span className="fontSize24">
                                    <>
                                      <Skeleton width="30px" />
                                    </>
                                  </span>
                                </h5>
                                <p className="fontSize16 syne_Font limeMistClr_text fontWeight400 textdecoration_underline">
                                  <>
                                    <Skeleton width="100px" />
                                  </>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {mappedItem === 4 && (
                      <div className="projectsSmallview_spacebetween ">
                        <div className="row alignItem_center">
                          <div
                            data-aos="fade-right"
                            className="col-lg-4 offset-lg-2 mobSpace_mb34"
                          >
                            <div className="mb_20 ">
                              <Skeleton className="projectImg_skeleton" />
                            </div>
                            <div className="displayFlex alignItem_center gap30">
                              <div>
                                <>
                                  <Skeleton />
                                </>
                              </div>
                              <div>
                                <h5 className="fontSize48 syne_Font drWhiteClr_text fontWeight400">
                                  <>
                                    <Skeleton width="100px" />
                                  </>
                                  <span className="fontSize24">
                                    <>
                                      <Skeleton width="30px" />
                                    </>
                                  </span>
                                </h5>
                                <p className="fontSize16 syne_Font limeMistClr_text fontWeight400 textdecoration_underline">
                                  <>
                                    <Skeleton width="100px" />
                                  </>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
        </div>
      </section>

      {/* <section className="contactus_marquee">
        <div className="container-fluid">
          <div className="main-marq">
            <div className="slide-har st1">
              <div className="box">
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Projects;
