import React from 'react'
// import Sidebar from './Sidebar'
import { Outlet } from 'react-router-dom'
import Sidebar from '../../Layout/Sidebar'
import Header from '../../Layout/Header'

const Admin = () => {
  return (
    <div className='page_wrapper overflow-x-scroll h-full'>
      <Sidebar />
      <div className='content_wrapper'>
        <Header />
        <div className=' container MainContent px-6 py-8  mx-auto'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Admin
