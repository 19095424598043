import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
// import { Provider } from 'react-redux';
// import store from './store'; // Import your Redux store
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
