import React, { useState } from "react";
import { handleInputValidation } from "../../../helper/validator";
import senderRequest from "../../../service/Http";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AddTestimonials = () => {
  const [name, setName] = useState("");
  const [feedback, setFeedback] = useState("");

  const navigate = useNavigate();

  const handleAddTestimonial = async (e) => {
    e.preventDefault();
    try {
      let body = {
        name: name,
        feedback: feedback,
      };
      const response = await senderRequest(
        "post",
        `api/testimonial/add`,
        "",
        "",
        body
      );
      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
        navigate("/admin/testimonials");
        setFeedback("");
        setName("");
      }
    } catch (error) {
      setFeedback("");
      setName("");
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSaveClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await handleAddTestimonial(e);
      // Optional: show a success message or handle success logic
    } catch (error) {
      // Handle the error, e.g., display an error message
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h1 className="text-2xl font-medium mb-3">AddTestimonials</h1>
      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Name
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="Project Name"
                value={name}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setName, 1)
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="InputFrame mb_24">
              <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                Feedback
                <span className="blooddonorClr_text fontSize16 ml_4">* </span>
              </label>
              <input
                type="text"
                placeholder="feedback"
                value={feedback}
                onChange={(e) =>
                  handleInputValidation(e.target.value, setFeedback, 7)
                }
                name="Experience"
              />
            </div>
          </div>
        </div>

        <button
          className={`MainButton greenGoneWild_btnclr mt-5 ${isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          onClick={!isLoading ? handleSaveClick : undefined}
          disabled={isLoading} // To ensure the button is not clickable during loading
        >
          {isLoading ? (
            <>
              <svg
                className="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                ></path>
              </svg>
              Uploading...
            </>
          ) : (
            "Save"
          )}
        </button>
      </form>
    </div >
  );
};

export default AddTestimonials;
