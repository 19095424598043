import React, { useState, useEffect } from "react";
import RightArrowIcon from "../assets/images/rightArrow.svg";
import InstagramLogo from "../assets/images/instagram_logo.svg";
import XLogo from "../assets/images/x_logo.svg";
import linkedinIcon from "../assets/images/linkedin_icon.svg";
import MetaskapeswhiteLogo from "../assets/images/metaskapelogo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import WhatsappLogo from "../assets/images/whatsapp_icon.svg";
import FacebookLogo from "../assets/images/facebook_icon.svg";
import TopScrollIcon from "../assets/images/topScroll_icon.svg";
const Footer = () => {
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);
  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleMenuClick = (path) => {
    navigate(path);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <>
      {/* <footer>
        <div className='container'>


          <div className='verifiedBlackBottomborder'></div>
          <div className='desktopFlex_mobileBlock  justifyContent_spacebetween pblock20'>
            <div className='mobSpace_mb24'>
              <Link to="tel:+91-9884036011" className='fontSize32  fontWeight400 drWhiteClr_text atypDisplay_Font'>+91-9884036011</Link>
            </div>
            <div className=''>
              <Link to='mailto:ganesh@metaskapes.com' className='mailtobtn mb_24'>
                <img src={RightArrowIcon} alt='RightArrowIcon' />
                <span><span className='limeMistClr_text'>ganesh</span>@metaskapes.com</span>

              </Link>


              <Link target='_blank' to="https://maps.app.goo.gl/XG6cuEb2456GJke3A
" className='emptyBtn atypDisplay_Font fontSize32 fontWeight400 limeMistClr_text textalign_right display_block'>
                Locate us
              </Link>
            </div>
          </div>
          <div className='verifiedBlackBottomborder'></div>




          <div className='desktopFlex_mobileBlock  justifyContent_spacebetween pblock20'>
            <div className='mobSpace_mb24'>
              <Link to="https://www.instagram.com/metaskapes/" target='_blank' className='InstaLink'>

                <span>Instagram</span>
                <img src={InstagramLogo} alt='InstagramLogo' />
              </Link>

            </div>
            <div className=''>
              <Link href="#" className='whatsappLink'>

                <span>Whatsapp</span>
                <img src={WhatsappLogo} alt='WhatsappLogo' />
              </Link>



            </div>
          </div>

          <div className='verifiedBlackBottomborder'></div>



          <div className='desktopFlex_mobileBlock  justifyContent_spacebetween pblock20'>
            <div className='mobSpace_mb24'>
              <Link target='_blank' to="https://www.linkedin.com/company/metaskapes/about/" className='TwitterWidth_243px LinkedInLink'>

                <span>LinkedIn</span>
                <img src={linkedinIcon} alt='linkedinIcon' />
              </Link>
            </div>
            <div className=''>

              <Link className='fbLink fontSize32  fontWeight500 drWhiteClr_text syne_Font displayFlex emptyBtn alignItem_center gap30 MobSpaceBetween' target='_blank' to="https://www.facebook.com/people/Metaskapes-Chennai/100083225022674/">
                <span>Facebook</span>
                <img src={FacebookLogo} alt='FacebookLogo' />
              </Link>



            </div>
          </div>

        </div>
      </footer> */}

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mobSpace_mb24">
              <div className="footerLogo">
                <div
                  onClick={() => {

                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <a href="/">
                    <img src={MetaskapeswhiteLogo} alt="MetaskapeswhiteLogo" />
                  </a>
                </div>
              </div>
              <p className="cerebralGreyClr_text fontSize20 fontWeight400 atypDisplay_Font mb_34">
                Your Dream space, Our Design expertise.{" "}
                <br className="MobBr_hide" />
                Let's create Stunning and Functional{" "}
                <br className="MobBr_hide" />
                environments together. Contact us <br className="MobBr_hide" />
                to Begin your Transformation.
              </p>
              <div className="socialMediaLinks">
                <ul>
                  <li>
                    <Link
                      className="MobSpaceBetween"
                      target="_blank"
                      to="https://www.facebook.com/people/Metaskapes-Chennai/100083225022674/"
                    >
                      <img src={FacebookLogo} alt="FacebookLogo" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/metaskapes/"
                      target="_blank"
                    >
                      <img src={InstagramLogo} alt="InstagramLogo" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      to="https://www.linkedin.com/company/metaskapes/about/"
                      className="TwitterWidth_243px"
                    >
                      <img src={linkedinIcon} alt="linkedinIcon" />
                    </Link>
                  </li>

                  <li>
                    <Link
                      // to="whatsapp://send?abid=+919043099772&text=Hello%2C%20World!"
                      to="https://wa.me/+919043099772"
                      target="_blank"
                    >
                      <img src={WhatsappLogo} alt="WhatsappLogo" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 offset-lg-1 mobSpace_mb24">
              <div className="quickLink">
                <ul>
                  <li>
                    <a href="/about-us">
                      <button
                      // onClick={() => {
                      //   handleMenuClick("/about-us");
                      //   window.scrollTo({ top: 0, behavior: "smooth" });
                      // }}
                      >
                        <img src={RightArrowIcon} alt="RightArrowIcon" />
                        <span>About</span>
                      </button>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <button
                      // onClick={() => {
                      //   handleMenuClick("/services");
                      //   window.scrollTo({ top: 0, behavior: "smooth" });
                      // }}
                      >
                        <img src={RightArrowIcon} alt="RightArrowIcon" />
                        <span>Services</span>
                      </button>
                    </a>
                  </li>
                  <li>
                    <a href="/projects">
                      <button
                      // onClick={() => {
                      //   handleMenuClick("/projects");
                      //   window.scrollTo({ top: 0, behavior: "smooth" });
                      // }}
                      >
                        <img src={RightArrowIcon} alt="RightArrowIcon" />
                        <span>Projects</span>
                      </button>
                    </a>
                  </li>
                  <li>
                    <a href="/thoughts">
                      <button
                      // onClick={() => {
                      //   handleMenuClick("/thoughts");
                      //   window.scrollTo({ top: 0, behavior: "smooth" });
                      // }}
                      >
                        <img src={RightArrowIcon} alt="RightArrowIcon" />
                        <span>Thoughts</span>
                      </button>
                    </a>
                  </li>
                  <li>
                    <a href="/careers" >
                      <button
                      // onClick={() => {
                      //   handleMenuClick("/careers");
                      //   window.scrollTo({ top: 0, behavior: "smooth" });
                      // }}
                      >
                        <img src={RightArrowIcon} alt="RightArrowIcon" />
                        <span>Career</span>
                      </button>
                    </a>
                  </li>

                  <li>
                    <a href="/contact-us" >
                      <button
                      // onClick={() => {
                      //   handleMenuClick("/contact-us");
                      //   window.scrollTo({ top: 0, behavior: "smooth" });
                      // }}
                      >
                        <img src={RightArrowIcon} alt="RightArrowIcon" />
                        <span>Let's Connect</span>
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 mobSpace_mb24">
              <div className="footerProjectFrame">
                <ul className="mb_24">
                  <li>
                    <button
                      onClick={() => {
                        handleMenuClick(`/projects?category=Residence`);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <span>Residence</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        handleMenuClick(`/projects?category=Villas`);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <span>Villas & Resorts</span>
                    </button>
                  </li>

                  <li>
                    <button
                      onClick={() => {
                        handleMenuClick(
                          `/projects?category=Corporate Office Interiors`
                        );
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <span>Corporate Office</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        handleMenuClick(`/projects?category=Landscape`);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <span>Landscape</span>
                    </button>
                  </li>
                  {/* <li>
                    <button
                      onClick={() =>
                        handleMenuClick(
                          `/projects?type=buildingAndExterior_tab`
                        )
                      }
                    >
                      <span>Building Exteriors</span>
                    </button>
                  </li> */}
                </ul>

                <a href="/projects" rel="noopener noreferrer">
                  <button
                    className="smallbutton"
                  // onClick={() => handleMenuClick(`/projects?category=all_tab`)}
                  >
                    Explore more
                  </button>
                </a>
              </div>
            </div>
            <div className="col-lg-3 pleftZero">
              {/* <h2 className='fontSize32 fontWeight500 atypDisplay_Font blankaGreenClr_text mb_24'> OFFICE ADDRESS</h2> */}
              <p className="cerebralGreyClr_text fontSize20 fontWeight400 atypDisplay_Font mb_12">
                {" "}
                Metaskapes Architects,
                <br className="MobBr_hide" /> 2nd Floor, No.5 First Main Road,
                <br className="MobBr_hide" />
                Kamaraj Nagar West, Thiruvanmiyur,
                <br className="MobBr_hide" /> Chennai - 600041
              </p>
              <div className="mb_12">
                <Link
                  to="tel:+914449525655"
                  className="hrefHover fontSize20 fontWeight400 cerebralGreyClr_text atypDisplay_Font"
                >
                  +91 44 4952 5655
                </Link>
              </div>
              <div>
                <Link
                  target="_blank"
                  to="https://maps.app.goo.gl/XG6cuEb2456GJke3A
"
                  className="hrefHover emptyBtn atypDisplay_Font fontSize20  fontWeight400 cerebralGreyClr_text display_block"
                >
                  Locate us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* <div className="copyRightSec">
        <div className="container">
          <div className="desktopFlex_mobileBlock alignItem_center justifyContent_spacebetween">
            <div className="mobSpace_mb16">
              <h5 className="SteelWoolClr_text fontSize16 fontWeight400 syne_Font">
                ©copyright2024. All rights reserved.
              </h5>
            </div>
            <div>
              <ul className="desktopFlex_mobileBlock alignItem_center gap30 emptyList policyCondition">
                <li>
                  <button className="emptyBtn SteelWoolClr_text fontSize16 fontWeight400 syne_Font">
                    Refund policy
                  </button>
                </li>
                <li>
                  <button className="emptyBtn SteelWoolClr_text fontSize16 fontWeight400 syne_Font">
                    Privacy policy
                  </button>
                </li>
                <li>
                  <button className="emptyBtn SteelWoolClr_text fontSize16 fontWeight400 syne_Font">
                    Terms & Conditions
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
      <div className="backtoTop">
        {isVisible && (
          <button className="topscrollBtn " onClick={scrollToTop}>
            <img src={TopScrollIcon} alt="TopScrollIcon" />
            <span>BACK TO TOP</span>
          </button>
        )}
      </div>
    </>
  );
};

export default Footer;
