import React, { useState, useRef, useEffect } from "react";
// import gsap from "gsap";
import Accordion from 'react-bootstrap/Accordion';

import HalfellipseIcon from "../../assets/images/halfellipse.svg";
import TopArrowIcon from "../../assets/images/topArrow_icon.svg";
import BottomArrowIcon from "../../assets/images/bottomArrow_icon.svg";
import cn from "classnames";
import Slider from "react-slick";
import likeRadioBtn from "../../assets/images/like_radiobtn.svg";
import jasonProfile from "../../assets/images/g_team.png";
import JohnProfile from "../../assets/images/j_team.png";
import AndreanneProfile from "../../assets/images/a_team.png";
import JohnPaulProfile from "../../assets/images/b_team.png";
import JuliaProfile from "../../assets/images/a_team.png";
import VillaImg from "../../assets/images/Villa_img.jpg";
import Marquee from "react-fast-marquee";

import CommaIcon from "../../assets/images/mdi_comma.svg";
import RightArrowIcon from "../../assets/images/rightArrow.svg";
import Tempdb from "../../assets/images/temp_db.png";
import Reguslogo from "../../assets/images/regus_logo.png";
import Metrohmlogo from "../../assets/images/metrohm_logo.svg";

import GlUpdatethree from "../../assets/images/glUpdate_3.jpg";

import Cl1 from "../../assets/images/client_logo/cl_1.png";
import Cl2 from "../../assets/images/client_logo/cl_2.png";
import Cl3 from "../../assets/images/client_logo/cl_3.png";
import Cl4 from "../../assets/images/client_logo/cl_4.png";
import Cl5 from "../../assets/images/client_logo/cl_5.png";
import Cl6 from "../../assets/images/client_logo/cl_6.png";
import Cl7 from "../../assets/images/client_logo/cl_7.png";
import Cl8 from "../../assets/images/client_logo/cl_8.png";
import Cl9 from "../../assets/images/client_logo/cl_9.png";
import Cl10 from "../../assets/images/client_logo/cl_10.png";
import Cl11 from "../../assets/images/client_logo/cl_11.png";
import Cl12 from "../../assets/images/client_logo/cl_12.png";
import Cl13 from "../../assets/images/client_logo/cl_13.png";
import Cl14 from "../../assets/images/client_logo/cl_14.png";
import Cl15 from "../../assets/images/client_logo/cl_15.png";
import Cl16 from "../../assets/images/client_logo/cl_16.png";
import Cl17 from "../../assets/images/client_logo/cl_17.png";
import Cl18 from "../../assets/images/client_logo/cl_18.png";
import Cl19 from "../../assets/images/client_logo/cl_19.png";
import Cl20 from "../../assets/images/client_logo/cl_20.png";
import Cl21 from "../../assets/images/client_logo/cl_21.png";
import Cl22 from "../../assets/images/client_logo/cl_22.png";
import Cl23 from "../../assets/images/client_logo/cl_23.png";
import Cl24 from "../../assets/images/client_logo/cl_24.png";
import Cl25 from "../../assets/images/client_logo/cl_25.png";
import Cl26 from "../../assets/images/client_logo/cl_26.png";
import Cl27 from "../../assets/images/client_logo/cl_27.png";
import Cl28 from "../../assets/images/client_logo/cl_28.png";
import Cl29 from "../../assets/images/client_logo/cl_29.png";
import Cl30 from "../../assets/images/client_logo/cl_30.png";
import Cl31 from "../../assets/images/client_logo/cl_31.png";
import Cl32 from "../../assets/images/client_logo/cl_32.png";
import Cl33 from "../../assets/images/client_logo/cl_33.png";
import Cl34 from "../../assets/images/client_logo/cl_34.png";
import Cl35 from "../../assets/images/client_logo/cl_35.png";
import Cl36 from "../../assets/images/client_logo/cl_36.png";
import Cl37 from "../../assets/images/client_logo/cl_37.png";
import Cl38 from "../../assets/images/client_logo/cl_38.png";
import Cl39 from "../../assets/images/client_logo/cl_39.png";
import Cl40 from "../../assets/images/client_logo/cl_40.png";
import Cl42 from "../../assets/images/client_logo/cl_42.png";
import Cl43 from "../../assets/images/client_logo/cl_43.png";
import Cl44 from "../../assets/images/client_logo/cl_44.png";
import Cl45 from "../../assets/images/client_logo/cl_45.png";
import Cl46 from "../../assets/images/client_logo/cl_46.png";
import Cl47 from "../../assets/images/client_logo/cl_47.png";










import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
import { useGSAP } from "@gsap/react";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { useNavigate } from "react-router-dom";
import senderRequest from "../../service/Http";
import Testimonials from "../../components/Testimonials";
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
const AboutUs = () => {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const myElementRef = useRef(null);

  const [shouldReload, setShouldReload] = useState(false);

  const [testimonials, setTestimonials] = useState([]);
  const marqueeRef = useRef(null);
  const sectionRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    handleTestimonials();

  }, []);


  // useEffect(() => {
  console.log("ANIMATION SSSS")
  const textH9 = document.querySelectorAll(".h2-9");
  if (textH9.length) {
    gsap.fromTo(
      ".h2-9",
      {
        x: -100,
        opacity: 1,
        backgroundPositionX: "100%",
        scrollTrigger: {
          trigger: ".h2-9",
          scrub: 1,
          start: "top 50%",
          end: "bottom 20%",
        },
      },
      {
        backgroundPositionX: "0%",
        stagger: 1,
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: ".h2-9",
          scrub: 1,
          start: "top 50%",
          end: "bottom 20%",
        },
      },
      {}
    );
  }

  // }, [marqueeRef.current])





  const handleTestimonials = async () => {
    try {
      const res = await senderRequest("get", `api/testimonial/get-all`, "", "");
      if (res.data.statusCode === 200) {
        setTestimonials(res?.data?.data);
      } else {
        setTestimonials([]);
      }
    } catch (error) {
      console.log(error);
      setTestimonials([]);
    }
  };
  const handleNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  useGSAP(async () => {
    const mm = gsap.matchMedia();
    gsap.set(".swipeimage", { yPercent: -50, xPercent: -50, opacity: 0 });


    console.log("ANIMATION FILE TRIGGERED - SERVICE FILE");



    const leftToRight = document.querySelectorAll(".leftToRight");
    const rightToLeft = document.querySelectorAll(".rightToLeft");

    const textH2 = document.querySelectorAll(".h2-1");
    if (textH2) {
      console.log("hello in if h1");
      gsap.fromTo(
        ".h2-1",
        {
          x: -100,
          backgroundPositionX: "100%",
          scrollTrigger: {
            trigger: ".h2-2",
            scrub: 1,
            start: "top 60%",
            end: "bottom 20%",
          },
        },
        {
          backgroundPositionX: "0%",
          stagger: 1,
          x: 10,
          scrollTrigger: {
            trigger: ".h2-1",
            // markers: true,
            scrub: 1,
            start: "top 70%",
            end: "bottom 20%",
          },
        }
      );

      gsap.fromTo(
        ".h2-2",
        {
          x: 100,
          backgroundPositionX: "100%",
          scrollTrigger: {
            trigger: ".h2-2",
            scrub: 1,
            start: "top 70%",
            end: "bottom 20%",
          },
        },
        {
          backgroundPositionX: "0%",
          stagger: 1,
          x: 0,
          scrollTrigger: {
            trigger: ".h2-2",
            scrub: 1,
            start: "top 70%",
            end: "bottom 20%",
          },
        },
        {}
      );
    }

    const textH3 = document.querySelectorAll(".h2-3");
    if (textH3.length) {
      gsap.fromTo(
        ".h2-3",
        {
          x: -100,
          backgroundPositionX: "100%",
          scrollTrigger: {
            trigger: ".h2-3",
            scrub: 1,
            start: "top 70%",
            end: "bottom 30%",
          },
        },
        {
          backgroundPositionX: "0%",
          stagger: 1,
          x: 0,
          scrollTrigger: {
            trigger: ".h2-3",
            // markers: true,
            scrub: 1,
            start: "top 70%",
            end: "bottom 30%",
          },
        }
      );

      gsap.fromTo(
        ".h2-4",
        {
          x: 100,
          backgroundPositionX: "100%",
          scrollTrigger: {
            trigger: ".h2-3",
            scrub: 1,
            start: "top 70%",
            end: "bottom 30%",
          },
        },
        {
          backgroundPositionX: "0%",
          stagger: 1,
          x: 0,
          scrollTrigger: {
            trigger: ".h2-3",
            scrub: 1,
            start: "top 70%",
            end: "bottom 30%",
          },
        },
        {}
      );
    }

    const textH5 = document.querySelectorAll(".h2-5");
    if (textH5.length) {
      gsap.fromTo(
        ".h2-5",
        {
          x: -100,
          backgroundPositionX: "100%",
          scrollTrigger: {
            trigger: ".h2-5",
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        },
        {
          backgroundPositionX: "0%",
          stagger: 1,
          x: 0,
          scrollTrigger: {
            trigger: ".h2-5",
            // markers: true,
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        }
      );

      gsap.fromTo(
        ".h2-6",
        {
          x: 100,
          backgroundPositionX: "100%",
          scrollTrigger: {
            trigger: ".h2-5",
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        },
        {
          backgroundPositionX: "0%",
          stagger: 1,
          x: 0,
          scrollTrigger: {
            trigger: ".h2-5",
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        },
        {}
      );
    }


    gsap.fromTo(
      ".h2-6",
      {
        x: 100,
        backgroundPositionX: "100%",
        scrollTrigger: {
          trigger: ".h2-5",
          scrub: 1,
          start: "top 50%",
          end: "bottom 20%",
        },
      },
      {
        backgroundPositionX: "0%",
        stagger: 1,
        x: 0,
        scrollTrigger: {
          trigger: ".h2-5",
          scrub: 1,
          start: "top 50%",
          end: "bottom 20%",
        },
      },
      {}
    );

    const textH7 = document.querySelectorAll(".h2-7");
    if (textH7.length) {
      gsap.fromTo(
        ".h2-7",
        {
          x: -100,
          backgroundPositionX: "100%",
          scrollTrigger: {
            trigger: ".h2-7",
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        },
        {
          backgroundPositionX: "0%",
          stagger: 1,
          x: 0,
          scrollTrigger: {
            trigger: ".h2-7",
            // markers: true,
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        }
      );

      gsap.fromTo(
        ".h2-8",
        {
          x: 50,
          backgroundPositionX: "100%",
          scrollTrigger: {
            trigger: ".h2-7",
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        },
        {
          backgroundPositionX: "0%",
          stagger: 1,
          x: 0,
          scrollTrigger: {
            trigger: ".h2-7",
            scrub: 1,
            start: "top 50%",
            end: "bottom 20%",
          },
        },
        {}
      );
    }



    // rightToLeft.forEach((e) => {
    //   gsap.from(e, {
    //     x: 1500,
    //     y: 300,
    //     scrollTrigger: {
    //       trigger: e,
    //       start: "top 80%",
    //       end: "+=300",
    //       scrub: 1,
    //     },
    //     rotate: 45,
    //     duration: 2.5,
    //   });
    //   console.log("ANIMATION FILE TRIGGERED - SERVICE FILE");

    //   // const smoother = ScrollSmoother.create({
    //   //   content: ".customerLayout",
    //   //   smooth: 2,
    //   //   normalizeScroll: true,
    //   //   ignoreMobileResize: true,
    //   // });

    //   // gsap.to(".text-1 h2", {
    //   //   backgroundPositionX: "0%",
    //   //   stagger: 1,
    //   //   x: -50,
    //   //   scrollTrigger: {
    //   //     trigger: ".text-1 h2",
    //   //     // markers: true,
    //   //     scrub: 1,
    //   //     start: "top center",
    //   //     end: "bottom center",
    //   //   },
    //   // });
    //   // gsap.to(".text-2 h2", {
    //   //   backgroundPositionX: "0%",
    //   //   stagger: 1,
    //   //   x: 50,
    //   //   scrollTrigger: {
    //   //     trigger: ".text-2 h2",
    //   //     // markers: true,
    //   //     scrub: 1,
    //   //     start: "top center",
    //   //     end: "bottom center",
    //   //   },
    //   // });
    //   // gsap.to(".text-3 h2", {
    //   //   backgroundPositionX: "0%",
    //   //   stagger: 1,
    //   //   x: -100,
    //   //   scrollTrigger: {
    //   //     trigger: ".text-3 h2",
    //   //     // markers: true,
    //   //     scrub: 1,
    //   //     start: "top center",
    //   //     end: "bottom center",
    //   //   },
    //   // });
    //   // gsap.to(".text-4 h2", {
    //   //   backgroundPositionX: "0%",
    //   //   stagger: 1,
    //   //   x: 50,
    //   //   scrollTrigger: {
    //   //     trigger: ".text-4 h2",
    //   //     // markers: true,
    //   //     scrub: 1,
    //   //     start: "top center",
    //   //     end: "bottom center",
    //   //   },
    //   // });
    //   // gsap.to(".text-5 h2", {
    //   //   backgroundPositionX: "0%",
    //   //   stagger: 1,
    //   //   x: -50,
    //   //   scrollTrigger: {
    //   //     trigger: ".text-5 h2",
    //   //     // markers: true,
    //   //     scrub: 1,
    //   //     start: "top center",
    //   //     end: "bottom center",
    //   //   },
    //   // });
    //   // gsap.to(".text-6 h2", {
    //   //   backgroundPositionX: "0%",
    //   //   stagger: 1,
    //   //   scrollTrigger: {
    //   //     trigger: ".text-6 h2",
    //   //     // markers: true,
    //   //     scrub: 1,
    //   //     start: "top center",
    //   //     end: "bottom center",
    //   //   },
    //   // });

    //   const opacity0 = () => {
    //     gsap.to(".full_page_animation", {
    //       opacity: 0,
    //     });
    //   };
    //   const opacity1 = () => {
    //     gsap.to(".full_page_animation", {
    //       opacity: 1,
    //     });
    //   };

    //   // console.log("myElementRef.current", myElementRef.current)
    //   // if (myElementRef.current) {
    //   //   gsap.to(myElementRef.current, {
    //   //     x: 500,
    //   //     scrollTrigger: {
    //   //       trigger: myElementRef.current,
    //   //       pin: true,
    //   //       pinSpacing: true,
    //   //       scrub: 2,
    //   //       end: "+=400",
    //   //       onprogress: () => {
    //   //         if (this.progress > 0.7) {
    //   //           opacity0()
    //   //         }
    //   //       }
    //   //       ,
    //   //       onEnterBack: () => {
    //   //         opacity1()
    //   //       },
    //   //       onLeave: () => {
    //   //         opacity0()
    //   //       }
    //   //     },
    //   //     onComplete: () => {
    //   //       opacity0()
    //   //     }, onReverseComplete: () => {
    //   //       opacity1()
    //   //     },
    //   //     rotate: 180,
    //   //     scale: 50,
    //   //     zIndex: -9999
    //   //   })
    //   // }

    //   // const element_one = document.querySelector('.cursor_animation_one');
    //   // const image1 = document.querySelector('.cursor_animation_one.swipeimage');
    //   // console.log('image1::: ', image1);
    //   // const setOneX = gsap.quickSetter(image1, "x", "px");
    //   // const setOneY = gsap.quickSetter(image1, "y", "px");
    //   // const startFollow = () => document.addEventListener("mousemove", align),
    //   // const stopFollow = () => document.removeEventListener("mousemove", align),
    //   // const fade = gsap.to(image1, { autoAlpha: 1, ease: "none", paused: true, onReverseComplete: stopFollow });
    //   // element_one.addEventListener('mouseenter', (e) => {
    //   //   fade.play();
    //   //   startFollow();
    //   //   align(e);
    //   // });

    //   // element_one.addEventListener('mouseleave', () => fade.reverse());

    //   gsap.utils.toArray(".awardsDetail_item").forEach((el) => {
    //     const image = el.querySelector(".swipeimage"),
    //       setX = gsap.quickSetter(image, "x", "px"),
    //       setY = gsap.quickSetter(image, "y", "px"),
    //       align = (e) => {
    //         setX(e.clientX);
    //         setY(e.clientY);
    //       },
    //       startFollow = () => document.addEventListener("mousemove", align),
    //       stopFollow = () => document.removeEventListener("mousemove", align),
    //       fade = gsap.to(image, {
    //         autoAlpha: 1,
    //         ease: "none",
    //         paused: true,
    //         onReverseComplete: stopFollow,
    //       });

    //     el.addEventListener("mouseenter", (e) => {
    //       fade.play();
    //       startFollow();
    //       align(e);
    //       gsap.to(image, {
    //         opacity: 1,
    //       });
    //     });

    //     el.addEventListener("mouseleave", () => {
    //       fade.reverse();
    //       gsap.to(image, {
    //         opacity: 0,
    //       });
    //     });
    //   });
    // });
  });

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="textalign_right testimonialArrow">
        <button className="limeMistBorderbtn" onClick={onClick}>
          <img src={RightArrowIcon} alt="RightArrowIcon" />
        </button>
      </div>
    );
  }

  const onEdge = (direction) => {
    console.log(`Edge reached, direction: ${direction}`);
    // Perform any action you want when the edge is reached
  };

  // const TestimonialSlider = {
  //   centerMode: true,
  //   dots: false,
  //   arrows: true,
  //   infinite: false,
  //   initialSlide: 0,
  //   autoplay: false,
  //   autoplaySpeed: 3000,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   cssEase: "linear",
  //   draggable: true,
  //   swipeToSlide: true,
  //   // initialSlide:0,
  //   prevArrow: false,
  //   nextArrow: <SampleNextArrow />,
  //   pauseOnHover: true,
  //   touchThreshold: 5,
  //   // swipe: true,
  //   touchMove: true,
  //   // focusOnSelect: true,
  //   accessibility: true,
  //   onEdge: onEdge, // Attach the onEdge event
  //   lazyLoad: "progressive", // or 'ondemand'
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: false,
  //         centerMode: false,
  //       },
  //     },
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 2,
  //         initialSlide: 1,
  //         centerMode: false,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         initialSlide: 1,
  //         centerMode: false,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 1,
  //         centerMode: false,
  //       },
  //     },
  //   ],
  // };

  const ourCrew = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 3000,
    accessibility: true,
    initialSlide: 1,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const data = [
    {
      year: 2009,
      Desc: "The inception of Metaskapes by Ganesh Kumar, with just 4 passionate souls in a cosy 1400 sq ft space.",
    },
    {
      year: 2014,
      Desc: "Marked by triumph, as we tackled our first grand corporate and villa projects, expanding our team and office to 2200 sq ft.",
    },
    {
      year: 2016,
      Desc: "Metamatrics - our project management firm, emerged, spreading our influence across 4 southern states, spanning over 20 vibrant cities.",
    },
    {
      year: "NOW",
      // Desc: "Presently thriving with a robust team of 50+ in a dynamic 4400 sq ft hub, with a plethora of projects ranging from Corporate Interiors, Residential to Institutional and Government under our belt.",
      Desc: 'Metaskapes is a multidisciplinary design practice that provides a comprehensive approach to architecture, planning, interior design, and art services, guided by our collective passion for space design.With a combined staff of nearly 100 in and across Chennai, Bangalore, and Hyderabad, our award- winning firm has designed and transformed over 27 million square feet of built space in more than 25 cities throughout India.',
    },
    // {
    //   year: "NEW",
    //   Desc:"Metaskapes is a multidisciplinary design practice that provides a comprehensive approach to architecture, planning, interior design, and art services, guided by our collective passion for space design. With a combined staff of nearly 100 in and across Chennai, Bangalore, and Hyderabad, our award-winning firm has designed and transformed over 27 million square feet of built space in more than 25 cities throughout India.",
    // },
    {
      year: "GOAL",
      Desc: "Our sights are set on the horizon, aiming to scale our hubs to Bangalore and Hyderabad.",
    },
    {
      year: "MISSION",
      Desc: "To create premium designs accessible and affordable to all and to leave a lasting impact on the built environment by creating spaces that inspire, enrich, and serve communities.",
    },
    {
      year: "VISION",
      Desc: "Becoming a leading force in the Architectural and Project Management sectors, known for delivering innovative, high-quality designs and solutions.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeKey, setActiveKey] = useState('0');

  // Used to determine which items appear above the active item
  const halfwayIndex = Math.ceil(data.length / 2);

  // Usd to determine the height/spacing of each item
  const itemHeight = 100;

  // Used to determine at what point an item is moved from the top to the bottom
  const shuffleThreshold = halfwayIndex * itemHeight;

  // Used to determine which items should be visible. this prevents the "ghosting" animation
  const visibleStyleThreshold = shuffleThreshold / 2;

  const determinePlacement = (itemIndex) => {
    // If these match, the item is active
    if (activeIndex === itemIndex) return 0;

    if (itemIndex >= halfwayIndex) {
      if (activeIndex > itemIndex - halfwayIndex) {
        return (itemIndex - activeIndex) * itemHeight;
      } else {
        return -(data.length + activeIndex - itemIndex) * itemHeight;
      }
    }

    if (itemIndex > activeIndex) {
      return (itemIndex - activeIndex) * itemHeight;
    }

    if (itemIndex < activeIndex) {
      if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (data.length - (activeIndex - itemIndex)) * itemHeight;
      }
      return -(activeIndex - itemIndex) * itemHeight;
    }
  };

  const handleClick = (direction) => {
    setActiveIndex((prevIndex) => {
      if (direction === "next") {
        if (prevIndex + 1 > data.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      }

      if (prevIndex - 1 < 0) {
        return data.length - 1;
      }

      return prevIndex - 1;
    });
  };

  // animation marquee

  // const marqueTimeLine1 = useRef(null);
  // const marqueTimeLine2 = useRef(null);

  // useEffect(() => {
  //   marqueTimeLine1.current = gsap.timeline({ paused: false });
  //   marqueTimeLine2.current = gsap.timeline({ paused: false });

  //   const marquees = document.querySelectorAll(".marquee");

  //   marquees.forEach((marquee_tags, index) => {
  //     index === 0
  //       ? marqueTimeLine1.current.to(marquee_tags, {
  //         x: 3000,
  //         duration: 35,
  //         repeat: -1,
  //         ease: "linear",
  //         yoyo: true,
  //         immediateRender: true,
  //       })
  //       : marqueTimeLine2.current.from(marquee_tags, {
  //         x: -3000,
  //         duration: 35,
  //         repeat: -1,
  //         ease: "linear",
  //         yoyo: true,
  //         immediateRender: true,
  //       });

  //     marquee_tags.addEventListener("mouseenter", () => {
  //       index === 0 ? marqueTimeLine1.current.pause() : marqueTimeLine2.current.pause();
  //     });

  //     marquee_tags.addEventListener("mouseleave", () => {
  //       index === 0 ? marqueTimeLine1.current.resume() : marqueTimeLine2.current.resume();
  //     });
  //   });

  //   return () => {
  //     marqueTimeLine1.current.kill();
  //     marqueTimeLine2.current.kill();
  //   };
  // }, []);

  return (
    <>
      <section className="commonPage_bnr aboutUs_bnr">
        <div className="container">
          <div className="row alignItem_center">
            <div
              data-aos="fade-down-right" data-aos-duration="1500"
              className="col-lg-6 mobSpace_mb24 "
            >
              <p className="fontSize24  fontWeight400 cerebralGreyClr_text syne_Font ">
                Transforming visionary concepts into enduring masterpieces, we
                set benchmarks in the industry with our commitment to excellence
                and innovation.{" "}
              </p>
            </div>
            <div
              data-aos="fade-down-left"
              data-aos-duration="1500"
              className="col-lg-6"
            >
              <div className="sectionTitle text-1">
                <h2 className="atypDisplay_Font textalign_right">ABOUT </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutUs_intro">
        <div className="container">
          <div className="row justifyContent_center">
            {/* col-lg-8 */}
            <div className="col-lg-10">
              <h5
                data-aos="fade-up"
                data-aos-duration="1600"
                className="textalign_center fontSize40 atypDisplay_Font fontWeight300 drWhiteClr_text mb_32"
              >
                At Metaskapes, we blend creativity with excellence, carefully
                crafting spaces that redefine Architecture. We specialise in
                bringing your envisioned ideas to a reality and pushing the
                boundaries of conventional design.
              </h5>
              <p
                data-aos="fade-up"
                data-aos-duration="1800"
                className="textalign_center fontSize30 syne_Font fontWeight400 SteelWoolClr_text"
              >
                With a commitment to innovation and a passion for precision, we
                elevate every project to new heights of sophistication and
                style.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="ourStory_bnr">
        <div data-aos="fade-up" data-aos-duration="1600" className="container">
          <div className="row alignItem_center">
            <div className="col-lg-4">
              <div className="sectionTitle text-2">
                <h2 className="atypDisplay_Font  h2-1">Our</h2>
                <h2 className="atypDisplay_Font mb_40 h2-2">Story</h2>

                <div data-aos="fade-up" data-aos-duration="3000" data-aos-delay="500">
                  <p className="fontSize24  fontWeight400 cerebralGreyClr_text syne_Font">
                    Discover the narrative behind our firm, where passion,
                    innovation, and expertise converge to reshape the built
                    environment.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-aos="fade-up"
        data-aos-duration="1800"
        className="ourStorySecCircle"
      >
        <div>
          <div className="bigcircle">
            <div className="position_relative">
              <div>
                <div className="row">
                  <div className="col-lg-8">
                    <h2 className="atypDisplay_Font fontSize48 blankaGreenClr_text fontWeight500 mb_24">
                      {" "}
                      {data[activeIndex].year}
                    </h2>
                    <p className="cerebralGreyClr_text fontSize24 fontWeight400 syne_Font mb_34">
                      {data[activeIndex].Desc}
                    </p>
                    {/* <p className='cerebralGreyClr_text fontSize24 fontWeight400 syne_Font'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                  </div>
                </div>
              </div>
              <div className="halfEllipeControl">
                <div className="position_relative">
                  <button
                    className="topArrowBtn"
                    onClick={() => handleClick("prev")}
                  >
                    <img src={TopArrowIcon} alt="TopArrowIcon" />
                  </button>
                </div>
                <div className="halfEllipeWave">
                  <img src={HalfellipseIcon} alt="HalfellipseIcon" />
                </div>
                <div className="position_relative">
                  <button
                    className="BottomArrowBtn"
                    onClick={() => handleClick("next")}
                  >
                    <img src={BottomArrowIcon} alt="BottomArrowIcon" />
                  </button>
                </div>
              </div>
            </div>

            <div className="centerActive">
              <img src={likeRadioBtn} />
            </div>
          </div>

          <div className="ourStoryTab">
            <ul>
              {data.map((item, i) => (
                <li
                  key={item.id}
                  onClick={() => setActiveIndex(i)}
                  style={{
                    transform: `translateY(${determinePlacement(i)}px)`,
                  }}
                  className={cn("carousel-item", {
                    active: activeIndex === i,
                    visible:
                      Math.abs(determinePlacement(i)) <= visibleStyleThreshold,
                  })}
                >
                  <h5>
                    {" "}
                    {typeof item.year === "string" && item.year.length > 25
                      ? `${item.year.slice(0, 25)}...`
                      : item.year}
                  </h5>
                  {/* <p> {shortenText(item.Desc, 50)}</p> */}
                  {/* <p>
                    {" "}
                    {typeof item.Desc === "string" && item.Desc.length > 25
                      ? `${item.Desc.slice(0, 25)}...`
                      : item.Desc}
                  </p> */}
                </li>
              ))}
            </ul>
          </div>
        </div>



      </section>

      <div className="ourStoryAccordion">
        <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
          {data.map((item, i) => (
            <Accordion.Item eventKey={i.toString()} key={i}>
              <Accordion.Header>{item.year}</Accordion.Header>
              <Accordion.Body>
                <p className="cerebralGreyClr_text fontSize24 fontWeight400 syne_Font">{item.Desc}</p>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>

      <section className="about_whoWeAre">
        <div className="container">
          <div className="weHandledSec">
            <div className="sectionTitle text-2">
              <h2 className="atypDisplay_Font  textalign_right h2-4">We've</h2>
              <h2 className="atypDisplay_Font  textalign_right h2-3">
                Handled
              </h2>
            </div>
          </div>

          <div className="row pb_80">
            <div
              data-aos="fade-right"
              data-aos-duration="3000"
              className="col-lg-6 aboutWhoWeareEffects "
            >
              <div className="about_whoWeAreItem WWA_Item_leftAnimation center leftToRight">
                <div className="about_WWA_content ">
                  <h3>43+</h3>
                  <p>
                    Residential
                    <br /> Projects
                    {/* <br /> */}
                  </p>
                </div>
                <div className="about_WWA_InsiteContent">
                  <div>
                    <p>
                      We elevate living by harmoniously blending practicality
                      with irresistible charm.
                    </p>
                  </div>
                  <div>
                    <a href="/projects">
                      <button className="cursedBlackBtn">
                        <span> View projects</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pb_80">
            <div
              data-aos="fade-left"
              data-aos-duration="3000"
              className="col-lg-6 offset-lg-6 "
            >
              <div className="about_whoWeAreItem WWA_Item_rightAnimation rightToLeft">
                <div className="about_WWA_content ">
                  <h3>52+</h3>
                  <p>
                    Commercial
                    <br /> Projects
                    {/* <br /> */}
                  </p>
                </div>
                <div className="about_WWA_InsiteContent">
                  <div>
                    <p>
                      Excellence drives our commercial projects, blending style
                      and functionality for dynamic, high-performing business
                      environments.
                    </p>
                  </div>
                  <div>
                    <a href="/projects">
                      <button className="cursedBlackBtn">
                        <span> View projects</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pb_80">
            <div
              data-aos="fade-right"
              data-aos-duration="3000"
              className="col-lg-6 aboutWhoWeareEffects "
            >
              <div className="about_whoWeAreItem WWA_Item_leftAnimation left leftToRight">
                <div className="about_WWA_content ">
                  <h3>10+</h3>
                  <p>
                    Institutional
                    <br /> Projects
                  </p>
                </div>

                <div className="about_WWA_InsiteContent">
                  <div>
                    <p>
                      Committed to crafting spaces that seamlessly merge utility
                      and elegance, inspiring dynamic learning experiences.
                    </p>
                  </div>
                  <div>
                    <a href="/projects">
                      <button className="cursedBlackBtn">
                        <span> View projects</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pb_80">
            <div
              data-aos="fade-left"
              data-aos-duration="3000"
              className="col-lg-6 offset-lg-6 aboutWhoWeareEffects "
            >
              <div className="about_whoWeAreItem WWA_Item_rightAnimation right rightToLeft">
                <div className="about_WWA_content ">
                  <h3>72+</h3>
                  <p>
                    Project
                    <br /> Managements
                  </p>
                </div>

                <div className="about_WWA_InsiteContent">
                  <div>
                    <p>
                      Experience excellence in Project Management, where we
                      blend practicality and flair to craft dynamic environments
                      for peak performance.
                    </p>
                  </div>
                  <div>
                    <a href="/projects">
                      <button className="cursedBlackBtn">
                        <span> View projects</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              data-aos="fade-right"
              data-aos-duration="3000"
              className="col-lg-6"
            >
              <div className="about_whoWeAreItem WWA_Item_leftAnimation leftToRight">
                <div className="about_WWA_content ">
                  <h3>55+</h3>
                  <p>
                    Happy
                    <br />
                    Clients
                  </p>
                </div>

                <div className="about_WWA_InsiteContent">
                  <div>
                    <p>
                      We thrive because our clients are elated; their happiness
                      fuels the bedrock of our enduring success.
                    </p>
                  </div>
                  <div>
                    <a href="/projects">
                      <button className="cursedBlackBtn">
                        <span> View projects</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        data-aos="zoom-in"
        data-aos-duration="2000"
        ref={myElementRef}
        className=" About_whoWeareFontsizeIncrease"
      >
        {/* fontSize140 */}
        <h4 className="syne_Font fontWeight400  textalign_center">
          {" "}
          15+
        </h4>
        <p className="syne_Font fontWeight400 fontSize48 textalign_center">
          Years of experience
        </p>
      </div>

      {/* <section className="ourCrew_bnr">
        <div className="container">
          <div className="row alignItem_center">
            <div
              data-aos="fade-right"
              data-aos-duration="1900"
              className="col-lg-4 mobSpace_mb24"
            >
              <button
                className="limeMistBorderbtn"
                onClick={() => navigate(`/careers`)}
              >
                Join us
              </button>
            </div>
            <div
              data-aos="fade-right"
              data-aos-duration="1900"
              className="col-lg-8"
            >
              <div className="sectionTitle text-2">
                <h2 className="atypDisplay_Font textalign_right h2-5">OUR</h2>
                <h2 className="atypDisplay_Font textalign_right mb_40 h2-6">CREW</h2>
                <p className="fontSize24  fontWeight400 cerebralGreyClr_text syne_Font textalign_right">
                  Driven by a shared vision, we pledge to infuse <br className="MobBr_hide" />
                  Architectural excellence into every project. <br className="MobBr_hide" />
                  Our passion radiates through each detail, <br className="MobBr_hide" />
                  promising unparalleled quality and <br className="MobBr_hide" />
                  distinction in all endeavors. 

                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section
        data-aos="fade-up"
        data-aos-duration="2100"
        className="ourCrew_Slider"
      >
        <Slider ref={sliderRef} {...ourCrew}>
          <div className="ourCrewItem">
            <div className="ProfileImage">
              <img src={jasonProfile} alt="jasonProfile" />
            </div>
            <div>
              <h2>Ganesh</h2>
              <p>FOUNDER, Metaskapes</p>
            </div>
          </div>

          <div className="ourCrewItem">
            <div className="ProfileImage">
              <img src={JohnProfile} alt="JohnProfile" />
            </div>
            <div>
              <h2>John</h2>
              <p>CO-FOUNDER, Metaskapes</p>
            </div>
          </div>

          <div className="ourCrewItem">
            <div className="ProfileImage">
              <img src={AndreanneProfile} alt="AndreanneProfile" />
            </div>
            <div>
              <h2>Andreanne</h2>
              <p>Project manager</p>
            </div>
          </div>

          <div className="ourCrewItem">
            <div className="ProfileImage">
              <img src={JohnPaulProfile} alt="JohnPaulProfile" />
            </div>
            <div>
              <h2>Bailey</h2>
              <p>Chief Architect</p>
            </div>
          </div>

          <div className="ourCrewItem">
            <div className="ProfileImage">
              <img src={JuliaProfile} alt="JuliaProfile" />
            </div>
            <div>
              <h2>Ashley</h2>
              <p>Designer / Editor</p>
            </div>
          </div>
        </Slider>
      </section> */}

      {/* <section className="awardsRecognitions_sec">
        <div className="container">
          <div className="sectionTitle text-4">
            <h2 className=" atypDisplay_Font   mb_20 textalign_center">
              AWARDS & <br /> RECOGNITIONS
            </h2>
            <p
              data-aos="fade-up"
              data-aos-duration="2200"
              className="cerebralGreyClr_text fontSize24 fontWeight400 syne_Font textalign_center mb_40"
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.{" "}
            </p>
          </div>
          <div className="row justifyContent_center">
            <div className="col-lg-8">
              <div className="awards_detail">
                <div
                  data-aos="fade-up"
                  data-aos-duration="2300"
                  className="awardsDetail_item cursor_animation_one"
                >
                  <div className="hoverImgMove">
                    <img className="swipeimage" src={VillaImg} />
                  </div>

                  <div className="displayFlex alignItem_center justifyContent_spacebetween Mobgap24">
                    <div>
                      <h4> BEST ARCHITECTURE AWARD </h4>
                    </div>
                    <div>
                      <h3>2020</h3>
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="2400"
                  className="awardsDetail_item cursor_animation_two Mobgap24"
                >
                  <div className="hoverImgMove">
                    <img className="swipeimage" src={JuliaProfile} />
                  </div>

                  <div className=" displayFlex alignItem_center justifyContent_spacebetween Mobgap24">
                    <div>
                      <h4>INTERIOR OF THE YEAR </h4>
                    </div>
                    <div>
                      <h3>2022</h3>
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="3000"
                  className="awardsDetail_item cursor_animation_three Mobgap24"
                >
                  <div className="hoverImgMove">
                    <img className="swipeimage" src={AndreanneProfile} />
                  </div>

                  <div className=" displayFlex alignItem_center justifyContent_spacebetween Mobgap24">
                    <div>
                      <h4>
                        {" "}
                        DESIGN AWARD{" "}
                      </h4>
                    </div>
                    <div>
                      <h3>2024</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="ourClients_sec">
        <div className="container">
          <div className="sectionTitle text-2">
            <h2 className=" atypDisplay_Font textalign_center h2-7"> OUR </h2>
            <h2 className=" atypDisplay_Font mb_40 textalign_center h2-8">
              {" "}
              CLIENTS{" "}
            </h2>

            <div className="row justifyContent_center">
              <div className="col-lg-10">
                <p
                  data-aos="fade-up" data-aos-delay="700"
                  data-aos-duration="3000"
                  className="cerebralGreyClr_text fontSize34 fontWeight400 syne_Font textalign_center mb_40"
                >
                  Trust fuels our passion for excellence. Each client's loyalty
                  is a testament to our unwavering dedication. They choose us
                  confidently,
                  {/* <br className="MobBr_hide" /> */}
                  knowing we always exceed expectations.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="customerLogos"
          data-aos="fade-up"
          data-aos-duration="3000" data-aos-delay="900"
        >
          <Marquee direction="left" ref={marqueeRef} speed={70} className="marquee animation_testimonial" loop={0}>

            <div className="clientLogo_item ms-0">
              <img src={Cl1} alt="Cl1" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl2} alt="Cl2" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl3} alt="Cl3" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl4} alt="Cl4" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl5} alt="Cl5" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl6} alt="Cl6" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl7} alt="Cl7" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl8} alt="Cl8" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl9} alt="Cl9" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl10} alt="Cl10" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl11} alt="Cl11" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl12} alt="Cl12" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl13} alt="Cl13" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl14} alt="Cl14" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl15} alt="Cl115" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl16} alt="Cl16" />
            </div>
            <div className="clientLogo_item ">
              <img src={Cl17} alt="Cl17" />
            </div>
            {/* <div className="clientLogo_item ">
              <img src={Cl41} alt="Cl41" />
            </div> */}

            <div className="clientLogo_item ">
              <img src={Cl42} alt="Cl42" />
            </div>
            
            
            <div className="clientLogo_item ">
              <img src={Cl43} alt="Cl43" />
            </div>
            
            <div className="clientLogo_item ">
              <img src={Cl44} alt="Cl44" />
            </div>
            
            <div className="clientLogo_item ">
              <img src={Cl45} alt="Cl45" />
            </div>
            
            <div className="clientLogo_item ">
              <img src={Cl46} alt="Cl46" />
            </div>
            
            <div className="clientLogo_item me-0">
              <img src={Cl47} alt="Cl47" />
            </div>
            {" "}
          </Marquee>

          <Marquee className="marquee" direction="right" loop={0} speed={70} >
            <div className="clientLogo_item ms-0">
              <img src={Cl18} alt="Cl18" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl19} alt="Cl19" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl20} alt="Cl20" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl21} alt="Cl21" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl22} alt="Cl22" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl23} alt="Cl23" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl24} alt="Cl24" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl25} alt="Cl25" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl26} alt="Cl26" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl27} alt="Cl27" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl28} alt="Cl28" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl29} alt="Cl29" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl30} alt="Cl30" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl31} alt="Cl31" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl32} alt="Cl32" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl33} alt="Cl33" />
            </div>
            <div className="clientLogo_item">
              <img src={Cl34} alt="Cl34" />
            </div>
            <div className="clientLogo_item ">
              <img src={Cl35} alt="Cl35" />
              
            </div>
            <div className="clientLogo_item ">
              <img src={Cl36} alt="Cl36" />
              
            </div>
            <div className="clientLogo_item ">
              <img src={Cl37} alt="Cl37" />
              
            </div>
            <div className="clientLogo_item ">
              <img src={Cl38} alt="Cl38" />
              
            </div>
            <div className="clientLogo_item ">
              <img src={Cl39} alt="Cl39" />
              
            </div>
            <div className="clientLogo_item me-0">
              <img src={Cl40} alt="Cl40" />
              
            </div>
           
           
           
           {" "}

          </Marquee>
        </div>
      </section>

      <section className="testimonials_sec">
        <div className="container">
          <div className="row alignItem_end pb_100">
            <div className="col-lg-7 ">
              <div className="sectionTitle text-2 ">
                <h2 className="atypDisplay_Font mb_40 h2-9 " >TESTIMONIALS</h2>
                <div data-aos="fade-up"
                  data-aos-duration="3000" data-aos-delay="900" data-aos-easing="ease">
                  <p

                    className="cerebralGreyClr_text fontSize24 fontWeight400 syne_Font fadeUp newTrigger" 
                  >
                    Listen to our clients' stories - each testimonial is a
                    <br className="MobBr_hide" />
                    vibrant chapter in our journey, showcasing trust,
                    <br className="MobBr_hide" />
                    satisfaction, and exceptional experiences.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8 ">
              {/* <button className='limeMistBorderbtn marginLeft_Auto display_block' onClick={handleNextSlide}>
                <img src={RightArrowIcon} alt='RightArrowIcon' />
              </button> */}
            </div>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-duration="3000"
          className="container-fluid"
        >
          <Testimonials
            CommaIcon={CommaIcon}
            // TestimonialSlider={TestimonialSlider}
          />
        </div>
      </section>
      {/* <section className="contactus_marquee">
        <div className="container-fluid">
          <div className="main-marq">
            <div className="slide-har st1">
              <div className="box">
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
                <div className="item">
                  <h3>
                    CONTACT <span>US</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default AboutUs;
