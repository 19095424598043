import React from "react";
import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import senderRequest from "../../../service/Http";
import toast from "react-hot-toast";
import { handleInputValidation } from "../../../helper/validator";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import ImageUploading from "react-images-uploading";
import Upload from "../../../components/Upload";
import axios from "axios";

const AddTestimonialsModal = ({
  openModal,
  setOpenModal,
  handleTestimonials,
}) => {
  const [name, setName] = useState("");
  const [feedback, setFeedback] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [designation, setDesignation] = useState("");
  const [Tags, setTags] = useState("");
  const [error, setError] = useState({});
  //   const [image, setImage] = useState("");
  const [modalPlacement, setModalPlacement] = useState("center");
  // const [images, setImages] = useState([]);

  const navigate = useNavigate();

  const handleAddTestimonial = async (e, image_id) => {
    e.preventDefault();
    if ( !feedback ||  !company_name) {
      toast.error("All fields are required");
      return;
    }

    try {
      let body = {
        // name: name,
        feedback: feedback,
        company_name: company_name,
        // designation: designation,
      };
      const response = await senderRequest(
        "post",
        `api/testimonial/add`,
        "",
        "",
        body
      );
      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
        // navigate("/admin/testimonials");
        handleTestimonials();
        setFeedback("");
        setName("");
        setCompany_name("");
        setDesignation("");
        setOpenModal(false);
      }
    } catch (error) {
      setFeedback("");
      setName("");
      setCompany_name("");
      setDesignation("");
      setOpenModal(false);
      toast.error(error.message);
    }
  };

  return (
    <Modal
      show={openModal}
      size={"xl"}
      onClose={() => {
        setOpenModal(false);
        setFeedback("");
        setName("");
        setCompany_name("");
        setDesignation("");
      }}
    >
      <Modal.Header className="p-4">Add Testimonials</Modal.Header>
      <Modal.Body>
        <form>
          <div className="row">
            <>
              {/* <div className="InputFrame mb_24">
                <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                  Name
                  <span className="blooddonorClr_text fontSize16 ml_4">* </span>
                </label>
                <input
                  type="text"
                  placeholder=" Name"
                  value={name}
                  onChange={(e) =>
                    handleInputValidation(e.target.value, setName, 1)
                  }
                />
              </div> */}
            </>
            <>
              <div className="InputFrame mb_24">
                <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                  Feedback
                  <span className="blooddonorClr_text fontSize16 ml_4">* </span>
                </label>
                <input
                  type="text"
                  placeholder="feedback"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  name="Experience"
                />
              </div>
            </>
            <>
              <div className="InputFrame mb_24">
                <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                  Company Name
                  <span className="blooddonorClr_text fontSize16 ml_4">* </span>
                </label>
                <input
                  type="text"
                  placeholder="company Name"
                  value={company_name}
                  onChange={(e) => setCompany_name(e.target.value)}
                  name="Experience"
                />
              </div>
            </>
            <>
              {/* <div className="InputFrame mb_24">
                <label className="roycroftPewterClr_text display_block fontWeight600 fontSize14">
                  Designation
                  <span className="blooddonorClr_text fontSize16 ml_4">* </span>
                </label>
                <input
                  type="text"
                  placeholder="Designation"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  name="Experience"
                />
              </div> */}
            </>
          </div>

          <button
            className="MainButton greenGoneWild_btnclr mt-5"
            onClick={(e) => handleAddTestimonial(e)}
          >
            Save
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddTestimonialsModal;
