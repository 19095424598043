import axios from "axios";
import environment from "../env/env";
// const URL = process.env.REACT_API_URL

console.log(URL, "url");
const apiUrlWithId = (apiurl, id) => {
  console.log(id, "id");
  return id?.length
    ? `${environment.apiUrl}/${apiurl}/${id}`
    : `${environment.apiUrl}/${apiurl}`;
};

const handleResponse = (response) => {
  console.log(response, "response");
  return response;
};

const handleError = (error) => {
  if (error?.request) {
    throw new Error(error.request);
  } else if (error?.response) {
    throw new Error(error?.response?.data);
  } else {
    throw new Error(error?.message || "Request Erroe");
  }
};

const senderRequest = async (method, apiUrl, id = "", token = "", body) => {
  let response;
  let url = apiUrlWithId(apiUrl, id);
  try {
    switch (method.toLowerCase()) {
      case "get":
        response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        break;
      case "post":
        response = await axios.post(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        break;
      case "post-photo":
        response = await axios.post(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        break;
      case "put":
        response = await axios.put(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        break;
      case "delete":
        response = await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        break;
      case "patch":
        response = await axios.patch(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        break;
      default:
        throw new Error("Method not allowed");
    }
    console.log(response, "response");
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export default senderRequest;
