import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MetaskapeswhiteLogo from "../assets/images/metaskapelogo.svg";
import DashboardIcon from "../assets/images/dashboard_icon.svg";
import CareerIcon from "../assets/images/career_icon.svg";
import BlogIcon from "../assets/images/blog_icon.svg";
import TestimonialsIcon from "../assets/images/testimonials_icon.svg";
import QueriesIcon from "../assets/images/queries_icon.svg";
import ProjectsIcon from "../assets/images/projects_icon.svg";
import ServicesIcon from "../assets/images/services_icon.svg";

const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const route = pathname?.split("/")[2];
  console.log(route,"route")

  return (
    <div className="sidebar_wrapper">
      <div>
        <div className="adminLogo">
          <img src={MetaskapeswhiteLogo} alt="MetaskapeswhiteLogo" />
        </div>
        <div className="cursor-pointer">
          <ul>
            {/* <li>
              <button
                className={route === "dashboard" ? "active" : ""}
                onClick={() => navigate("/admin/dashboard")}
              >
                <img src={DashboardIcon} alt="DashboardIcon" />
                <span>Dashboard</span>
              </button>
            </li> */}
            <li className="fontSize14 fontWeight500 SteelWoolClr_text">
              Pages
            </li>

            <li>
              <button
                className={route === "thoughts" ? "activeTab" : ""}
                onClick={() => navigate("/admin/thoughts")}
              >
                <img src={BlogIcon} alt="BlogIcon" />
                <span>Thoughts</span>
              </button>
            </li>

            <li>
              <button
                className={route === "projects" ? "activeTab" : ""}
                onClick={() => navigate("/admin/projects")}
              >
                <img src={ProjectsIcon} alt="ProjectsIcon" />
                <span>Projects</span>
              </button>
            </li>
            <li>
              <button
                className={route === "testimonials" ? "activeTab" : ""}
                onClick={() => navigate("/admin/testimonials")}
              >
                <img src={ServicesIcon} alt="ServicesIcon" />
                <span>Testimonials</span>
              </button>
            </li>
            <li>
              <button
                className={route === "career" ? "activeTab" : ""}
                onClick={() => navigate("/admin/career")}
              >
                <img src={CareerIcon} alt="CareerIcon" />
                <span>Career</span>
              </button>
            </li>
            {/* <li>
              <button
                className={route === "user-career" ? "active" : ""}
                onClick={() => navigate("/admin/usercareer")}
              >
                <img src={CareerIcon} alt="CareerIcon" />
                <span>User Career</span>
              </button>
            </li> */}

            {/* <li>
              <button
                className={route === 'testimonials' ? 'active' : ''}
                onClick={() => navigate('/admin/testimonials')}
              >
                <img src={TestimonialsIcon} alt="TestimonialsIcon" />
                <span>Testimonials</span>
              </button>
            </li> */}
            <li>
              <button
                className={route === "queries" ? "activeTab" : ""}
                onClick={() => navigate("/admin/queries")}
              >
                <img src={QueriesIcon} alt="QueriesIcon" />
                <span>Queries</span>
              </button>
            </li>
            {/* <li>
              <button
                className={route === "business-leads" ? "active" : ""}
                onClick={() => navigate("/admin/business-leads")}
              >
                <img src={QueriesIcon} alt="QueriesIcon" />
                <span>Business Leads</span>
              </button>
            </li> */}
          </ul>
        </div>
      </div>
      {/* <div >
        Footer
      </div> */}
    </div>
  );
};

export default Sidebar;
